import axios from "axios";
import { useEffect, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import * as turf from "@turf/turf";
import { SetAreaDropdown } from "./redux/slices/OldMapSlice";
import { useDispatch, useSelector } from "react-redux";
// import L from "leaflet";
import { toast } from "react-toastify";
import { area_dropdownd } from "./map_pages/Constants";
import { BASE_API } from "./helpers/API";
import {
  handleLoadingFalse,
  handleLoadingTrue,
} from "./redux/slices/LocationProjectSlice";

const VectorData = (props) => {
  const dispatch = useDispatch();
  const OldMapSlice = useSelector((state) => state.OldMapSlice);

  const map = useMap();
  const [id, setId] = useState();
  const [data, setData] = useState();
  // const [minVal, setminVal] = useState(null);
  // const [maxVal, setmaxVal] = useState(null);
  const [selectedDD, setselectedDD] = useState(null);
  const [selectedCat, setselectedCat] = useState(null);
  const [selectedSubCat, setselectedSubCat] = useState(null);
  const [msoaEstab, setmsoaEstab] = useState(null);
  const [isMsoa, setisMsoa] = useState(false); // 0,1,2, (0=msoa, 1=regions msoa, 2=counties msoa)
  const [isloading, setisloading] = useState(false);
  const [areaDropdownList, setAreaDropdownList] = useState(area_dropdownd);

  const {
    minVal,
    setminVal,
    maxVal,
    setmaxVal,
    setFilterMinValue,
    setFilterMaxValue,
    setFilterValue,
  } = props;

  // useEffect(() => {
  //   setFilterValue([
  //     minVal ? parseInt(minVal) : 0,
  //     maxVal ? parseInt(maxVal) : 100,
  //   ]);
  //   // setFilterMinValue(minVal ? parseInt(minVal) : 0);
  //   // setFilterMaxValue(maxVal ? parseInt(maxVal) : 100);
  // }, [minVal, maxVal]);

  // const [points, setpoints] = useState([]);
  // props
  useEffect(() => {
    let id = props.change;
    const getData = async () => {
      dispatch(handleLoadingTrue());
      let is_msoa_es_tab = msoaEstab;
      if (props.reset) {
        is_msoa_es_tab = null;
      }
      if (id === "counties" || id === "regions") {
        setisMsoa(null);
        setmsoaEstab(null);
        is_msoa_es_tab = null;
      }
      // if (id === "msoa") {
      //   id = "regions";
      //   setisMsoa(1);
      // }
      //TO SHOW ALL MSOA DATA
      if (id === "msoa" && props.activeIndex === 0) {
        id = "regions";
        setisMsoa(1);
      }

      if (id === "counties_msoa") {
        id = "counties";
        setisMsoa(2);
      }
      setId(id);
      let geojson;
      const response = await axios.get(
        `${BASE_API}${id}/${is_msoa_es_tab}`
        // `../webapp/${id}/${msoaEstab}`
      );
      if (is_msoa_es_tab) {
        geojson = {
          type: "FeatureCollection",
          features:
            response?.data?.length > 0
              ? response.data.map((item) => {
                  return {
                    type: "Feature",
                    properties: {
                      id: "msoa",
                      area: item.area,
                      msoa21nm: item.msoa21nm,
                      msoa21cd: item.msoa21cd,
                      population: item.population,
                      dpopu: item.dpopu,
                      genderm: item.genderm,
                      dgm: item.dgm,
                      genderf: item.genderf,
                      dgf: item.dgf,
                      age0to4: item.age0to4,
                      da0to4: item.da0to4,
                      age5to9: item.age5to9,
                      da5to9: item.da5to9,
                      age10to14: item.age10to14,
                      da10to14: item.da10to14,
                      age15to19: item.age15to19,
                      da15to19: item.da15to19,
                      age20to24: item.age20to24,
                      da20to24: item.da20to24,
                      age25to29: item.age25to29,
                      da25to29: item.da25to29,
                      age30to34: item.age30to34,
                      da30to34: item.da30to34,
                      age35to39: item.age35to39,
                      da35to39: item.da35to39,
                      age40to44: item.age40to44,
                      da40to44: item.da40to44,
                      age45to49: item.age45to49,
                      da45to49: item.da45to49,
                      age50to54: item.age50to54,
                      da50to54: item.da50to54,
                      age55to59: item.age55to59,
                      da55to59: item.da55to59,
                      age60to64: item.age60to64,
                      da60to64: item.da60to64,
                      age65to69: item.age65to69,
                      da65to69: item.da65to69,
                      age70to74: item.age70to74,
                      da70to74: item.da70to74,
                      age75to79: item.age75to79,
                      da75to79: item.da75to79,
                      age80to84: item.age80to84,
                      da80to84: item.da80to84,
                      age85plus: item.age85plus,
                      da85plus: item.da85plus,
                      net_annual_income: item.net_annual_income,
                      dnet_annual_income:
                        item.dnet_annual_income ??
                        item.net_annual_income / item.area,
                      net_annual_income_after_housing_costs:
                        item.net_annual_income_after_housing_costs,
                      dnet_annual_income_after_housing_costs:
                        item.dnet_annual_income_after_housing_costs ??
                        item.net_annual_income_after_housing_costs / item.area,
                      net_annual_income_before_housing_costs:
                        item.net_annual_income_before_housing_costs,
                      dnet_annual_income_before_housing_costs:
                        item.dnet_annual_income_before_housing_costs ??
                        item.net_annual_income_before_housing_costs / item.area,
                      total_annual_income: item.total_annual_income,
                      dtotal_annual_income:
                        item.dtotal_annual_income ??
                        item.total_annual_income / item.area,
                      total_households: item.total_households,
                      dtotal_households:
                        item.dtotal_households ??
                        item.total_households / item.area,
                      people0: item.people0,
                      dpeople0: item.dpeople0 ?? item.people0 / item.area,
                      person1: item.person1,
                      dperson1: item.dperson1 ?? item.person1 / item.area,
                      person2: item.person2,
                      dperson2: item.dperson2 ?? item.person2 / item.area,
                      person3: item.person3,
                      dperson3: item.dperson3 ?? item.person3 / item.area,
                      person4: item.person4,
                      dperson4: item.dperson4 ?? item.person4 / item.area,
                      person5: item.person5,
                      dperson5: item.dperson5 ?? item.person5 / item.area,
                      person6: item.person6,
                      dperson6: item.dperson6 ?? item.person6 / item.area,
                      person7: item.person7,
                      dperson7: item.dperson7 ?? item.person7 / item.area,
                      person8: item.person8,
                      dperson8: item.dperson8 ?? item.person8 / item.area,
                      person8ormore: item.person8ormore,
                      dperson8ormore:
                        item.dperson8ormore ?? item.person8ormore / item.area,
                      christian: item.christian,
                      dchristian: item.dchristian ?? item.christian / item.area,
                      hindu: item.hindu,
                      dhindu: item.dhindu ?? item.hindu / item.area,
                      jewish: item.jewish,
                      djewish: item.djewish ?? item.jewish / item.area,
                      muslim: item.muslim,
                      dmuslim: item.dmuslim ?? item.muslim / item.area,
                      sikh: item.sikh,
                      dsikh: item.dsikh ?? item.sikh / item.area,
                      buddhist: item.buddhist,
                      dbuddhist: item.dbuddhist ?? item.buddhist / item.area,
                      no_religion: item.no_religion,
                      dno_religion:
                        item.dno_religion ?? item.no_religion / item.area,
                      other_religion: item.other_religion,
                      dother_religion:
                        item.dother_religion ?? item.other_religion / item.area,
                    },
                    geometry: {
                      type: "MultiPolygon",
                      coordinates: item.geometry.coordinates,
                    },
                  };
                })
              : "",
        };
      } else {
        if (id === "regions") {
          geojson = {
            type: "FeatureCollection",
            features:
              response?.data?.length > 0
                ? response.data.map((item) => {
                    return {
                      type: "Feature",
                      properties: {
                        id: "regions",
                        area: item.area,
                        rgn21nm: item.rgn21nm,
                        rgn21cd: item.rgn21cd,
                        population: item.population,
                        dpopu: item.dpopu,
                        genderm: item.genderm,
                        dgm: item.dgm,
                        genderf: item.genderf,
                        dgf: item.dgf,
                        age0to4: item.age0to4,
                        da0to4: item.da0to4,
                        age5to9: item.age5to9,
                        da5to9: item.da5to9,
                        age10to14: item.age10to14,
                        da10to14: item.da10to14,
                        age15to19: item.age15to19,
                        da15to19: item.da15to19,
                        age20to24: item.age20to24,
                        da20to24: item.da20to24,
                        age25to29: item.age25to29,
                        da25to29: item.da25to29,
                        age30to34: item.age30to34,
                        da30to34: item.da30to34,
                        age35to39: item.age35to39,
                        da35to39: item.da35to39,
                        age40to44: item.age40to44,
                        da40to44: item.da40to44,
                        age45to49: item.age45to49,
                        da45to49: item.da45to49,
                        age50to54: item.age50to54,
                        da50to54: item.da50to54,
                        age55to59: item.age55to59,
                        da55to59: item.da55to59,
                        age60to64: item.age60to64,
                        da60to64: item.da60to64,
                        age65to69: item.age65to69,
                        da65to69: item.da65to69,
                        age70to74: item.age70to74,
                        da70to74: item.da70to74,
                        age75to79: item.age75to79,
                        da75to79: item.da75to79,
                        age80to84: item.age80to84,
                        da80to84: item.da80to84,
                        age85plus: item.age85plus,
                        da85plus: item.da85plus,
                        net_annual_income: item.net_annual_income,
                        dnet_annual_income:
                          item.dnet_annual_income ??
                          item.net_annual_income / item.area,
                        net_annual_income_after_housing_costs:
                          item.net_annual_income_after_housing_costs,
                        dnet_annual_income_after_housing_costs:
                          item.dnet_annual_income_after_housing_costs ??
                          item.net_annual_income_after_housing_costs /
                            item.area,
                        net_annual_income_before_housing_costs:
                          item.net_annual_income_before_housing_costs,
                        dnet_annual_income_before_housing_costs:
                          item.dnet_annual_income_before_housing_costs ??
                          item.net_annual_income_before_housing_costs /
                            item.area,
                        total_annual_income: item.total_annual_income,
                        dtotal_annual_income:
                          item.dtotal_annual_income ??
                          item.total_annual_income / item.area,
                        total_households: item.total_households,
                        dtotal_households:
                          item.dtotal_households ??
                          item.total_households / item.area,
                        people0: item.people0,
                        dpeople0: item.dpeople0 ?? item.people0 / item.area,
                        person1: item.person1,
                        dperson1: item.dperson1 ?? item.person1 / item.area,
                        person2: item.person2,
                        dperson2: item.dperson2 ?? item.person2 / item.area,
                        person3: item.person3,
                        dperson3: item.dperson3 ?? item.person3 / item.area,
                        person4: item.person4,
                        dperson4: item.dperson4 ?? item.person4 / item.area,
                        person5: item.person5,
                        dperson5: item.dperson5 ?? item.person5 / item.area,
                        person6: item.person6,
                        dperson6: item.dperson6 ?? item.person6 / item.area,
                        person7: item.person7,
                        dperson7: item.dperson7 ?? item.person7 / item.area,
                        person8: item.person8,
                        dperson8: item.dperson8 ?? item.person8 / item.area,
                        person8ormore: item.person8ormore,
                        dperson8ormore:
                          item.dperson8ormore ?? item.person8ormore / item.area,
                        christian: item.christian,
                        dchristian:
                          item.dchristian ?? item.christian / item.area,
                        hindu: item.hindu,
                        dhindu: item.dhindu ?? item.hindu / item.area,
                        jewish: item.jewish,
                        djewish: item.djewish ?? item.jewish / item.area,
                        muslim: item.muslim,
                        dmuslim: item.dmuslim ?? item.muslim / item.area,
                        sikh: item.sikh,
                        dsikh: item.dsikh ?? item.sikh / item.area,
                        buddhist: item.buddhist,
                        dbuddhist: item.dbuddhist ?? item.buddhist / item.area,
                        no_religion: item.no_religion,
                        dno_religion:
                          item.dno_religion ?? item.no_religion / item.area,
                        other_religion: item.other_religion,
                        dother_religion:
                          item.dother_religion ??
                          item.other_religion / item.area,
                      },
                      geometry: {
                        type: "MultiPolygon",
                        coordinates: item.geometry.coordinates,
                      },
                    };
                  })
                : "",
          };
        }
        if (id === "counties") {
          geojson = {
            type: "FeatureCollection",
            features: response.data.map((item) => {
              return {
                type: "Feature",
                properties: {
                  id: "counties",
                  area: item.area,
                  ctyua21nm: item.ctyua21nm,
                  ctyua21cd: item.ctyua21cd,
                  population: item.population,
                  dpopu: item.dpopu,
                  genderm: item.genderm,
                  dgm: item.dgm,
                  genderf: item.genderf,
                  dgf: item.dgf,
                  age0to4: item.age0to4,
                  da0to4: item.da0to4,
                  age5to9: item.age5to9,
                  da5to9: item.da5to9,
                  age10to14: item.age10to14,
                  da10to14: item.da10to14,
                  age15to19: item.age15to19,
                  da15to19: item.da15to19,
                  age20to24: item.age20to24,
                  da20to24: item.da20to24,
                  age25to29: item.age25to29,
                  da25to29: item.da25to29,
                  age30to34: item.age30to34,
                  da30to34: item.da30to34,
                  age35to39: item.age35to39,
                  da35to39: item.da35to39,
                  age40to44: item.age40to44,
                  da40to44: item.da40to44,
                  age45to49: item.age45to49,
                  da45to49: item.da45to49,
                  age50to54: item.age50to54,
                  da50to54: item.da50to54,
                  age55to59: item.age55to59,
                  da55to59: item.da55to59,
                  age60to64: item.age60to64,
                  da60to64: item.da60to64,
                  age65to69: item.age65to69,
                  da65to69: item.da65to69,
                  age70to74: item.age70to74,
                  da70to74: item.da70to74,
                  age75to79: item.age75to79,
                  da75to79: item.da75to79,
                  age80to84: item.age80to84,
                  da80to84: item.da80to84,
                  age85plus: item.age85plus,
                  da85plus: item.da85plus,
                  net_annual_income: item.net_annual_income,
                  dnet_annual_income:
                    item.dnet_annual_income ??
                    item.net_annual_income / item.area,
                  net_annual_income_after_housing_costs:
                    item.net_annual_income_after_housing_costs,
                  dnet_annual_income_after_housing_costs:
                    item.dnet_annual_income_after_housing_costs ??
                    item.net_annual_income_after_housing_costs / item.area,
                  net_annual_income_before_housing_costs:
                    item.net_annual_income_before_housing_costs,
                  dnet_annual_income_before_housing_costs:
                    item.dnet_annual_income_before_housing_costs ??
                    item.net_annual_income_before_housing_costs / item.area,
                  total_annual_income: item.total_annual_income,
                  dtotal_annual_income:
                    item.dtotal_annual_income ??
                    item.total_annual_income / item.area,
                  total_households: item.total_households,
                  dtotal_households:
                    item.dtotal_households ?? item.total_households / item.area,
                  people0: item.people0,
                  dpeople0: item.dpeople0 ?? item.people0 / item.area,
                  person1: item.person1,
                  dperson1: item.dperson1 ?? item.person1 / item.area,
                  person2: item.person2,
                  dperson2: item.dperson2 ?? item.person2 / item.area,
                  person3: item.person3,
                  dperson3: item.dperson3 ?? item.person3 / item.area,
                  person4: item.person4,
                  dperson4: item.dperson4 ?? item.person4 / item.area,
                  person5: item.person5,
                  dperson5: item.dperson5 ?? item.person5 / item.area,
                  person6: item.person6,
                  dperson6: item.dperson6 ?? item.person6 / item.area,
                  person7: item.person7,
                  dperson7: item.dperson7 ?? item.person7 / item.area,
                  person8: item.person8,
                  dperson8: item.dperson8 ?? item.person8 / item.area,
                  person8ormore: item.person8ormore,
                  dperson8ormore:
                    item.dperson8ormore ?? item.person8ormore / item.area,
                  christian: item.christian,
                  dchristian: item.dchristian ?? item.christian / item.area,
                  hindu: item.hindu,
                  dhindu: item.dhindu ?? item.hindu / item.area,
                  jewish: item.jewish,
                  djewish: item.djewish ?? item.jewish / item.area,
                  muslim: item.muslim,
                  dmuslim: item.dmuslim ?? item.muslim / item.area,
                  sikh: item.sikh,
                  dsikh: item.dsikh ?? item.sikh / item.area,
                  buddhist: item.buddhist,
                  dbuddhist: item.dbuddhist ?? item.buddhist / item.area,
                  no_religion: item.no_religion,
                  dno_religion:
                    item.dno_religion ?? item.no_religion / item.area,
                  other_religion: item.other_religion,
                  dother_religion:
                    item.dother_religion ?? item.other_religion / item.area,
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: item.geometry.coordinates,
                },
              };
            }),
          };
        }

        if (id === "msoa") {
          geojson = {
            type: "FeatureCollection",
            features: response.data.map((item) => {
              return {
                type: "Feature",
                properties: {
                  id: "msoa",
                  area: item.area,
                  rgn21nm: item.rgn21nm,
                  rgn21cd: item.rgn21cd,
                  msoa21nm: item.msoa21nm,
                  msoa21cd: item.msoa21cd,
                  ctyua21nm: item.ctyua21nm,
                  ctyua21cd: item.ctyua21cd,
                  population: item.population,
                  dpopu: item.dpopu,
                  genderm: item.genderm,
                  dgm: item.dgm,
                  genderf: item.genderf,
                  dgf: item.dgf,
                  age0to4: item.age0to4,
                  da0to4: item.da0to4,
                  age5to9: item.age5to9,
                  da5to9: item.da5to9,
                  age10to14: item.age10to14,
                  da10to14: item.da10to14,
                  age15to19: item.age15to19,
                  da15to19: item.da15to19,
                  age20to24: item.age20to24,
                  da20to24: item.da20to24,
                  age25to29: item.age25to29,
                  da25to29: item.da25to29,
                  age30to34: item.age30to34,
                  da30to34: item.da30to34,
                  age35to39: item.age35to39,
                  da35to39: item.da35to39,
                  age40to44: item.age40to44,
                  da40to44: item.da40to44,
                  age45to49: item.age45to49,
                  da45to49: item.da45to49,
                  age50to54: item.age50to54,
                  da50to54: item.da50to54,
                  age55to59: item.age55to59,
                  da55to59: item.da55to59,
                  age60to64: item.age60to64,
                  da60to64: item.da60to64,
                  age65to69: item.age65to69,
                  da65to69: item.da65to69,
                  age70to74: item.age70to74,
                  da70to74: item.da70to74,
                  age75to79: item.age75to79,
                  da75to79: item.da75to79,
                  age80to84: item.age80to84,
                  da80to84: item.da80to84,
                  age85plus: item.age85plus,
                  da85plus: item.da85plus,
                  net_annual_income: item.net_annual_income,
                  dnet_annual_income:
                    item.dnet_annual_income ??
                    item.net_annual_income / item.area,
                  net_annual_income_after_housing_costs:
                    item.net_annual_income_after_housing_costs,
                  dnet_annual_income_after_housing_costs:
                    item.dnet_annual_income_after_housing_costs ??
                    item.net_annual_income_after_housing_costs / item.area,
                  net_annual_income_before_housing_costs:
                    item.net_annual_income_before_housing_costs,
                  dnet_annual_income_before_housing_costs:
                    item.dnet_annual_income_before_housing_costs ??
                    item.net_annual_income_before_housing_costs / item.area,
                  total_annual_income: item.total_annual_income,
                  dtotal_annual_income:
                    item.dtotal_annual_income ??
                    item.total_annual_income / item.area,
                  total_households: item.total_households,
                  dtotal_households:
                    item.dtotal_households ?? item.total_households / item.area,
                  people0: item.people0,
                  dpeople0: item.dpeople0 ?? item.people0 / item.area,
                  person1: item.person1,
                  dperson1: item.dperson1 ?? item.person1 / item.area,
                  person2: item.person2,
                  dperson2: item.dperson2 ?? item.person2 / item.area,
                  person3: item.person3,
                  dperson3: item.dperson3 ?? item.person3 / item.area,
                  person4: item.person4,
                  dperson4: item.dperson4 ?? item.person4 / item.area,
                  person5: item.person5,
                  dperson5: item.dperson5 ?? item.person5 / item.area,
                  person6: item.person6,
                  dperson6: item.dperson6 ?? item.person6 / item.area,
                  person7: item.person7,
                  dperson7: item.dperson7 ?? item.person7 / item.area,
                  person8: item.person8,
                  dperson8: item.dperson8 ?? item.person8 / item.area,
                  person8ormore: item.person8ormore,
                  dperson8ormore:
                    item.dperson8ormore ?? item.person8ormore / item.area,
                  christian: item.christian,
                  dchristian: item.dchristian ?? item.christian / item.area,
                  hindu: item.hindu,
                  dhindu: item.dhindu ?? item.hindu / item.area,
                  jewish: item.jewish,
                  djewish: item.djewish ?? item.jewish / item.area,
                  muslim: item.muslim,
                  dmuslim: item.dmuslim ?? item.muslim / item.area,
                  sikh: item.sikh,
                  dsikh: item.dsikh ?? item.sikh / item.area,
                  buddhist: item.buddhist,
                  dbuddhist: item.dbuddhist ?? item.buddhist / item.area,
                  no_religion: item.no_religion,
                  dno_religion:
                    item.dno_religion ?? item.no_religion / item.area,
                  other_religion: item.other_religion,
                  dother_religion:
                    item.dother_religion ?? item.other_religion / item.area,
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: item.geometry.coordinates,
                },
              };
            }),
          };
        }
      }
      setData(geojson);

      if (!geojson?.features || geojson?.features.length === 0) {
        props.loading(!isloading);
        alert("Sorry No Result Found FROM API ");
        return "";
      }
      let bbox = turf.bbox(geojson);

      map.flyToBounds([[bbox[1], bbox[0]], [[bbox[3], bbox[2]]]]);
      props.loading(!isloading);

      setselectedDD(props.updateStyle);
      setselectedCat(props.updateCatVal);
      setselectedSubCat(props.updateSubCatVal);
      if (props.updateSubCatVal === "actual") {
        if (props.updateStyle === "population") {
          setmaxVal(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.population > curr.properties.population
                ? prev
                : curr;
            }).properties.population
          );

          setFilterMaxValue(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.population > curr.properties.population
                ? prev
                : curr;
            }).properties.population
          );

          setminVal(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.population < curr.properties.population
                ? prev
                : curr;
            }).properties.population
          );

          setFilterMinValue(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.population < curr.properties.population
                ? prev
                : curr;
            }).properties.population
          );
        }
        if (props.updateStyle === "gender") {
          if (props.updateCatVal === "genderm") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderm > curr.properties.genderm
                  ? prev
                  : curr;
              }).properties.genderm
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderm > curr.properties.genderm
                  ? prev
                  : curr;
              }).properties.genderm
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderm < curr.properties.genderm
                  ? prev
                  : curr;
              }).properties.genderm
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderm < curr.properties.genderm
                  ? prev
                  : curr;
              }).properties.genderm
            );
          }
          if (props.updateCatVal === "genderf") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderf > curr.properties.genderf
                  ? prev
                  : curr;
              }).properties.genderf
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderf > curr.properties.genderf
                  ? prev
                  : curr;
              }).properties.genderf
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderf < curr.properties.genderf
                  ? prev
                  : curr;
              }).properties.genderf
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.genderf < curr.properties.genderf
                  ? prev
                  : curr;
              }).properties.genderf
            );
          }
        }
        if (props.updateStyle === "age") {
          if (props.updateCatVal === "Age0to4") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age0to4 > curr.properties.age0to4
                  ? prev
                  : curr;
              }).properties.age0to4
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age0to4 > curr.properties.age0to4
                  ? prev
                  : curr;
              }).properties.age0to4
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age0to4 < curr.properties.age0to4
                  ? prev
                  : curr;
              }).properties.age0to4
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age0to4 < curr.properties.age0to4
                  ? prev
                  : curr;
              }).properties.age0to4
            );
          }
          if (props.updateCatVal === "Age5to9") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age5to9 > curr.properties.age5to9
                  ? prev
                  : curr;
              }).properties.age5to9
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age5to9 > curr.properties.age5to9
                  ? prev
                  : curr;
              }).properties.age5to9
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age5to9 < curr.properties.age5to9
                  ? prev
                  : curr;
              }).properties.age5to9
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age5to9 < curr.properties.age5to9
                  ? prev
                  : curr;
              }).properties.age5to9
            );
          }
          if (props.updateCatVal === "Age10to14") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age10to14 > curr.properties.age10to14
                  ? prev
                  : curr;
              }).properties.age10to14
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age10to14 > curr.properties.age10to14
                  ? prev
                  : curr;
              }).properties.age10to14
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age10to14 < curr.properties.age10to14
                  ? prev
                  : curr;
              }).properties.age10to14
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age10to14 < curr.properties.age10to14
                  ? prev
                  : curr;
              }).properties.age10to14
            );
          }
          if (props.updateCatVal === "Age15to19") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age15to19 > curr.properties.age15to19
                  ? prev
                  : curr;
              }).properties.age15to19
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age15to19 > curr.properties.age15to19
                  ? prev
                  : curr;
              }).properties.age15to19
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age15to19 < curr.properties.age15to19
                  ? prev
                  : curr;
              }).properties.age15to19
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age15to19 < curr.properties.age15to19
                  ? prev
                  : curr;
              }).properties.age15to19
            );
          }
          if (props.updateCatVal === "Age20to24") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age20to24 > curr.properties.age20to24
                  ? prev
                  : curr;
              }).properties.age20to24
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age20to24 > curr.properties.age20to24
                  ? prev
                  : curr;
              }).properties.age20to24
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age20to24 < curr.properties.age20to24
                  ? prev
                  : curr;
              }).properties.age20to24
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age20to24 < curr.properties.age20to24
                  ? prev
                  : curr;
              }).properties.age20to24
            );
          }
          if (props.updateCatVal === "Age25to29") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age25to29 > curr.properties.age25to29
                  ? prev
                  : curr;
              }).properties.age25to29
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age25to29 > curr.properties.age25to29
                  ? prev
                  : curr;
              }).properties.age25to29
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age25to29 < curr.properties.age25to29
                  ? prev
                  : curr;
              }).properties.age25to29
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age25to29 < curr.properties.age25to29
                  ? prev
                  : curr;
              }).properties.age25to29
            );
          }
          if (props.updateCatVal === "Age30to34") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age30to34 > curr.properties.age30to34
                  ? prev
                  : curr;
              }).properties.age30to34
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age30to34 > curr.properties.age30to34
                  ? prev
                  : curr;
              }).properties.age30to34
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age30to34 < curr.properties.age30to34
                  ? prev
                  : curr;
              }).properties.age30to34
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age30to34 < curr.properties.age30to34
                  ? prev
                  : curr;
              }).properties.age30to34
            );
          }
          if (props.updateCatVal === "Age35to39") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age35to39 > curr.properties.age35to39
                  ? prev
                  : curr;
              }).properties.age35to39
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age35to39 > curr.properties.age35to39
                  ? prev
                  : curr;
              }).properties.age35to39
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age35to39 < curr.properties.age35to39
                  ? prev
                  : curr;
              }).properties.age35to39
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age35to39 < curr.properties.age35to39
                  ? prev
                  : curr;
              }).properties.age35to39
            );
          }
          if (props.updateCatVal === "Age40to44") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age40to44 > curr.properties.age40to44
                  ? prev
                  : curr;
              }).properties.age40to44
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age40to44 > curr.properties.age40to44
                  ? prev
                  : curr;
              }).properties.age40to44
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age40to44 < curr.properties.age40to44
                  ? prev
                  : curr;
              }).properties.age40to44
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age40to44 < curr.properties.age40to44
                  ? prev
                  : curr;
              }).properties.age40to44
            );
          }
          if (props.updateCatVal === "Age45to49") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age45to49 > curr.properties.age45to49
                  ? prev
                  : curr;
              }).properties.age45to49
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age45to49 > curr.properties.age45to49
                  ? prev
                  : curr;
              }).properties.age45to49
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age45to49 < curr.properties.age45to49
                  ? prev
                  : curr;
              }).properties.age45to49
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age45to49 < curr.properties.age45to49
                  ? prev
                  : curr;
              }).properties.age45to49
            );
          }
          if (props.updateCatVal === "Age50to54") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age50to54 > curr.properties.age50to54
                  ? prev
                  : curr;
              }).properties.age50to54
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age50to54 > curr.properties.age50to54
                  ? prev
                  : curr;
              }).properties.age50to54
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age50to54 < curr.properties.age50to54
                  ? prev
                  : curr;
              }).properties.age50to54
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age50to54 < curr.properties.age50to54
                  ? prev
                  : curr;
              }).properties.age50to54
            );
          }
          if (props.updateCatVal === "Age55to59") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age55to59 > curr.properties.age55to59
                  ? prev
                  : curr;
              }).properties.age55to59
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age55to59 > curr.properties.age55to59
                  ? prev
                  : curr;
              }).properties.age55to59
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age55to59 < curr.properties.age55to59
                  ? prev
                  : curr;
              }).properties.age55to59
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age55to59 < curr.properties.age55to59
                  ? prev
                  : curr;
              }).properties.age55to59
            );
          }
          if (props.updateCatVal === "Age60to64") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age60to64 > curr.properties.age60to64
                  ? prev
                  : curr;
              }).properties.age60to64
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age60to64 > curr.properties.age60to64
                  ? prev
                  : curr;
              }).properties.age60to64
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age60to64 < curr.properties.age60to64
                  ? prev
                  : curr;
              }).properties.age60to64
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age60to64 < curr.properties.age60to64
                  ? prev
                  : curr;
              }).properties.age60to64
            );
          }
          if (props.updateCatVal === "Age65to69") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age65to69 > curr.properties.age65to69
                  ? prev
                  : curr;
              }).properties.age65to69
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age65to69 > curr.properties.age65to69
                  ? prev
                  : curr;
              }).properties.age65to69
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age65to69 < curr.properties.age65to69
                  ? prev
                  : curr;
              }).properties.age65to69
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age65to69 < curr.properties.age65to69
                  ? prev
                  : curr;
              }).properties.age65to69
            );
          }
          if (props.updateCatVal === "Age70to74") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age70to74 > curr.properties.age70to74
                  ? prev
                  : curr;
              }).properties.age70to74
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age70to74 > curr.properties.age70to74
                  ? prev
                  : curr;
              }).properties.age70to74
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age70to74 < curr.properties.age70to74
                  ? prev
                  : curr;
              }).properties.age70to74
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age70to74 < curr.properties.age70to74
                  ? prev
                  : curr;
              }).properties.age70to74
            );
          }
          if (props.updateCatVal === "Age75to79") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age75to79 > curr.properties.age75to79
                  ? prev
                  : curr;
              }).properties.age75to79
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age75to79 > curr.properties.age75to79
                  ? prev
                  : curr;
              }).properties.age75to79
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age75to79 < curr.properties.age75to79
                  ? prev
                  : curr;
              }).properties.age75to79
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age75to79 < curr.properties.age75to79
                  ? prev
                  : curr;
              }).properties.age75to79
            );
          }
          if (props.updateCatVal === "Age80to84") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age80to84 > curr.properties.age80to84
                  ? prev
                  : curr;
              }).properties.age80to84
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age80to84 > curr.properties.age80to84
                  ? prev
                  : curr;
              }).properties.age80to84
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age80to84 < curr.properties.age80to84
                  ? prev
                  : curr;
              }).properties.age80to84
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age80to84 < curr.properties.age80to84
                  ? prev
                  : curr;
              }).properties.age80to84
            );
          }
          if (props.updateCatVal === "Age85Plus") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age85plus > curr.properties.age85plus
                  ? prev
                  : curr;
              }).properties.age85plus
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age85plus > curr.properties.age85plus
                  ? prev
                  : curr;
              }).properties.age85plus
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age85plus < curr.properties.age85plus
                  ? prev
                  : curr;
              }).properties.age85plus
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.age85plus < curr.properties.age85plus
                  ? prev
                  : curr;
              }).properties.age85plus
            );
          }
        }
        if (props.updateStyle === "income") {
          if (props.updateCatVal === "total_annual_income") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_annual_income >
                  curr.properties.total_annual_income
                  ? prev
                  : curr;
              }).properties.total_annual_income
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_annual_income >
                  curr.properties.total_annual_income
                  ? prev
                  : curr;
              }).properties.total_annual_income
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_annual_income <
                  curr.properties.total_annual_income
                  ? prev
                  : curr;
              }).properties.total_annual_income
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_annual_income <
                  curr.properties.total_annual_income
                  ? prev
                  : curr;
              }).properties.total_annual_income
            );
          }
          if (props.updateCatVal === "net_annual_income_before_housing_costs") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_before_housing_costs >
                  curr.properties.net_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_before_housing_costs
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_before_housing_costs >
                  curr.properties.net_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_before_housing_costs
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_before_housing_costs <
                  curr.properties.net_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_before_housing_costs
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_before_housing_costs <
                  curr.properties.net_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_before_housing_costs
            );
          }
          if (props.updateCatVal === "net_annual_income_after_housing_costs") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_after_housing_costs >
                  curr.properties.net_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_after_housing_costs
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_after_housing_costs >
                  curr.properties.net_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_after_housing_costs
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_after_housing_costs <
                  curr.properties.net_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_after_housing_costs
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income_after_housing_costs <
                  curr.properties.net_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.net_annual_income_after_housing_costs
            );
          }
          if (props.updateCatVal === "net_annual_income") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income >
                  curr.properties.net_annual_income
                  ? prev
                  : curr;
              }).properties.net_annual_income
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income >
                  curr.properties.net_annual_income
                  ? prev
                  : curr;
              }).properties.net_annual_income
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income <
                  curr.properties.net_annual_income
                  ? prev
                  : curr;
              }).properties.net_annual_income
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.net_annual_income <
                  curr.properties.net_annual_income
                  ? prev
                  : curr;
              }).properties.net_annual_income
            );
          }
        }

        if (props.updateStyle === "people") {
          if (props.updateCatVal === "total_households") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_households >
                  curr.properties.total_households
                  ? prev
                  : curr;
              }).properties.total_households
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_households >
                  curr.properties.total_households
                  ? prev
                  : curr;
              }).properties.total_households
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_households <
                  curr.properties.total_households
                  ? prev
                  : curr;
              }).properties.total_households
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.total_households <
                  curr.properties.total_households
                  ? prev
                  : curr;
              }).properties.total_households
            );
          }
          if (props.updateCatVal === "people0") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.people0 > curr.properties.people0
                  ? prev
                  : curr;
              }).properties.people0
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.people0 > curr.properties.people0
                  ? prev
                  : curr;
              }).properties.people0
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.people0 < curr.properties.people0
                  ? prev
                  : curr;
              }).properties.people0
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.people0 < curr.properties.people0
                  ? prev
                  : curr;
              }).properties.people0
            );
          }
          if (props.updateCatVal === "person1") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person1 > curr.properties.person1
                  ? prev
                  : curr;
              }).properties.person1
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person1 > curr.properties.person1
                  ? prev
                  : curr;
              }).properties.person1
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person1 < curr.properties.person1
                  ? prev
                  : curr;
              }).properties.person1
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person1 < curr.properties.person1
                  ? prev
                  : curr;
              }).properties.person1
            );
          }
          if (props.updateCatVal === "person2") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person2 > curr.properties.person2
                  ? prev
                  : curr;
              }).properties.person2
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person2 > curr.properties.person2
                  ? prev
                  : curr;
              }).properties.person2
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person2 < curr.properties.person2
                  ? prev
                  : curr;
              }).properties.person2
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person2 < curr.properties.person2
                  ? prev
                  : curr;
              }).properties.person2
            );
          }
          if (props.updateCatVal === "person3") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person3 > curr.properties.person3
                  ? prev
                  : curr;
              }).properties.person3
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person3 > curr.properties.person3
                  ? prev
                  : curr;
              }).properties.person3
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person3 < curr.properties.person3
                  ? prev
                  : curr;
              }).properties.person3
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person3 < curr.properties.person3
                  ? prev
                  : curr;
              }).properties.person3
            );
          }
          if (props.updateCatVal === "person4") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person4 > curr.properties.person4
                  ? prev
                  : curr;
              }).properties.person4
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person4 > curr.properties.person4
                  ? prev
                  : curr;
              }).properties.person4
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person4 < curr.properties.person4
                  ? prev
                  : curr;
              }).properties.person4
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person4 < curr.properties.person4
                  ? prev
                  : curr;
              }).properties.person4
            );
          }
          if (props.updateCatVal === "person5") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person5 > curr.properties.person5
                  ? prev
                  : curr;
              }).properties.person5
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person5 > curr.properties.person5
                  ? prev
                  : curr;
              }).properties.person5
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person5 < curr.properties.person5
                  ? prev
                  : curr;
              }).properties.person5
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person5 < curr.properties.person5
                  ? prev
                  : curr;
              }).properties.person5
            );
          }
          if (props.updateCatVal === "person6") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person6 > curr.properties.person6
                  ? prev
                  : curr;
              }).properties.person6
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person6 > curr.properties.person6
                  ? prev
                  : curr;
              }).properties.person6
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person6 < curr.properties.person6
                  ? prev
                  : curr;
              }).properties.person6
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person6 < curr.properties.person6
                  ? prev
                  : curr;
              }).properties.person6
            );
          }
          if (props.updateCatVal === "person7") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person7 > curr.properties.person7
                  ? prev
                  : curr;
              }).properties.person7
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person7 > curr.properties.person7
                  ? prev
                  : curr;
              }).properties.person7
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person7 < curr.properties.person7
                  ? prev
                  : curr;
              }).properties.person7
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person7 < curr.properties.person7
                  ? prev
                  : curr;
              }).properties.person7
            );
          }
          if (props.updateCatVal === "person8") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8 > curr.properties.person8
                  ? prev
                  : curr;
              }).properties.person8
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8 > curr.properties.person8
                  ? prev
                  : curr;
              }).properties.person8
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8 < curr.properties.person8
                  ? prev
                  : curr;
              }).properties.person8
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8 < curr.properties.person8
                  ? prev
                  : curr;
              }).properties.person8
            );
          }
          if (props.updateCatVal === "person8ormore") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8ormore >
                  curr.properties.person8ormore
                  ? prev
                  : curr;
              }).properties.person8ormore
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8ormore >
                  curr.properties.person8ormore
                  ? prev
                  : curr;
              }).properties.person8ormore
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8ormore <
                  curr.properties.person8ormore
                  ? prev
                  : curr;
              }).properties.person8ormore
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.person8ormore <
                  curr.properties.person8ormore
                  ? prev
                  : curr;
              }).properties.person8ormore
            );
          }
        }
        if (props.updateStyle === "religion") {
          if (props.updateCatVal === "christian") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.christian > curr.properties.christian
                  ? prev
                  : curr;
              }).properties.christian
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.christian > curr.properties.christian
                  ? prev
                  : curr;
              }).properties.christian
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.christian < curr.properties.christian
                  ? prev
                  : curr;
              }).properties.christian
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.christian < curr.properties.christian
                  ? prev
                  : curr;
              }).properties.christian
            );
          }
          if (props.updateCatVal === "hindu") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.hindu > curr.properties.hindu
                  ? prev
                  : curr;
              }).properties.hindu
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.hindu > curr.properties.hindu
                  ? prev
                  : curr;
              }).properties.hindu
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.hindu < curr.properties.hindu
                  ? prev
                  : curr;
              }).properties.hindu
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.hindu < curr.properties.hindu
                  ? prev
                  : curr;
              }).properties.hindu
            );
          }
          if (props.updateCatVal === "jewish") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.jewish > curr.properties.jewish
                  ? prev
                  : curr;
              }).properties.jewish
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.jewish > curr.properties.jewish
                  ? prev
                  : curr;
              }).properties.jewish
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.jewish < curr.properties.jewish
                  ? prev
                  : curr;
              }).properties.jewish
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.jewish < curr.properties.jewish
                  ? prev
                  : curr;
              }).properties.jewish
            );
          }
          if (props.updateCatVal === "muslim") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.muslim > curr.properties.muslim
                  ? prev
                  : curr;
              }).properties.muslim
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.muslim > curr.properties.muslim
                  ? prev
                  : curr;
              }).properties.muslim
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.muslim < curr.properties.muslim
                  ? prev
                  : curr;
              }).properties.muslim
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.muslim < curr.properties.muslim
                  ? prev
                  : curr;
              }).properties.muslim
            );
          }
          if (props.updateCatVal === "sikh") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.sikh > curr.properties.sikh
                  ? prev
                  : curr;
              }).properties.sikh
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.sikh > curr.properties.sikh
                  ? prev
                  : curr;
              }).properties.sikh
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.sikh < curr.properties.sikh
                  ? prev
                  : curr;
              }).properties.sikh
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.sikh < curr.properties.sikh
                  ? prev
                  : curr;
              }).properties.sikh
            );
          }
          if (props.updateCatVal === "buddhist") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.buddhist > curr.properties.buddhist
                  ? prev
                  : curr;
              }).properties.buddhist
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.buddhist > curr.properties.buddhist
                  ? prev
                  : curr;
              }).properties.buddhist
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.buddhist < curr.properties.buddhist
                  ? prev
                  : curr;
              }).properties.buddhist
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.buddhist < curr.properties.buddhist
                  ? prev
                  : curr;
              }).properties.buddhist
            );
          }
          if (props.updateCatVal === "no_religion") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.no_religion > curr.properties.no_religion
                  ? prev
                  : curr;
              }).properties.no_religion
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.no_religion > curr.properties.no_religion
                  ? prev
                  : curr;
              }).properties.no_religion
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.no_religion < curr.properties.no_religion
                  ? prev
                  : curr;
              }).properties.no_religion
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.no_religion < curr.properties.no_religion
                  ? prev
                  : curr;
              }).properties.no_religion
            );
          }
          if (props.updateCatVal === "other_religion") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.other_religion >
                  curr.properties.other_religion
                  ? prev
                  : curr;
              }).properties.other_religion
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.other_religion >
                  curr.properties.other_religion
                  ? prev
                  : curr;
              }).properties.other_religion
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.other_religion <
                  curr.properties.other_religion
                  ? prev
                  : curr;
              }).properties.other_religion
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.other_religion <
                  curr.properties.other_religion
                  ? prev
                  : curr;
              }).properties.other_religion
            );
          }
        }
      }
      if (props.updateSubCatVal === "density") {
        if (props.updateStyle === "population") {
          setmaxVal(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.dpopu > curr.properties.dpopu
                ? prev
                : curr;
            }).properties.dpopu
          );

          setFilterMaxValue(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.dpopu > curr.properties.dpopu
                ? prev
                : curr;
            }).properties.dpopu
          );

          setminVal(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.dpopu < curr.properties.dpopu
                ? prev
                : curr;
            }).properties.dpopu
          );

          setFilterMinValue(
            geojson.features.reduce(function (prev, curr) {
              return prev.properties.dpopu < curr.properties.dpopu
                ? prev
                : curr;
            }).properties.dpopu
          );
        }
        if (props.updateStyle === "gender") {
          if (props.updateCatVal === "genderm") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgm > curr.properties.dgm ? prev : curr;
              }).properties.dgm
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgm > curr.properties.dgm ? prev : curr;
              }).properties.dgm
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgm < curr.properties.dgm ? prev : curr;
              }).properties.dgm
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgm < curr.properties.dgm ? prev : curr;
              }).properties.dgm
            );
          }
          if (props.updateCatVal === "genderf") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgf > curr.properties.dgf ? prev : curr;
              }).properties.dgf
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgf > curr.properties.dgf ? prev : curr;
              }).properties.dgf
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgf < curr.properties.dgf ? prev : curr;
              }).properties.dgf
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dgf < curr.properties.dgf ? prev : curr;
              }).properties.dgf
            );
          }
        }
        if (props.updateStyle === "age") {
          if (props.updateCatVal === "Age0to4") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da0to4 > curr.properties.da0to4
                  ? prev
                  : curr;
              }).properties.da0to4
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da0to4 > curr.properties.da0to4
                  ? prev
                  : curr;
              }).properties.da0to4
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da0to4 < curr.properties.da0to4
                  ? prev
                  : curr;
              }).properties.da0to4
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da0to4 < curr.properties.da0to4
                  ? prev
                  : curr;
              }).properties.da0to4
            );
          }
          if (props.updateCatVal === "Age5to9") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da5to9 > curr.properties.da5to9
                  ? prev
                  : curr;
              }).properties.da5to9
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da5to9 > curr.properties.da5to9
                  ? prev
                  : curr;
              }).properties.da5to9
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da5to9 < curr.properties.da5to9
                  ? prev
                  : curr;
              }).properties.da5to9
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da5to9 < curr.properties.da5to9
                  ? prev
                  : curr;
              }).properties.da5to9
            );
          }
          if (props.updateCatVal === "Age10to14") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da10to14 > curr.properties.da10to14
                  ? prev
                  : curr;
              }).properties.da10to14
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da10to14 > curr.properties.da10to14
                  ? prev
                  : curr;
              }).properties.da10to14
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da10to14 < curr.properties.da10to14
                  ? prev
                  : curr;
              }).properties.da10to14
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da10to14 < curr.properties.da10to14
                  ? prev
                  : curr;
              }).properties.da10to14
            );
          }
          if (props.updateCatVal === "Age15to19") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da15to19 > curr.properties.da15to19
                  ? prev
                  : curr;
              }).properties.da15to19
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da15to19 > curr.properties.da15to19
                  ? prev
                  : curr;
              }).properties.da15to19
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da15to19 < curr.properties.da15to19
                  ? prev
                  : curr;
              }).properties.da15to19
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da15to19 < curr.properties.da15to19
                  ? prev
                  : curr;
              }).properties.da15to19
            );
          }
          if (props.updateCatVal === "Age20to24") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da20to24 > curr.properties.da20to24
                  ? prev
                  : curr;
              }).properties.da20to24
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da20to24 > curr.properties.da20to24
                  ? prev
                  : curr;
              }).properties.da20to24
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da20to24 < curr.properties.da20to24
                  ? prev
                  : curr;
              }).properties.da20to24
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da20to24 < curr.properties.da20to24
                  ? prev
                  : curr;
              }).properties.da20to24
            );
          }
          if (props.updateCatVal === "Age25to29") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da25to29 > curr.properties.da25to29
                  ? prev
                  : curr;
              }).properties.da25to29
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da25to29 > curr.properties.da25to29
                  ? prev
                  : curr;
              }).properties.da25to29
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da25to29 < curr.properties.da25to29
                  ? prev
                  : curr;
              }).properties.da25to29
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da25to29 < curr.properties.da25to29
                  ? prev
                  : curr;
              }).properties.da25to29
            );
          }
          if (props.updateCatVal === "Age30to34") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da30to34 > curr.properties.da30to34
                  ? prev
                  : curr;
              }).properties.da30to34
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da30to34 > curr.properties.da30to34
                  ? prev
                  : curr;
              }).properties.da30to34
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da30to34 < curr.properties.da30to34
                  ? prev
                  : curr;
              }).properties.age30to34
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da30to34 < curr.properties.da30to34
                  ? prev
                  : curr;
              }).properties.age30to34
            );
          }
          if (props.updateCatVal === "Age35to39") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da35to39 > curr.properties.da35to39
                  ? prev
                  : curr;
              }).properties.da35to39
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da35to39 > curr.properties.da35to39
                  ? prev
                  : curr;
              }).properties.da35to39
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da35to39 < curr.properties.da35to39
                  ? prev
                  : curr;
              }).properties.da35to39
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da35to39 < curr.properties.da35to39
                  ? prev
                  : curr;
              }).properties.da35to39
            );
          }
          if (props.updateCatVal === "Age40to44") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da40to44 > curr.properties.da40to44
                  ? prev
                  : curr;
              }).properties.da40to44
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da40to44 > curr.properties.da40to44
                  ? prev
                  : curr;
              }).properties.da40to44
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da40to44 < curr.properties.da40to44
                  ? prev
                  : curr;
              }).properties.da40to44
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da40to44 < curr.properties.da40to44
                  ? prev
                  : curr;
              }).properties.da40to44
            );
          }
          if (props.updateCatVal === "Age45to49") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da45to49 > curr.properties.da45to49
                  ? prev
                  : curr;
              }).properties.da45to49
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da45to49 > curr.properties.da45to49
                  ? prev
                  : curr;
              }).properties.da45to49
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da45to49 < curr.properties.da45to49
                  ? prev
                  : curr;
              }).properties.da45to49
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da45to49 < curr.properties.da45to49
                  ? prev
                  : curr;
              }).properties.da45to49
            );
          }
          if (props.updateCatVal === "Age50to54") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da50to54 > curr.properties.da50to54
                  ? prev
                  : curr;
              }).properties.da50to54
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da50to54 > curr.properties.da50to54
                  ? prev
                  : curr;
              }).properties.da50to54
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da50to54 < curr.properties.da50to54
                  ? prev
                  : curr;
              }).properties.da50to54
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da50to54 < curr.properties.da50to54
                  ? prev
                  : curr;
              }).properties.da50to54
            );
          }
          if (props.updateCatVal === "Age55to59") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da55to59 > curr.properties.da55to59
                  ? prev
                  : curr;
              }).properties.da55to59
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da55to59 > curr.properties.da55to59
                  ? prev
                  : curr;
              }).properties.da55to59
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da55to59 < curr.properties.da55to59
                  ? prev
                  : curr;
              }).properties.da55to59
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da55to59 < curr.properties.da55to59
                  ? prev
                  : curr;
              }).properties.da55to59
            );
          }
          if (props.updateCatVal === "Age60to64") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da60to64 > curr.properties.da60to64
                  ? prev
                  : curr;
              }).properties.da60to64
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da60to64 > curr.properties.da60to64
                  ? prev
                  : curr;
              }).properties.da60to64
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da60to64 < curr.properties.da60to64
                  ? prev
                  : curr;
              }).properties.da60to64
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da60to64 < curr.properties.da60to64
                  ? prev
                  : curr;
              }).properties.da60to64
            );
          }
          if (props.updateCatVal === "Age65to69") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da65to69 > curr.properties.da65to69
                  ? prev
                  : curr;
              }).properties.da65to69
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da65to69 > curr.properties.da65to69
                  ? prev
                  : curr;
              }).properties.da65to69
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da65to69 < curr.properties.da65to69
                  ? prev
                  : curr;
              }).properties.da65to69
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da65to69 < curr.properties.da65to69
                  ? prev
                  : curr;
              }).properties.da65to69
            );
          }
          if (props.updateCatVal === "Age70to74") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da70to74 > curr.properties.da70to74
                  ? prev
                  : curr;
              }).properties.da70to74
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da70to74 > curr.properties.da70to74
                  ? prev
                  : curr;
              }).properties.da70to74
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da70to74 < curr.properties.da70to74
                  ? prev
                  : curr;
              }).properties.da70to74
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da70to74 < curr.properties.da70to74
                  ? prev
                  : curr;
              }).properties.da70to74
            );
          }
          if (props.updateCatVal === "Age75to79") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da75to79 > curr.properties.da75to79
                  ? prev
                  : curr;
              }).properties.da75to79
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da75to79 > curr.properties.da75to79
                  ? prev
                  : curr;
              }).properties.da75to79
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da75to79 < curr.properties.da75to79
                  ? prev
                  : curr;
              }).properties.da75to79
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da75to79 < curr.properties.da75to79
                  ? prev
                  : curr;
              }).properties.da75to79
            );
          }
          if (props.updateCatVal === "Age80to84") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da80to84 > curr.properties.da80to84
                  ? prev
                  : curr;
              }).properties.da80to84
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da80to84 > curr.properties.da80to84
                  ? prev
                  : curr;
              }).properties.da80to84
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da80to84 < curr.properties.da80to84
                  ? prev
                  : curr;
              }).properties.da80to84
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da80to84 < curr.properties.da80to84
                  ? prev
                  : curr;
              }).properties.da80to84
            );
          }
          if (props.updateCatVal === "Age85Plus") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da85plus > curr.properties.da85plus
                  ? prev
                  : curr;
              }).properties.da85plus
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da85plus > curr.properties.da85plus
                  ? prev
                  : curr;
              }).properties.da85plus
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da85plus < curr.properties.da85plus
                  ? prev
                  : curr;
              }).properties.da85plus
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.da85plus < curr.properties.da85plus
                  ? prev
                  : curr;
              }).properties.da85plus
            );
          }
        }
        if (props.updateStyle === "income") {
          if (props.updateCatVal === "total_annual_income") {
            // console.log({
            // 	maxVal:geojson.features.reduce(function (prev, curr)
            // 	{
            // 		return prev.properties.dtotal_annual_income < curr.properties.dtotal_annual_income
            // 			? prev
            // 			: curr;
            // 	}).properties.dtotal_annual_income,
            // 	minVal: geojson.features.reduce(function (prev, curr)
            // 	{
            // 		return prev.properties.dtotal_annual_income > curr.properties.dtotal_annual_income
            // 			? prev
            // 			: curr;
            // 	}).properties.dtotal_annual_income
            // });

            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_annual_income >
                  curr.properties.dtotal_annual_income
                  ? prev
                  : curr;
              }).properties.dtotal_annual_income
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_annual_income >
                  curr.properties.dtotal_annual_income
                  ? prev
                  : curr;
              }).properties.dtotal_annual_income
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_annual_income <
                  curr.properties.dtotal_annual_income
                  ? prev
                  : curr;
              }).properties.dtotal_annual_income
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_annual_income <
                  curr.properties.dtotal_annual_income
                  ? prev
                  : curr;
              }).properties.dtotal_annual_income
            );
          }
          if (props.updateCatVal === "net_annual_income_before_housing_costs") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_before_housing_costs >
                  curr.properties.dnet_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_before_housing_costs
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_before_housing_costs >
                  curr.properties.dnet_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_before_housing_costs
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_before_housing_costs <
                  curr.properties.dnet_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_before_housing_costs
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_before_housing_costs <
                  curr.properties.dnet_annual_income_before_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_before_housing_costs
            );
          }
          if (props.updateCatVal === "net_annual_income_after_housing_costs") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_after_housing_costs >
                  curr.properties.dnet_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_after_housing_costs
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_after_housing_costs >
                  curr.properties.dnet_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_after_housing_costs
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_after_housing_costs <
                  curr.properties.dnet_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_after_housing_costs
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income_after_housing_costs <
                  curr.properties.dnet_annual_income_after_housing_costs
                  ? prev
                  : curr;
              }).properties.dnet_annual_income_after_housing_costs
            );
          }
          if (props.updateCatVal === "net_annual_income") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income >
                  curr.properties.dnet_annual_income
                  ? prev
                  : curr;
              }).properties.dnet_annual_income
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income >
                  curr.properties.dnet_annual_income
                  ? prev
                  : curr;
              }).properties.dnet_annual_income
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income <
                  curr.properties.dnet_annual_income
                  ? prev
                  : curr;
              }).properties.dnet_annual_income
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dnet_annual_income <
                  curr.properties.dnet_annual_income
                  ? prev
                  : curr;
              }).properties.dnet_annual_income
            );
          }
        }
        if (props.updateStyle === "people") {
          if (props.updateCatVal === "total_households") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_households >
                  curr.properties.dtotal_households
                  ? prev
                  : curr;
              }).properties.dtotal_households
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_households >
                  curr.properties.dtotal_households
                  ? prev
                  : curr;
              }).properties.dtotal_households
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_households <
                  curr.properties.dtotal_households
                  ? prev
                  : curr;
              }).properties.dtotal_households
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dtotal_households <
                  curr.properties.dtotal_households
                  ? prev
                  : curr;
              }).properties.dtotal_households
            );
          }
          if (props.updateCatVal === "people0") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dpeople0 > curr.properties.dpeople0
                  ? prev
                  : curr;
              }).properties.dpeople0
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dpeople0 > curr.properties.dpeople0
                  ? prev
                  : curr;
              }).properties.dpeople0
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dpeople0 < curr.properties.dpeople0
                  ? prev
                  : curr;
              }).properties.dpeople0
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dpeople0 < curr.properties.dpeople0
                  ? prev
                  : curr;
              }).properties.dpeople0
            );
          }
          if (props.updateCatVal === "person1") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson1 > curr.properties.dperson1
                  ? prev
                  : curr;
              }).properties.dperson1
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson1 > curr.properties.dperson1
                  ? prev
                  : curr;
              }).properties.dperson1
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson1 < curr.properties.dperson1
                  ? prev
                  : curr;
              }).properties.dperson1
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson1 < curr.properties.dperson1
                  ? prev
                  : curr;
              }).properties.dperson1
            );
          }
          if (props.updateCatVal === "person2") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson2 > curr.properties.dperson2
                  ? prev
                  : curr;
              }).properties.dperson2
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson2 > curr.properties.dperson2
                  ? prev
                  : curr;
              }).properties.dperson2
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson2 < curr.properties.dperson2
                  ? prev
                  : curr;
              }).properties.dperson2
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson2 < curr.properties.dperson2
                  ? prev
                  : curr;
              }).properties.dperson2
            );
          }
          if (props.updateCatVal === "person3") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson3 > curr.properties.dperson3
                  ? prev
                  : curr;
              }).properties.dperson3
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson3 > curr.properties.dperson3
                  ? prev
                  : curr;
              }).properties.dperson3
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson3 < curr.properties.dperson3
                  ? prev
                  : curr;
              }).properties.dperson3
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson3 < curr.properties.dperson3
                  ? prev
                  : curr;
              }).properties.dperson3
            );
          }
          if (props.updateCatVal === "person4") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson4 > curr.properties.dperson4
                  ? prev
                  : curr;
              }).properties.dperson4
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson4 > curr.properties.dperson4
                  ? prev
                  : curr;
              }).properties.dperson4
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson4 < curr.properties.dperson4
                  ? prev
                  : curr;
              }).properties.dperson4
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson4 < curr.properties.dperson4
                  ? prev
                  : curr;
              }).properties.dperson4
            );
          }
          if (props.updateCatVal === "person5") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson5 > curr.properties.dperson5
                  ? prev
                  : curr;
              }).properties.dperson5
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson5 > curr.properties.dperson5
                  ? prev
                  : curr;
              }).properties.dperson5
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson5 < curr.properties.dperson5
                  ? prev
                  : curr;
              }).properties.dperson5
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson5 < curr.properties.dperson5
                  ? prev
                  : curr;
              }).properties.dperson5
            );
          }
          if (props.updateCatVal === "person6") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson6 > curr.properties.dperson6
                  ? prev
                  : curr;
              }).properties.dperson6
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson6 > curr.properties.dperson6
                  ? prev
                  : curr;
              }).properties.dperson6
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson6 < curr.properties.dperson6
                  ? prev
                  : curr;
              }).properties.dperson6
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson6 < curr.properties.dperson6
                  ? prev
                  : curr;
              }).properties.dperson6
            );
          }
          if (props.updateCatVal === "person7") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson7 > curr.properties.dperson7
                  ? prev
                  : curr;
              }).properties.dperson7
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson7 > curr.properties.dperson7
                  ? prev
                  : curr;
              }).properties.dperson7
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson7 < curr.properties.dperson7
                  ? prev
                  : curr;
              }).properties.dperson7
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson7 < curr.properties.dperson7
                  ? prev
                  : curr;
              }).properties.dperson7
            );
          }
          if (props.updateCatVal === "person8") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8 > curr.properties.dperson8
                  ? prev
                  : curr;
              }).properties.dperson8
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8 > curr.properties.dperson8
                  ? prev
                  : curr;
              }).properties.dperson8
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8 < curr.properties.dperson8
                  ? prev
                  : curr;
              }).properties.dperson8
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8 < curr.properties.dperson8
                  ? prev
                  : curr;
              }).properties.dperson8
            );
          }
          if (props.updateCatVal === "person8ormore") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8ormore >
                  curr.properties.dperson8ormore
                  ? prev
                  : curr;
              }).properties.dperson8ormore
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8ormore >
                  curr.properties.dperson8ormore
                  ? prev
                  : curr;
              }).properties.dperson8ormore
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8ormore <
                  curr.properties.dperson8ormore
                  ? prev
                  : curr;
              }).properties.dperson8ormore
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dperson8ormore <
                  curr.properties.dperson8ormore
                  ? prev
                  : curr;
              }).properties.dperson8ormore
            );
          }
        }
        if (props.updateStyle === "religion") {
          if (props.updateCatVal === "christian") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dchristian > curr.properties.dchristian
                  ? prev
                  : curr;
              }).properties.dchristian
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dchristian > curr.properties.dchristian
                  ? prev
                  : curr;
              }).properties.dchristian
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dchristian < curr.properties.dchristian
                  ? prev
                  : curr;
              }).properties.dchristian
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dchristian < curr.properties.dchristian
                  ? prev
                  : curr;
              }).properties.dchristian
            );
          }
          if (props.updateCatVal === "hindu") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dhindu > curr.properties.dhindu
                  ? prev
                  : curr;
              }).properties.dhindu
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dhindu > curr.properties.dhindu
                  ? prev
                  : curr;
              }).properties.dhindu
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dhindu < curr.properties.dhindu
                  ? prev
                  : curr;
              }).properties.dhindu
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dhindu < curr.properties.dhindu
                  ? prev
                  : curr;
              }).properties.dhindu
            );
          }
          if (props.updateCatVal === "jewish") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.djewish > curr.properties.djewish
                  ? prev
                  : curr;
              }).properties.djewish
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.djewish > curr.properties.djewish
                  ? prev
                  : curr;
              }).properties.djewish
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.djewish < curr.properties.djewish
                  ? prev
                  : curr;
              }).properties.djewish
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.djewish < curr.properties.djewish
                  ? prev
                  : curr;
              }).properties.djewish
            );
          }
          if (props.updateCatVal === "muslim") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dmuslim > curr.properties.dmuslim
                  ? prev
                  : curr;
              }).properties.dmuslim
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dmuslim > curr.properties.dmuslim
                  ? prev
                  : curr;
              }).properties.dmuslim
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dmuslim < curr.properties.dmuslim
                  ? prev
                  : curr;
              }).properties.dmuslim
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dmuslim < curr.properties.dmuslim
                  ? prev
                  : curr;
              }).properties.dmuslim
            );
          }
          if (props.updateCatVal === "sikh") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dsikh > curr.properties.dsikh
                  ? prev
                  : curr;
              }).properties.dsikh
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dsikh > curr.properties.dsikh
                  ? prev
                  : curr;
              }).properties.dsikh
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dsikh < curr.properties.dsikh
                  ? prev
                  : curr;
              }).properties.dsikh
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dsikh < curr.properties.dsikh
                  ? prev
                  : curr;
              }).properties.dsikh
            );
          }
          if (props.updateCatVal === "buddhist") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dbuddhist > curr.properties.dbuddhist
                  ? prev
                  : curr;
              }).properties.dbuddhist
            );

            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dbuddhist > curr.properties.dbuddhist
                  ? prev
                  : curr;
              }).properties.dbuddhist
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dbuddhist < curr.properties.dbuddhist
                  ? prev
                  : curr;
              }).properties.dbuddhist
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dbuddhist < curr.properties.dbuddhist
                  ? prev
                  : curr;
              }).properties.dbuddhist
            );
          }
          if (props.updateCatVal === "no_religion") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dno_religion >
                  curr.properties.dno_religion
                  ? prev
                  : curr;
              }).properties.dno_religion
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dno_religion >
                  curr.properties.dno_religion
                  ? prev
                  : curr;
              }).properties.dno_religion
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dno_religion <
                  curr.properties.dno_religion
                  ? prev
                  : curr;
              }).properties.dno_religion
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dno_religion <
                  curr.properties.dno_religion
                  ? prev
                  : curr;
              }).properties.dno_religion
            );
          }
          if (props.updateCatVal === "other_religion") {
            setmaxVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dother_religion >
                  curr.properties.dother_religion
                  ? prev
                  : curr;
              }).properties.dother_religion
            );
            setFilterMaxValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dother_religion >
                  curr.properties.dother_religion
                  ? prev
                  : curr;
              }).properties.dother_religion
            );

            setminVal(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dother_religion <
                  curr.properties.dother_religion
                  ? prev
                  : curr;
              }).properties.dother_religion
            );

            setFilterMinValue(
              geojson.features.reduce(function (prev, curr) {
                return prev.properties.dother_religion <
                  curr.properties.dother_religion
                  ? prev
                  : curr;
              }).properties.dother_religion
            );
          }
        }
      }
      dispatch(handleLoadingFalse());
    };
    if (id === null) {
      return;
    } else getData();
  }, [
    map,
    isMsoa,
    props.change,
    props.updateStyle,
    props.updateCatVal,
    props.updateSubCatVal,
    msoaEstab,
    isloading,
  ]);

  useEffect(() => {
    if (props.reset) {
      setmsoaEstab(null);
      setData(null);
      props.setreset(false);
    }
  }, [props.reset]);

  const loadLayer = async (e) => {
    var data = e.target.feature.properties;

    if (!isMsoa || msoaEstab !== null) {
      return;
    }
    if (!data?.rgn21cd && !data?.ctyua21cd) {
      return "";
    }

    props.setreset(false);
    let oldDropdown = [...OldMapSlice?.area_dropdown_list];
    oldDropdown = oldDropdown.map((item) => ({ ...item, is_selected: 0 }));
    oldDropdown.push({
      value: data.rgn21nm,
      label: data.rgn21nm,
      is_selected: 1,
    });
    dispatch(SetAreaDropdown(oldDropdown));
    props.setLoading(true);
    props.selectedArea(e.target.feature);
    setmsoaEstab(data?.rgn21cd || data?.ctyua21cd);
    toast.dismiss();

    let finalOptions = [
      ...areaDropdownList,
      {
        value:
          props?.change === "counties_msoa" ? data?.ctyua21nm : data?.rgn21nm,
        name:
          props?.change === "counties_msoa" ? data?.ctyua21nm : data?.rgn21nm,
        disabled: true,
        is_selected: 1,
      },
    ];

    props.setoptionsAdmin(finalOptions);
    props.setValue({
      value:
        props?.change === "counties_msoa" ? data?.ctyua21nm : data?.rgn21nm,
      name: props?.change === "counties_msoa" ? data?.ctyua21nm : data?.rgn21nm,
      disabled: true,
      is_selected: 1,
    });
  };

  function highlightFeature(e) {
    var layer = e.target;

    props.featureHighlighted(e.target.feature);

    layer.setStyle({
      // fillColor: selectedDD ? getColor(e.target.feature.properties.id) : "#b2b2b2",
      weight: 2,
      // color: "#333",
      dashArray: "3",
      fillOpacity: 0.7,
    });

    layer.bringToFront();
  }

  function resetHighlight(e) {
    props.featureUnHighlighted();
    var layer = e.target;
    layer.setStyle({
      // fillColor: selectedDD ? getColor(e.target.feature.properties.id) : "#b2b2b2",
      weight: 0.5,
      color: "black",
      dashArray: "3",
      fillOpacity: 0.9,
    });
  }

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: loadLayer,
    });
  };

  function getColor(d) {
    if (props.activeIndex === 1) {
      if (selectedSubCat === "density") {
        // console.log("value of d", d);
        return d < 90 && d > -90 ? "#71d622" : "#ffffff";
        // return d > -90 && d < 0 ? "#00441b" : "#ffffff";
        // return "#71d622";
      }
      return d > 10 && d < 90 ? "#71d622" : "#ffffff";

      // return "#71d622";
    }
    return d < 10
      ? "#ffffff"
      : d < 20
      ? "#f7fcf5"
      : d < 30
      ? "#e5f5e0"
      : d < 40
      ? "#c7e9c0"
      : d < 50
      ? "#a1d99b"
      : d < 60
      ? "#74c476"
      : d < 70
      ? "#41ab5d"
      : d < 80
      ? "#238b45"
      : d < 90
      ? "#006d2c"
      : d > 90
      ? "#00441b"
      : "#c3c3c3";
  }

  const getBorderColor = (d) => {
    if (props.activeIndex === 1) {
      if (selectedSubCat === "density") {
        return d < 90 && d > -90 ? "#71d622" : "#ffffff";
      }
      return d > 10 && d < 90 ? "#71d622" : "#ffffff";
    }
    return "black";
  };

  function getStyle(feature) {
    // console.log("feature in style", feature);
    var props = (feature.properties = feature.properties || {});
    if (selectedSubCat === "actual") {
      try {
        if (selectedDD === "population") {
          props.id = ((props.population - minVal) / (maxVal - minVal)) * 100;
        }
        if (selectedDD === "gender") {
          if (selectedCat === "genderm") {
            props.id = ((props.genderm - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "genderf") {
            props.id = ((props.genderf - minVal) / (maxVal - minVal)) * 100;
          }
        }
        if (selectedDD === "age") {
          if (selectedCat === "Age0to4") {
            props.id = ((props.age0to4 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age5to9") {
            props.id = ((props.age5to9 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age10to14") {
            props.id = ((props.age10to14 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age15to19") {
            props.id = ((props.age15to19 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age20to24") {
            props.id = ((props.age20to24 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age25to29") {
            props.id = ((props.age25to29 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age30to34") {
            props.id = ((props.age30to34 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age35to39") {
            props.id = ((props.age35to39 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age40to44") {
            props.id = ((props.age40to44 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age45to49") {
            props.id = ((props.age45to49 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age50to54") {
            props.id = ((props.age50to54 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age55to59") {
            props.id = ((props.age55to59 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age60to64") {
            props.id = ((props.age60to64 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age65to69") {
            props.id = ((props.age65to69 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age70to74") {
            props.id = ((props.age70to74 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age75to79") {
            props.id = ((props.age75to79 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age80to84") {
            props.id = ((props.age80to84 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "Age85Plus") {
            props.id = ((props.age85plus - minVal) / (maxVal - minVal)) * 100;
          }
        }
        if (selectedDD === "religion") {
          if (selectedCat === "hindu") {
            props.id = ((props.hindu - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "jewish") {
            props.id = ((props.jewish - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "muslim") {
            props.id = ((props.muslim - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "sikh") {
            props.id = ((props.sikh - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "christian") {
            props.id = ((props.christian - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "buddhist") {
            props.id = ((props.buddhist - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "no_religion") {
            props.id = ((props.no_religion - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "other_religion") {
            props.id =
              ((props.other_religion - minVal) / (maxVal - minVal)) * 100;
          }
        }
        if (selectedDD === "people") {
          if (selectedCat === "total_households") {
            props.id =
              ((props.total_households - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "people0") {
            props.id = ((props.people0 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person1") {
            props.id = ((props.person1 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person2") {
            props.id = ((props.person2 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person3") {
            props.id = ((props.person3 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person4") {
            props.id = ((props.person4 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person5") {
            props.id = ((props.person5 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person6") {
            props.id = ((props.person6 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person7") {
            props.id = ((props.person7 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person8") {
            props.id = ((props.person8 - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "person8ormore") {
            props.id =
              ((props.person8ormore - minVal) / (maxVal - minVal)) * 100;
          }
        }
        if (selectedDD === "income") {
          if (selectedCat === "total_annual_income") {
            props.id =
              ((props.total_annual_income - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedCat === "net_annual_income_before_housing_costs") {
            props.id =
              ((props.net_annual_income_before_housing_costs - minVal) /
                (maxVal - minVal)) *
              100;
          }
          if (selectedCat === "net_annual_income_after_housing_costs") {
            props.id =
              ((props.net_annual_income_after_housing_costs - minVal) /
                (maxVal - minVal)) *
              100;
          }
          if (selectedCat === "net_annual_income") {
            props.id =
              ((props.net_annual_income - minVal) / (maxVal - minVal)) * 100;
          }
        }
      } catch (e) {}
    }
    if (selectedSubCat === "density") {
      // console.log(minVal);
      // console.log(maxVal);
      if (msoaEstab) {
        try {
          if (selectedDD === "population") {
            props.id = ((props.dpopu - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedDD === "gender") {
            if (selectedCat === "genderm") {
              props.id = ((props.dgm - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "genderf") {
              props.id = ((props.dgf - minVal) / (maxVal - minVal)) * 100;
            }
          }
          if (selectedDD === "age") {
            if (selectedCat === "Age0to4") {
              props.id = ((props.da0to4 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age5to9") {
              props.id = ((props.da5to9 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age10to14") {
              props.id = ((props.da10to14 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age15to19") {
              props.id = ((props.da15to19 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age20to24") {
              props.id = ((props.da20to24 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age25to29") {
              props.id = ((props.da25to29 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age30to34") {
              props.id = ((props.da30to34 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age35to39") {
              props.id = ((props.da35to39 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age40to44") {
              props.id = ((props.da40to44 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age45to49") {
              props.id = ((props.da45to49 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age50to54") {
              props.id = ((props.da50to54 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age55to59") {
              props.id = ((props.da55to59 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age60to64") {
              props.id = ((props.da60to64 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age65to69") {
              props.id = ((props.da65to69 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age70to74") {
              props.id = ((props.da70to74 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age75to79") {
              props.id = ((props.da75to79 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age80to84") {
              props.id = ((props.da80to84 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age85Plus") {
              props.id = ((props.da85plus - minVal) / (maxVal - minVal)) * 100;
            }
          }

          if (selectedDD === "religion") {
            if (selectedCat === "hindu") {
              props.id = ((props.dhindu - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "jewish") {
              props.id = ((props.djewish - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "muslim") {
              props.id = ((props.dmuslim - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "sikh") {
              props.id = ((props.dsikh - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "christian") {
              props.id =
                ((props.dchristian - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "buddhist") {
              props.id = ((props.dbuddhist - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "no_religion") {
              props.id =
                ((props.dno_religion - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "other_religion") {
              props.id =
                ((props.dother_religion - minVal) / (maxVal - minVal)) * 100;
            }
          }
          if (selectedDD === "people") {
            if (selectedCat === "total_households") {
              props.id =
                ((props.dtotal_households - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "people0") {
              props.id = ((props.dpeople0 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person1") {
              props.id = ((props.dperson1 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person2") {
              props.id = ((props.dperson2 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person3") {
              props.id = ((props.dperson3 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person4") {
              props.id = ((props.dperson4 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person5") {
              props.id = ((props.dperson5 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person6") {
              props.id = ((props.dperson6 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person7") {
              props.id = ((props.dperson7 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person8") {
              props.id = ((props.dperson8 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person8ormore") {
              props.id =
                ((props.dperson8ormore - minVal) / (maxVal - minVal)) * 100;
            }
          }
          if (selectedDD === "income") {
            if (selectedCat === "total_annual_income") {
              props.id =
                ((props.dtotal_annual_income - minVal) / (maxVal - minVal)) *
                100;
            }
            if (selectedCat === "net_annual_income_before_housing_costs") {
              props.id =
                ((props.dnet_annual_income_before_housing_costs - minVal) /
                  (maxVal - minVal)) *
                100;
            }
            if (selectedCat === "net_annual_income_after_housing_costs") {
              props.id =
                ((props.dnet_annual_income_after_housing_costs - minVal) /
                  (maxVal - minVal)) *
                100;
            }
            if (selectedCat === "net_annual_income") {
              props.id =
                ((props.dnet_annual_income - minVal) / (maxVal - minVal)) * 100;
            }
          }
        } catch (e) {}
      } else {
        try {
          if (selectedDD === "population") {
            props.id = ((props.dpopu - minVal) / (maxVal - minVal)) * 100;
          }
          if (selectedDD === "gender") {
            if (selectedCat === "genderm") {
              props.id = ((props.dgm - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "genderf") {
              props.id = ((props.dgf - minVal) / (maxVal - minVal)) * 100;
            }
          }
          if (selectedDD === "age") {
            if (selectedCat === "Age0to4") {
              props.id = ((props.da0to4 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age5to9") {
              props.id = ((props.da5to9 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age10to14") {
              props.id = ((props.da10to14 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age15to19") {
              props.id = ((props.da15to19 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age20to24") {
              props.id = ((props.da20to24 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age25to29") {
              props.id = ((props.da25to29 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age30to34") {
              props.id = ((props.da30to34 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age35to39") {
              props.id = ((props.da35to39 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age40to44") {
              props.id = ((props.da40to44 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age45to49") {
              props.id = ((props.da45to49 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age50to54") {
              props.id = ((props.da50to54 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age55to59") {
              props.id = ((props.da55to59 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age60to64") {
              props.id = ((props.da60to64 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age65to69") {
              props.id = ((props.da65to69 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age70to74") {
              props.id = ((props.da70to74 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age75to79") {
              props.id = ((props.da75to79 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age80to84") {
              props.id = ((props.da80to84 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "Age85Plus") {
              props.id = ((props.da85plus - minVal) / (maxVal - minVal)) * 100;
            }
          }
          if (selectedDD === "religion") {
            if (selectedCat === "hindu") {
              props.id = ((props.dhindu - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "jewish") {
              props.id = ((props.djewish - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "muslim") {
              props.id = ((props.dmuslim - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "sikh") {
              props.id = ((props.dsikh - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "christian") {
              props.id =
                ((props.dchristian - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "buddhist") {
              props.id = ((props.dbuddhist - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "no_religion") {
              props.id =
                ((props.dno_religion - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "other_religion") {
              props.id =
                ((props.dother_religion - minVal) / (maxVal - minVal)) * 100;
            }
          }
          if (selectedDD === "people") {
            if (selectedCat === "total_households") {
              props.id =
                ((props.dtotal_households - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "people0") {
              props.id = ((props.dpeople0 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person1") {
              props.id = ((props.dperson1 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person2") {
              props.id = ((props.dperson2 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person3") {
              props.id = ((props.dperson3 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person4") {
              props.id = ((props.dperson4 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person5") {
              props.id = ((props.dperson5 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person6") {
              props.id = ((props.dperson6 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person7") {
              props.id = ((props.dperson7 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person8") {
              props.id = ((props.dperson8 - minVal) / (maxVal - minVal)) * 100;
            }
            if (selectedCat === "person8ormore") {
              props.id =
                ((props.dperson8ormore - minVal) / (maxVal - minVal)) * 100;
            }
          }
          if (selectedDD === "income") {
            if (selectedCat === "total_annual_income") {
              props.id =
                ((props.dtotal_annual_income - minVal) / (maxVal - minVal)) *
                100;
            }
            if (selectedCat === "net_annual_income_before_housing_costs") {
              props.id =
                ((props.dnet_annual_income_before_housing_costs - minVal) /
                  (maxVal - minVal)) *
                100;
            }
            if (selectedCat === "net_annual_income_after_housing_costs") {
              props.id =
                ((props.dnet_annual_income_after_housing_costs - minVal) /
                  (maxVal - minVal)) *
                100;
            }
            if (selectedCat === "net_annual_income") {
              props.id =
                ((props.dnet_annual_income - minVal) / (maxVal - minVal)) * 100;
            }
          }
        } catch (e) {}
      }
      // console.log(props.id);
    }
    return {
      fillColor: selectedDD ? getColor(feature.properties.id) : "#b2b2b2",
      // fillColor:
      //   props.activeIndex === 1
      //     ? "#b2b423"
      //     : selectedDD
      //     ? getColor(feature.properties.id)
      //     : "#b2b2b2",
      // fillColor: "#b2b2b2",
      // fillColor: "#b2b423",
      weight: 0.5,
      color: selectedDD ? getBorderColor(feature.properties.id) : "black",
      dashArray: "3",
      fillOpacity: 0.9,
    };
  }

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };

  console.log("selectedDD", selectedDD);

  if (data) {
    return props.showHeatmap ? (
      <GeoJSON
        key={generateKey("gjson")}
        style={getStyle}
        data={data.features}
        onEachFeature={onEachFeature}
      />
    ) : (
      ""
    );
  } else {
    return "";
  }
};

export default VectorData;
