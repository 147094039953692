import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { RxEyeClosed } from "react-icons/rx";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { CallGETAPI, CallPOSTAPI } from "../../helpers/API";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import EditLocationModal from "../modals/EditLocationModal";
import { useDispatch } from "react-redux";
import {
  addDistanceLocation,
  handleAddSelectedLocations,
  handleLoadingFalse,
  handleLoadingTrue,
  handleRemoveSelectedLocations,
  removeDistanceLocation,
} from "../../redux/slices/LocationProjectSlice";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { FiEdit } from "react-icons/fi";
// import CustomAvatar from '../TableComponents/CustomAvatar'
// import { ReactComponent as RedArrow } from '../../assets/icons/High.svg'
// import { ReactComponent as YellowArrow } from '../../assets/icons/Medium.svg'
// import { ReactComponent as BlueArrow } from '../../assets/icons/Low.svg'

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 106px;
  border-radius: 5px;
  max-width: 311px;
  /* background: ${({ isDragging }) =>
    isDragging ? "rgba(255, 59, 59, 0.15)" : "white"}; */
  background: white;
  margin-top: 15px;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;
const checkboxStyle = {
  position: "absolute",
  top: "0px",
  backgroundColor: "red",
  height: "36px",
  width: "80%",
  opacity: 0,
  left: 0,
  zIndex: 10,
};
const TaskCard = ({
  item,
  index,
  handleCheckboxChange,
  color,
  fetchList,
  projectId,
  // itemChecked,
  listName,
  projectName,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const selectedLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );
  const travelLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const combinedLocations = [...selectedLocation, ...travelLocation];

  const currentProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const deleteCard = async () => {
    const res = await CallGETAPI("deleteLocation/" + item?.location_id);
    if (res?.data?.status) {
      fetchList();
      if (item?.catchment_area === "Distance") {
        dispatch(handleRemoveSelectedLocations(item?.location_id));
      } else {
        dispatch(removeDistanceLocation(item?.location_id));
      }
      toast.success(res?.data?.msg);
    } else {
      toast.error(res?.data?.msg);
    }
  };

  const handleLocationStatus = async (e, item) => {
    // setUpdateLoading(true);
    // let data = e.target.checked
    let data = e
      ? {
          id: [item.location_id],
          is_checked: 1,
          user_id: user?.sub?.split("|")[1],
        }
      : {
          id: [item.location_id],
          is_checked: 0,
          user_id: user?.sub?.split("|")[1],
        };

    const res = await CallPOSTAPI("locationChecked/", data);

    if (res?.data?.status) {
      setUpdateLoading(false);
    } else {
      toast.error(res?.data?.msg);
    }
  };
  const [isChecked, setIsChecked] = useState(item?.location_checked);
  useEffect(() => {
    // if (currentProjects.length > 0) {
    const check = combinedLocations.find(
      (loc) => loc.location_id === item?.location_id
    );
    if (check) {
      setIsChecked(1);
    } else {
      setIsChecked(0);
    }
    // }
  }, [combinedLocations]);
  return (
    <>
      <EditLocationModal
        editLocationModal={editLocationModal}
        setEditLocationModal={setEditLocationModal}
        item={item}
        fetchList={fetchList}
        color={color}
        projectId={projectId}
        projectName={projectName}
        listName={listName}
      />
      <Draggable key={item.id} draggableId={item.id} index={index} id={item.id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // style={{ backgroundColor: "#" }}
          >
            {/* <TaskInformation> */}
            <div
              className="mx-auto rounded-3 px-3 py-2 d-flex justify-content-between align-items-center"
              style={{
                // backgroundColor: "#ffffff",
                // width: "95%",
                marginTop: "5px",
                marginBottom: "5px",
                position: "relative",
              }}
            >
              {/* <label
                htmlFor={"card_checkbox_" + item.location_id}
                className="d-flex gap-2 align-items-center checkbox-110"
                style={{ position: "relative", zIndex: 0 }}
              >
                <input
                  type="checkbox"
                  name={"check_" + item.location_id}
                  id={"card_checkbox_" + item.location_id}
                  value={item?.location_id}
                  onChange={async (e) => {
                    dispatch(handleLoadingTrue());
                    setUpdateLoading(true);
                    setIsChecked(!isChecked);
                    console.log("is checked value", isChecked);
                    await handleCheckboxChange(
                      e,
                      item,
                      color,
                      projectId,
                      listName,
                      projectName
                    );
                    await handleLocationStatus(e, item);
                    dispatch(handleLoadingFalse());
                  }}
                  checked={isChecked}
                  disabled={updateLoading}
                />
              </label> */}

              <div className="d-flex justify-content-center align-items-center gap-1">
                {isChecked ? (
                  <AiOutlineEye
                    size={18}
                    onClick={async (e) => {
                      dispatch(handleLoadingTrue());
                      setUpdateLoading(true);
                      setIsChecked(!isChecked);
                      console.log("is checked value", isChecked);
                      await handleCheckboxChange(
                        false,
                        item,
                        color,
                        projectId,
                        listName,
                        projectName
                      );
                      await handleLocationStatus(false, item);
                      dispatch(handleLoadingFalse());
                    }}
                  />
                ) : (
                  <RxEyeClosed
                    size={18}
                    onClick={async (e) => {
                      dispatch(handleLoadingTrue());
                      setUpdateLoading(true);
                      setIsChecked(!isChecked);
                      console.log("is checked value", isChecked);
                      await handleCheckboxChange(
                        true,
                        item,
                        color,
                        projectId,
                        listName,
                        projectName
                      );
                      await handleLocationStatus(true, item);
                      dispatch(handleLoadingFalse());
                    }}
                  />
                )}
                <span className="main-heading" style={{ maxWidth: "130px" }}>
                  {item.title}
                </span>
              </div>

              <div className="secondary-details d-flex justify-content-between align-items-center gap-1">
                <FiEdit
                  // size={18}
                  style={{ cursor: "pointer" }}
                  onClick={() => setEditLocationModal(true)}
                />
                {/* <AiOutlineDelete size={20} /> */}

                <RiDeleteBinLine
                  // size={18}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    confirmAlert({
                      customUI: ({ onClose }) => {
                        return (
                          <div className="custom-confirm-alert">
                            <div
                              style={{
                                width: "60%",
                                margin: "auto",
                                textAlign: "center",
                              }}
                            >
                              <h1
                                className="main-heading text-uppercase"
                                style={{ fontSize: "18px" }}
                              >
                                Are you sure you want to delete this item?
                              </h1>
                            </div>

                            <div className="d-flex flex-column justify-content-between gap-4 mt-5">
                              <button
                                className="search-btn"
                                style={{ backgroundColor: "#ff6b62" }}
                                onClick={() => {
                                  deleteCard();
                                  onClose();
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="search-btn"
                                style={{
                                  backgroundColor: "#fff",
                                  border: "1px solid #d9d9d9",
                                  color: "black",
                                }}
                                onClick={onClose}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        );
                      },
                      // title: "Delete Location?",
                      // message: "Are you sure to do this.",
                      // buttons: [
                      //   {
                      //     label: "Yes",
                      //     onClick: () => deleteCard(),
                      //   },
                      //   {
                      //     label: "No",
                      //     onClick: () => {},
                      //   },
                      // ],
                    });
                  }}
                />

                {/* <p>
                <span>
                  {new Date(item.Due_Date).toLocaleDateString('en-us', {
                    month: 'short',
                    day: '2-digit',
                  })}
                </span>
              </p> */}
              </div>
            </div>
            <hr
              className="mb-2 mx-auto"
              style={{ width: "90%", marginTop: "-5px" }}
            />
            {/* </TaskInformation> */}
          </div>
        )}
      </Draggable>
    </>
  );
};

export default TaskCard;

// <span className="priority">
// {item.Priority === 'High' ? (<RedArrow />) : item.Priority === 'Medium' ? (<YellowArrow />) : (<BlueArrow />)}
// </span>
// <div><CustomAvatar name={item.Assignee} isTable={false} size={16} /></div>
