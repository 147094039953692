import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import AutoCompleteInput from "../AutocompleteInput/AutoCompleteInput";
import ColorPalette from "../../ColorPallete";
import { Bicycle, CarIcon, WalkingMan } from "../../Icons";
import { BASE_API, CallPOSTAPI } from "../../helpers/API";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addDistanceLocation,
  handleAddSelectedLocations,
  handleRemoveSelectedLocations,
  removeDistanceLocation,
} from "../../redux/slices/LocationProjectSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useSelector } from "react-redux";

function EditLocationModal({
  editLocationModal,
  setEditLocationModal,
  item,
  fetchList,
  color,
  projectId,
  projectName,
  listName,
}) {
  const dispatch = useDispatch();
  let itemColor = color;

  const [volume, setVolume] = useState(item.distance_radius);
  const [selectedOption, setSelectedOption] = useState(item.catchment_area);
  const [showName, setShowName] = useState(item.catchment_area ? true : null);
  // input fields
  const [ColorData, setColorData] = useState("");
  const [DistanceVal, setDistanceVal] = useState(5);
  const [DistanceTime, setDistanceTime] = useState(item.travel_time);
  const [walingType, setwalingType] = useState(item.travel_way);
  const [LocationName, setLocationName] = useState(item.location_name);
  const [place, setPlace] = useState({});
  const location = useLocation();
  const locationId = location.search.slice(1);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setVolume(event.target.value);
    setDistanceVal(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setShowName(event.target.checked);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const userId = user?.sub?.slice(6);

  const [locationInput, setLocationinput] = useState({
    lat: item.lat,
    long: item.long,
    name: item.address,
  });
  const [loading, setLoading] = useState(false);
  const autoCompletInputHandle = (val) => {
    setLocationinput(val);
  };

  const radiusLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );
  const travelLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const combinedLocations = [...radiusLocation, ...travelLocation];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const sendObj = {
      user_id: userId,
      id: Number(item.location_id),
      lat: locationInput.lat,
      long: locationInput.long,
      catchment_area: showName ? selectedOption : null,
      distance_radius: volume,
      travel_way: walingType,
      travel_time: DistanceTime,
      location_name: LocationName.trim(),
      address: locationInput.name,
      list_id: item.list_id,
    };

    if (!user) {
      toast.error("Invalid User Please Login");
      return "";
    }

    // if (!selectedOption) {
    //   toast.error("Please Select Catchment Area");
    //   return "";
    // }

    if (!LocationName) {
      toast.error("Location Name Required");
      return "";
    }

    if (showName && !selectedOption) {
      toast.error("Please Select Catchment Area Type");
      return "";
    }

    if (selectedOption === "Distance") {
      sendObj.travel_time = 0;
      sendObj.travel_way = null;
      if (
        !sendObj.distance_radius ||
        sendObj.distance_radius === 0 ||
        volume === 0 ||
        sendObj.distance_radius === "0" ||
        volume === "0"
      ) {
        toast.error("Please Set Distance Renge");
        return "";
      }
    }

    if (selectedOption === "TravelTime") {
      sendObj.distance_radius = 0;
      if (!sendObj.travel_way) {
        toast.error("Please Select travel Way");
        return "";
      }
      if (
        !sendObj.travel_time ||
        sendObj.travel_time === 0 ||
        DistanceTime === 0 ||
        sendObj.travel_time === "0" ||
        DistanceTime === "0"
      ) {
        toast.error("Please Set Time Renge");
        return "";
      }
    }

    setLoading(true);
    const res = await CallPOSTAPI(
      `updateLocation/${item.location_id}`,
      sendObj
    );
    if (res.data.status) {
      item?.catchment_area === "Distance" || item?.catchment_area === null
        ? dispatch(handleRemoveSelectedLocations(item?.location_id))
        : dispatch(removeDistanceLocation(item?.location_id));
      let newObj = { ...item };
      const {
        id,
        lat,
        long,
        catchment_area,
        distance_radius,
        travel_way,
        travel_time,
        location_name,
        address,
        // color,
      } = res.data.data;
      if (combinedLocations.find((obj) => obj.location_id === id)) {
        if (catchment_area === "Distance" || catchment_area === null) {
          // console.log("catchment area distance");
          dispatch(handleRemoveSelectedLocations(id));
          newObj.lat = lat;
          newObj.long = long;
          newObj.catchment_area = catchment_area;
          newObj.distance_radius = distance_radius;
          newObj.travel_way = travel_way;
          newObj.travel_time = travel_time;
          newObj.location_name = location_name;
          newObj.address = address;
          newObj.color = color;
          newObj.projectId = projectId;
          newObj.location_id = id;
          newObj.projectName = projectName;
          newObj.id = id;
          dispatch(handleAddSelectedLocations([newObj]));
        } else {
          console.log("catchment area travel location");
          dispatch(removeDistanceLocation(id));
          let localKey =
            item.location_id.toString() +
            lat.toString() +
            long.toString() +
            travel_way.toString() +
            travel_time.toString() +
            color.toString();
          const existdata = localStorage.getItem(localKey);
          if (
            !existdata ||
            item.travel_way !== travel_way ||
            item.travel_time !== travel_time
          ) {
            try {
              const response = await axios.get(
                `https://api.mapbox.com/isochrone/v1/mapbox/${travel_way}/${long},${lat}?contours_minutes=${travel_time}&contours_colors=${
                  color?.slice(1) || ""
                }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
              );
              let memoObject = {
                lat: lat,
                long: long,
                locationName: location_name,
                location_id: item.location_id,
                projectId,
                list_id: item.list_id,
                listName,
                projectName,
                travelWay: travel_way,
                ...response.data.features[0],
              };
              localStorage.setItem(localKey, JSON.stringify(memoObject));
              dispatch(addDistanceLocation([memoObject]));
            } catch (error) {
              toast.error(error.msg);
            }
          } else {
            let newObj = JSON.parse(existdata);
            newObj.locationName = location_name;
            dispatch(addDistanceLocation([newObj]));
          }
        }
      }
      toast.success(res.data.msg);
      setColorData("");
      setDistanceVal("");
      setDistanceTime("");
      setwalingType("");
      setLocationName("");
      setSelectedOption(null);
      setLocationinput({
        lat: "",
        long: "",
        name: "",
      });
      setEditLocationModal(false);
      fetchList();
      setLoading(false);
    } else {
      toast.error(res.data.msg);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        show={editLocationModal}
        onHide={() => setEditLocationModal(false)}
        centered
        // size="lg"
      >
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b className="text-uppercase">Update Location</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <div class="container"></div>

          <form className="" onSubmit={handleSubmit} id="update-location-form">
            {/* add-location */}

            {/* <div class="container"> */}
            <div class="mt-2">
              <label htmlFor="location_name" className="mb-2">
                <b>Search For Location</b>
              </label>
              <AutoCompleteInput
                setPlace={setPlace}
                address={item?.address}
                handleValue={autoCompletInputHandle}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <label htmlFor="location_name">
                {" "}
                <b class="d-flex mt-2">Location Name</b>{" "}
              </label>
              <input
                type="text"
                name=""
                id="location_name"
                placeholder="Enter Location Name..."
                onChange={(e) => setLocationName(e.target.value.trimStart())}
                value={LocationName}
                className="form-control modal-custom-input italic-input"
              />
            </div>
            {/* </div> */}

            {/* <b style={{ marginLeft: "1rem" }} className="d-flex mt-2">
                Colour
              </b>
              <div style={{ marginLeft: "1rem" }} className="mt-2">
                {" "}
                <ColorPalette setInput={setColorData} color={ColorData} />{" "}
              </div> */}

            <div className="mt-4">
              <label htmlFor="location_name" className="">
                <b>Catchment Area</b>
              </label>
              {/* <label className="checkbox1">
                <input
                  type="checkbox"
                  checked={showName}
                  onChange={handleCheckboxChange}
                />
                &nbsp;
                <b>Add Catchment Area</b>
              </label>
              <br /> */}
              {showName && (
                <p>
                  <div
                    style={{ margin: "14px", gap: "2rem" }}
                    className="d-flex justify-content-start"
                  >
                    <div className="flex flex-col">
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2rem",
                        }}
                      >
                        <label className="label gap-2 d-flex">
                          <input
                            type="radio"
                            value="Distance"
                            checked={selectedOption === "Distance"}
                            onChange={handleRadioChange}
                            className="modal-custom-radio"
                          />
                          Distance
                        </label>
                        <label className="label gap-2 d-flex">
                          <input
                            type="radio"
                            value="TravelTime"
                            checked={selectedOption === "TravelTime"}
                            onChange={handleRadioChange}
                            className="modal-custom-radio"
                          />
                          Travel Time
                        </label>
                        <label className="label gap-2 d-flex">
                          <input
                            type="radio"
                            value="none"
                            checked={selectedOption === "none"}
                            onChange={handleRadioChange}
                            className="modal-custom-radio"
                          />
                          None
                        </label>
                      </span>

                      <div className="d-flex flex-column">
                        {selectedOption === "TravelTime" && (
                          <>
                            <div className="icon-flex">
                              <label
                                htmlFor={"id_driving"}
                                className={`icons ${
                                  walingType === "driving" ? "checkedbox" : ""
                                } `}
                              >
                                <input
                                  style={{ display: "none" }}
                                  id={"id_driving"}
                                  type="radio"
                                  value="driving"
                                  onClick={(e) => setwalingType(e.target.value)}
                                />
                                <CarIcon />
                              </label>
                              <label
                                htmlFor={"id_walking"}
                                className={`icons ${
                                  walingType === "walking" ? "checkedbox" : ""
                                } `}
                              >
                                <input
                                  style={{ display: "none" }}
                                  id={"id_walking"}
                                  type="radio"
                                  value="walking"
                                  onClick={(e) => setwalingType(e.target.value)}
                                />
                                <WalkingMan
                                  color={
                                    walingType === "walking" ? "#fff" : "#000"
                                  }
                                />
                              </label>
                              <label
                                htmlFor={"id_cycling"}
                                className={`icons ${
                                  walingType === "cycling" ? "checkedbox" : ""
                                } `}
                              >
                                <input
                                  style={{ display: "none" }}
                                  id={"id_cycling"}
                                  type="radio"
                                  value="cycling"
                                  onClick={(e) => setwalingType(e.target.value)}
                                />
                                <Bicycle />
                              </label>
                            </div>

                            <div className="volume-container ">
                              <div className="range-container">
                                <input
                                  type="range"
                                  min={0}
                                  max={60}
                                  value={DistanceTime}
                                  onChange={(e) =>
                                    setDistanceTime(e.target.value)
                                  }
                                  className="volume-slider"
                                  style={{ height: "0.3rem" }}
                                  step={1}
                                />
                              </div>
                              <span className="d-flex align-items-center position-relative">
                                <input
                                  style={{ width: "80px" }}
                                  type="number"
                                  value={DistanceTime}
                                  min={0}
                                  max={60}
                                  step={1}
                                  onChange={(e) =>
                                    setDistanceTime(e.target.value)
                                  }
                                  className="modal-custom-input form-control"
                                />
                                <b
                                  className="position-absolute"
                                  style={{ right: "12px" }}
                                >
                                  Min
                                </b>
                              </span>
                              {/* <span>
                                {" "}
                                <b>{DistanceTime}Min</b>{" "}
                              </span> */}
                            </div>
                          </>
                        )}
                        {selectedOption === "Distance" && (
                          <div className="volume-container ">
                            <div className="range-container">
                              <input
                                type="range"
                                min={0}
                                max={10}
                                step={0.1}
                                value={volume}
                                onChange={handleChange}
                                className="volume-slider"
                                style={{ height: "0.3rem" }}
                              />
                            </div>
                            <span className="d-flex align-items-center position-relative">
                              <input
                                style={{ width: "80px" }}
                                type="number"
                                value={volume}
                                min={0}
                                max={10}
                                step={0.1}
                                onChange={handleChange}
                                className="modal-custom-input form-control"
                              />
                              <b
                                className="position-absolute"
                                style={{ right: "12px" }}
                              >
                                Km
                              </b>
                            </span>
                            {/* <span>
                              {" "}
                              <b>{volume}km</b>{" "}
                            </span> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="modal-custom-footer">
          <button
            className="search-btn"
            form="update-location-form"
            disabled={loading}
          >
            {" "}
            {loading ? "Loading..." : "UPDATE LOCATION"}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditLocationModal;
