import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import './Forgotpassword.css'; // You can create your own CSS file for styling
import { CallPOSTAPI } from './helpers/API';
import { Container, Row, Col } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import { BASE_API } from './helpers/API';

const Forgotpassword = ({ setCrrPath, }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    toast.warning("Testing the warning message",{
      position: toast.POSITION.TOP_CENTER
  });
    e.preventDefault();

    if (email === '') {
      setErrorMessage('Please enter your email');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(BASE_API+'forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.status === true) {
        message.success(data.msg);
      } else {
        message.error(data.msg);
      }
    } catch (error) {
      console.error('Error sending forgot password request:', error);
      message.error('An error occurred while processing your request.');
    }

    setLoading(false);
  };


  const headerStyle = {
    // color: "#fff",
    height: 64,
    paddingInline: 0,
    lineHeight: "64px",
    // boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.1)", // Box shadow values

    // textAlign: "right",
  };



  return (
    <div className='container-fluid'>
      <Row style={headerStyle}>
        <Col sm={3} style={{ textAlign: "left", paddingLeft: '14px' }}>
          <a href="/"><img width={160} src={'logo.svg'} alt="mapozone logo" /></a>
        </Col>
        {/* <Col sm={4} style={{textAlign:'right'}} >
        
    </Col> */}

      </Row>
      <div className="Forgotpassword-page">
        <div className="Forgotpassword-container">
          <h2 className='font-l'>Forgot Password</h2>
          <form className="login-form" onSubmit={handleSubmit}>

            <Input className='input'
              prefix={<MailOutlined style={{ color: 'gray' }} />}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
            {/*style={{backgroundColor:"#ff69b4",marginTop:"10px",paddingTop:"20px",paddingBottom:"30px",color:"white",fontWeight:"bolder"}} */}
            <button style={{ marginTop: "1rem" }} className='btn1 register-submit' type="submit">
              {loading ? 'Loading...' : 'SUBMIT'}
            </button>
          </form>
          <div className="signup-link">
            Already a member <Link to={'/'}>Sign in</Link>
          </div>

          {/*      
      <div className='forgot-m'>  <a className="forgot-link" href="#"> Go to Login </a>
      </div> */}


        </div>
      </div></div>
  );
};




export default Forgotpassword;
// <div className="col-6" >
// <button className = "" onClick={()=>setCrrPath('register')}  > Go To Register Page</button>
// </div>