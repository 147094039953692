import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { TokenKey } from "./helpers/Constants";
import axios from "axios";
import { BASE_API } from "./helpers/API";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PaymentCancelled = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    toast.error("You have cancelled the payment.");
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Spin tip="Mapozone Loading..." spinning={loading} size="large">
        <button className="search-btn px-3" onClick={() => navigate("/")}>
          Proceed To Home
        </button>
      </Spin>
    </div>
  );
};

export default PaymentCancelled;
