import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Auth0Provider } from "@auth0/auth0-react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <Auth0Provider
      domain="dev-sqip4n0s6x6e1ozr.us.auth0.com"
      clientId="q7umCiZL4ytqGPnMen1GYoox47fPTfn8"
      redirectUri={window.location.origin}
    > */}
      <Auth0Provider domain='app-mapozone.uk.auth0.com' clientId='taToyorQreKz8GEUlQ0oW8ZRoZlLsXLU' redirectUri={window.location.origin}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </Provider>
);
