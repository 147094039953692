import { createSlice } from '@reduxjs/toolkit'
import { area_dropdownd } from '../../map_pages/Constants';
const initialState = { 
    area_dropdown_list: area_dropdownd,
    selected_area: "",
}

export const OldMapSlice = createSlice({
    name:"OldMap",
    initialState,
    reducers:{
        SetAreaDropdown: (state, action) => {
            state.area_dropdown_list = action.payload;
        }
    }
});

export const { SetAreaDropdown } = OldMapSlice.actions;

export default OldMapSlice.reducer;