import React, { useState } from 'react';
import { Input, Button ,} from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import './Forgotpassword.css'; // You can create your own CSS file for styling
import { CallPOSTAPI } from './helpers/API'; // You can import your API helper
import { useParams } from 'react-router-dom'; // React Router for extracting URL parameters
import { toast } from 'react-toastify';
import { Container, Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';

const UpdatePassword = ({token}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordMismatchError, setPasswordMismatchError] = useState('');
  const navigate = useNavigate();


//   const { token } = useParams(); // Extract token from URL using React Router
 console.log(token)
  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setErrorMessage('Please fill in all fields');
      return;
    }

    if (password !== confirmPassword) {
      setPasswordMismatchError('Password and confirm password do not match');
      return;
    }


    setLoading(true);

    try {
      const sendData = {
        password,
        token,
      };

      console.log(sendData)
      // Replace 'reset-password' with your actual API endpoint
      const result = await CallPOSTAPI(`updatepassword?token=${token}`, sendData);
      console.log(result)

      if (result.data.status === true) {
        toast.success(result.data.msg);
        window.location.href = 'http://localhost:3000/';
      } else {
        toast.error(result.data.msg);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('An error occurred while resetting password');
    }

    setLoading(false);
  };

  const headerStyle = {
    // color: "#fff",
    height: 64,
    paddingInline: 0,
    lineHeight: "64px",
// boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.1)", // Box shadow values

    // textAlign: "right",
};
  return (
    <div className='container-fluid'>
      <Row style={ headerStyle }>
    <Col sm={ 3 } style={ { textAlign: "left",paddingLeft:'14px' } }>
    <a href="/"><img width={ 160 } src={ 'logo.png' } alt="mapozone logo" /></a>
    </Col>
    {/* <Col sm={4} style={{textAlign:'right'}} >
        
    </Col> */}
   
  </Row>
    <div className="Forgotpassword-page">
      <div className="Forgotpassword-container">
        <h2 className='font-l'>Reset Password</h2>
        <form className="login-form"  onSubmit={handleResetPassword}>
          
        <Input className='input'
        prefix={<LockOutlined style={{ color: 'gray' }}/>}
        type='password'
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
         <Input className='input'
        prefix={<LockOutlined style={{ color: 'gray' }}/>}
        type='password'
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
         <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
    {passwordMismatchError && (
              <p style={{ color: 'red', marginTop: '5px' }}>
                {passwordMismatchError}
              </p>
            )}
  {/*style={{backgroundColor:"#ff69b4",marginTop:"10px",paddingTop:"20px",paddingBottom:"30px",color:"white",fontWeight:"bolder"}} */}
        <button style={{marginTop:"1rem"}} className='btn1 register-submit'   type="submit">
          {loading ? 'Loading...' : 'SUBMIT'}
        </button>
        </form>  
     
      <div className='forgot-m'>  <a className="forgot-link" href="#"> </a>
      </div>

 
        </div>
    </div></div>
  );
};

export default UpdatePassword;
