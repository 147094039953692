import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./style/custom.css";
import { useAuth0 } from "@auth0/auth0-react";

function MobileViewAlert() {
  const { user, loginWithRedirect, isAuthenticated, isLoading, logout } =
    useAuth0();

  const [show, setShow] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (windowSize.width <= 768) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [window.innerWidth]);

  return (
    <div className={`d-flex d-md-none`}>
      {/* <div className={`${isAuthenticated ? "d-none" : "d-flex d-md-none"}`}> */}
      <Modal show={show} centered>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <div className="d-flex justify-content-center align-items-center flex-column my-5">
            <h5 className="text-center mb-0">
              This app is intended for desktop use.
            </h5>
            {/* <button style={{
                     width: "150px", background: "#adf802",
                     border: "none",
                     borderRadius: "5px"
                  }} className='btn1 register-submit mx-auto' onClick={() => loginWithRedirect()}> Login / Signup</button> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MobileViewAlert;
