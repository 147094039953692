import React, { useEffect, useState } from "react";
import L from "leaflet";
import "./style/legend.css";
import { useMap } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "./components/InfoModal";

function Legend(props) {
  const map = useMap();
  const [openModal, setOpenModal] = useState(false);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const legend = L.control({ position: "bottomright" });

  //   useEffect(() => {
  //     L.DomUtil.remove("div", "info legend");
  //     map.removeControl(legend);
  //   }, [legend, map, props.activeIndex]);

  useEffect(() => {
    if (map) {
      //   const legend = L.control({ position: "bottomright" });

      //   legend.delete(map);
      //   map.removeControl(legend);
      //   map.legend.remove();

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        div.innerHTML += `<div style="text-align: right; margin-bottom: 5px;
						display:flex;
						justify-content: space-between;
						font-weight: 700;
					" 
					id="clieable-div"
					>
					<span className='legendheading'>Percentage Score</span> &nbsp; &nbsp;
					<svg xmlns="http://www.w3.org/2000/svg" height="1.25em" viewBox="0 0 512 512"><style>svg{fill:#d3d3d3}</style><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
					<div>`;
        // div.innerHTML += "<span className='legendheading'>Percentage Score</span><br>";
        div.innerHTML +=
          '<div class= "box" style="background-color: #fff;" ></div>&nbsp;&nbsp; 0 - 10%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #f7fcf5;" ></div>&nbsp;&nbsp; 10 - 20%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #e5f5e0;" ></div>&nbsp;&nbsp; 20 - 30%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #c7e9c0;" ></div>&nbsp;&nbsp; 30 - 40%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #a1d99b;" ></div>&nbsp;&nbsp; 40 - 50%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #74c476;" ></div>&nbsp;&nbsp; 50 - 60%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #41ab5d;" ></div>&nbsp;&nbsp; 60 - 70%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #238b45;" ></div>&nbsp;&nbsp; 70 - 80%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #006d2c;" ></div>&nbsp;&nbsp; 80 - 90%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #00441b;" ></div>&nbsp;&nbsp; 90 - 100%<br/>';
        div.innerHTML +=
          '<div class= "box" style="background-color: #d3d3d3;" ></div>&nbsp;&nbsp; No Data';
        return div;
      };

      legend.addTo(map);

      // clieable-div.
      const buttonElement = document.getElementById("clieable-div");

      const handleClick = () => {
        // This function will be executed when the element with the specified ID is clicked
        console.log("Button Clicked!");
        handleModal();
      };

      // Attach the click event listener to the button element
      buttonElement.addEventListener("click", handleClick);

      return () => {
        buttonElement.removeEventListener("click", handleClick);
      };
    }
  }, [map]);
  return (
    <>
      {/* openModal,setOpenModal */}
      <InfoModal setIsModalOpen={setOpenModal} isModalOpen={openModal} />
    </>
  );
}

export default Legend;
