export const ListType = [
    {tite: 'regions',slug: 'regions'},
    {tite: 'counties',slug: 'counties'},
    {tite: 'msoa',slug: 'msoa'},
]

export const placesLibrary = [ "places" ];
export const numberFormatter = Intl.NumberFormat("en-US");


export const optionsPopu = [
	{
		value: "population",
		label: "All",
	},
];

export const optionsGender = [
	{
		value: "genderm",
		label: "Male",
	},
	{
		value: "genderf",
		label: "Female",
	},
];

export const optionsAge = [
	{
		value: "Age0to4",
		label: "Age 0 - 4 years",
	},
	{
		value: "Age5to9",
		label: "Age 5 - 9 years",
	},
	{
		value: "Age10to14",
		label: "Age 10 - 14 years",
	},
	{
		value: "Age15to19",
		label: "Age 15 - 19 years",
	},
	{
		value: "Age20to24",
		label: "Age 20 - 24 years",
	},
	{
		value: "Age25to29",
		label: "Age 25 - 29 years",
	},
	{
		value: "Age30to34",
		label: "Age 30 - 34 years",
	},
	{
		value: "Age35to39",
		label: "Age 35 - 39 years",
	},
	{
		value: "Age40to44",
		label: "Age 40 - 44 years",
	},
	{
		value: "Age45to49",
		label: "Age 45 - 49 years",
	},
	{
		value: "Age50to54",
		label: "Age 50 - 54 years",
	},
	{
		value: "Age55to59",
		label: "Age 55 - 59 years",
	},
	{
		value: "Age60to64",
		label: "Age 60 - 64 years",
	},
	{
		value: "Age65to69",
		label: "Age 65 - 69 years",
	},
	{
		value: "Age70to74",
		label: "Age 70 - 74 years",
	},
	{
		value: "Age75to79",
		label: "Age 75 - 79 years",
	},
	{
		value: "Age80to84",
		label: "Age 80 - 84 years",
	},
	{
		value: "Age85Plus",
		label: "Age 85 years Plus",
	},
];

export const dataSetList = [
	{
		value: "population",
		label: "Population",
	},
	{
		value: "gender",
		label: "Gender",
	},
	{
		value: "age",
		label: "Age",
	},
    {
		value: "income",
		label: "Income",
	},
	{
		value: "people",
		label: "Households and Size",
	},
	{
		value: "religion",
		label: "Religion",
	},
] 

export const categoryList = {
	gender: optionsGender,
	population: optionsPopu,
	age: optionsAge
}

export const area_dropdownd = [
	// {
	// 	value: "regions",
	// 	is_selected: 0,
	// 	label: "Regions",
	// },
	// {
	// 	value: "counties",
	// 	is_selected: 0,
	// 	label: "Counties",
	// },
	{
		value: "msoa",
		is_selected: 0,
		// label: "MSOA (All Region)",
		label: "All Region",
	},
	{
		value: "counties_msoa",
		is_selected: 0,
		// label: "MSOA (All Counties)",
		label: "All Counties",
	},
	
];