import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEdit,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
// import { Button, Modal } from 'antd';
import { Button, Modal, Form } from "react-bootstrap";

import Board from "react-trello";
import { CallGETAPI, CallPOSTAPI } from "../../helpers/API";
import { PlusCircleOutlined } from "@ant-design/icons";
import AddProjectModal from "../../components/modals/AddProjectModal";
import ProjectList from "../../components/list/ProjectList";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { global_user_id } from "../../helpers/Constants";
import ListCard from "../../components/dragDropComp/ListCard";
import TrelloBoard from "../../components/dragDropComp/TrelloBoard";
import ListAction from "../../components/dragDropComp/ListAction";
import Test2DragDrop from "../../components/dragDropComp/Test2DragDrop";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Header from "../../components/Header";
import DataModal from "../../components/DataModal";
import MobileViewAlert from "../../MobileViewAlert";
import LoginNew from "../../LoginNew";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { handleAddSelectedProjects } from "../../redux/slices/LocationProjectSlice";
import SharedProjectList from "../../components/list/SharedProjectList";

// import axios from "axios";

// const Profile = () => {
//   const handleListClick = () => {};

const Profile = () => {
  const dispatch = useDispatch();
  const [openInfoModal, setInfoModal] = useState(false);
  const [searched, setSearched] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleListClick = () => {};

  const Data = {
    lanes: [
      {
        id: "PLANNED",
        title: "Planned Tasks",
        label: "20/70",
        style: {
          width: 280,
        },
        cards: [
          {
            id: "Milk",
            title: (
              <ListCard
                title={"Buy milk"}
                id={"milk"}
                project_id={"12"}
                list_id={"1002"}
                handleClick={handleListClick}
              />
            ),
            label: <ListAction />,
            description: "",
          },
          {
            id: "Plan2",
            title: "Dispose Garbage",
            label: "10 mins",
            description: "Sort out recyclable and waste as needed",
          },
          {
            id: "Plan3",
            title: "Write Blog",
            label: "30 mins",
            description: "Can AI make memes?",
          },
          {
            id: "Plan4",
            title: "Pay Rent",
            label: "5 mins",
            description: "Transfer to bank account",
          },
        ],
      },
      {
        id: "WIP",
        title: "Work In Progress",
        label: "10/20",
        style: {
          width: 280,
        },
        cards: [
          {
            id: "Wip1",
            title: "Clean House",
            label: "30 mins",
            description:
              "Soap wash and polish floor. Polish windows and doors. Scrap all broken glasses",
          },
        ],
      },
      {
        id: "BLOCKED",
        title: "Blocked",
        label: "0/0",
        style: {
          width: 280,
        },
        cards: [],
      },
    ],
  };

  const [isPopupOpen, setIsPopupOpen] = useState(0);
  const [ProjectListData, setProjectListData] = useState([]);
  const [sharedProjectListData, setSharedProjectListData] = useState([]);
  const [activePrject, setActiveProject] = useState("");
  const SelectedProjects = useSelector(
    (state) => state?.LocationProject?.active_project
  );

  // const { user } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();
  // const userId = user?.sub?.slice(6); //|| global_user_id;
  const userId = user?.sub?.split("|")[1];

  console.log("user details", user);
  console.log("userID", userId);

  // Step 2: Function to toggle the popup state
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleCancel = () => setIsPopupOpen(0);
  const addProject = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const sendData = {
      project_name: data.get("ProjectName"),
    };

    // const result = await CallPOSTAPI("updateLocation/", sendData);

    // const sendData = {
    //   project_name: data.get("ProjectName"),
    // };
    const result = await CallPOSTAPI("", sendData);

    e.target.reset();
    // togglePopup();
  };

  const allProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  const onLoad = async () => {
    setProjectListData([]);
    setSharedProjectListData([]);
    setActiveProject("");
    if (!userId) {
      return "";
    }
    const resilt = await CallGETAPI("getProjects/" + userId);
    // const resilt = await CallGETAPI("getProjects/" + userId);

    if (resilt?.data?.status) {
      setProjectListData(resilt?.data?.projects || []);
      setSharedProjectListData(resilt?.data?.shared_projects || []);
      resilt?.data?.projects.map((item, i) => {
        if (item.project_checked1) {
          if (!allProjects.find((obj) => obj.id === item.id)) {
            dispatch(handleAddSelectedProjects([item]));
          }
        }
      });
      resilt?.data?.shared_projects.map((item, i) => {
        if (item.project_checked1) {
          if (!allProjects.find((obj) => obj.id === item.id)) {
            dispatch(handleAddSelectedProjects([item]));
          }
        }
      });
    }
  };
  useEffect(() => {
    if (userId) {
      onLoad();
    }
  }, [userId]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setLoginModal(true);
    } else {
      setLoginModal(false);
    }
  }, [isAuthenticated, isLoading]);

  const reduxLoading = useSelector(
    (state) => state?.LocationProject?.reduxLoading
  );

  // const getUsers = () => {
  //   const mapToken = localStorage.getItem("MapozonToken");
  //   const lt = mapToken?.split("|")[1];
  //   const options = {
  //     method: "GET",
  //     url: "https://dev-sqip4n0s6x6e1ozr.us.auth0.com/api/v2/users",
  //     // params: {q: 'email:"jane@exampleco.com"', search_engine: 'v3'},
  //     headers: {
  //       authorization: `Bearer g42oEu6X-yfK3bEwGTaN6tY972dENRT3IX6ePjWfyh6ac`,
  //     },
  //   };

  //   axios
  //     .request(options)
  //     .then(function (response) {
  //       console.log("response data", response.data);
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // };

  return (
    <>
      <LoginNew show={loginModal} setShow={setLoginModal} />

      <MobileViewAlert />

      <Spin
        tip="Mapozone Loading..."
        spinning={isLoading || loading || reduxLoading}
        size="large"
      >
        <Header
          setSearched={setSearched}
          openInfoModal={openInfoModal}
          setInfoModal={setInfoModal}
        />
        <div className="" id="profile-page">
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <div className="LeftSideContainer">
                  <div className="ReturnMapContainer">
                    <button
                      className="ReturnButton"
                      type="button"
                      onClick={() => navigate("/")}
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="ArrowIcon"
                      />
                      <h2 className="ReturnToMapText">RETURN TO MAP</h2>
                    </button>
                  </div>

                  <div className="MyProjectContainer d-flex flex-column justify-content-between">
                    <div
                      className="h-50"
                      style={{ overflowY: "auto", overflowX: "hidden" }}
                    >
                      <div className="MyProject">
                        <h5 className="MyProjectText"> My Projects </h5>
                        <AiOutlinePlusCircle
                          className="add-icon cursor-pointer"
                          onClick={() => setIsPopupOpen(1)}
                        />
                      </div>
                      <hr className="mx-auto" style={{ width: "98%" }} />
                      {/* {JSON.stringify(ProjectListData)} */}

                      {ProjectListData &&
                        ProjectListData?.map((item, i) => {
                          // handleReduxValues(item);
                          return (
                            <ProjectList
                              key={`proj${i}`}
                              title={item?.project_name}
                              id={item?.id}
                              user_id={item?.user_id}
                              setActiveProject={setActiveProject}
                              activePrject={activePrject}
                              item={item}
                              is_selected={SelectedProjects?.id === item?.id}
                              refreashList={onLoad}
                            />
                          );
                        })}
                    </div>

                    <div
                      className="h-50"
                      style={{ overflowY: "auto", overflowX: "hidden" }}
                    >
                      <div className="MyProject m-0">
                        <h5 className="MyProjectText m-0">Shared Projects</h5>
                      </div>
                      <hr className="mx-auto my-1" style={{ width: "98%" }} />
                      {sharedProjectListData &&
                        sharedProjectListData?.map((item, i) => {
                          // handleReduxValues(item);
                          return (
                            <SharedProjectList
                              key={`proj${i}`}
                              title={item?.project_name}
                              // id={item?.id}
                              // user_id={item?.user_id}
                              // setActiveProject={setActiveProject}
                              // activePrject={activePrject}
                              item={item}
                              is_selected={SelectedProjects?.id === item?.id}
                              // refreashList={onLoad}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-10 list-section">
                {SelectedProjects.project_name && (
                  <>
                    <div className="mt-2">
                      <h2 className="PersonalBoard1Text1 text-capitalize">
                        {SelectedProjects?.project_name}
                      </h2>
                    </div>
                    <hr style={{ width: "100%" }} className="mx-auto my-2" />
                  </>
                )}

                <Test2DragDrop
                  setUpdateLoading={setLoading}
                  updateLoading={loading}
                />
              </div>
            </div>

            <AddProjectModal
              addProject={addProject}
              isPopupOpen={isPopupOpen}
              handleCancel={handleCancel}
              fetchProjects={onLoad}
            />
          </div>
        </div>
        <DataModal isModalOpen={openInfoModal} setIsModalOpen={setInfoModal} />
      </Spin>
    </>
  );
};

export default Profile;
