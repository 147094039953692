import { toast } from "react-toastify";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CallPOSTAPI } from "../../helpers/API";
import { useAuth0 } from "@auth0/auth0-react";
import ColorPalette from "../../ColorPallete";
import { useSelector } from "react-redux";

function AddListModal({ isPopupOpen, handleCancel, fetchList }) {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState("");
  const [ColorData, setColorData] = useState("");
  const { user } = useAuth0();
  const userId = user?.sub?.slice(6);

  const SelectedProjects = useSelector(
    (state) => state?.LocationProject?.active_project
  );

  const addProject = async (e) => {
    e.preventDefault();

    if (!SelectedProjects?.id) {
      toast.error("Invalid Project ID");
      return "";
    }

    if (!project) {
      toast.error("List name is required");
      return "";
    }

    if (!ColorData) {
      toast.error("Please Select Any Color");
      return "";
    }

    setLoading(true);
    const sendData = {
      user_id: userId,
      project_id: SelectedProjects?.id,
      list_name: project.trim(),
      color: ColorData,
    };
    const result = await CallPOSTAPI("addList", sendData);
    if (result.data?.status) {
      toast.success(result?.data?.msg);
      setProject("");
      setColorData("");
      fetchList();
      handleCancel();
    } else {
      toast.error(result?.data?.msg);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal show={isPopupOpen} onHide={handleCancel} centered>
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b className="text-uppercase">Add New List</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          {/* Content that may overflow */}
          <div style={{ display: "block" }}>
            <div className="">
              <form onSubmit={addProject} id="add_list_form">
                <div className="form-group d-grid gap-2">
                  <label htmlFor="location_name">
                    {" "}
                    <b className="d-flex">New List Name</b>{" "}
                  </label>
                  <input
                    type="text"
                    name="ProjectName"
                    placeholder="Enter List Name..."
                    className="form-control modal-custom-input italic-input"
                    onChange={(e) => setProject(e.target.value.trimStart())}
                    value={project}
                    style={{ borderRadius: "20px" }}
                  />
                </div>
                <div className="mt-4 px-1">
                  {/* <p className="m-0" style={{ fontWeight: "bold" }}>
                    List Color
                  </p> */}
                  <div className="">
                    <ColorPalette setInput={setColorData} value={ColorData} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-custom-footer">
          <button
            className="search-btn py-2"
            type="button"
            form="add_list_form"
            // id="add-project-btn"
            onClick={addProject}
          >
            {loading ? "Loading..." : "ADD NEW LIST"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddListModal;
