import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BASE_API } from "../../helpers/API";
import { TokenKey } from "../../helpers/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { Link } from "react-router-dom";

const SubscriptionModal = ({ showModal, setShowModal }) => {
  const { user, logout } = useAuth0();
  const { subscription_data } = useSelector((state) => state?.subscription);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(39);
  const onModalClose = () => {
    setShowModal(false);
  };

  const handlePayment = async (plan) => {
    try {
      setLoading(true);
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const response = await axios.post(
        BASE_API + "create-subscription-checkout-session",
        {
          plan: plan,
          user_id: userId,
        },
        { headers: headers }
      );

      if (response.data.status) {
        window.location = response.data.session.url;
        // setLoading(false);
      } else {
        setLoading(false);
        console.log("error", response.data.error);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const getSubscriptionStatus = (data) => {
    const currentDate = new Date();
    const subscriptionEndDate = new Date(data?.current_period_end * 1000);

    const comparison = subscriptionEndDate.getTime() - currentDate.getTime();

    if (
      comparison > 0 &&
      (data?.status === "active" || data?.status === "canceled")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("MapozonToken");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      <Modal
        size="lg"
        className="subscription-modal"
        show={showModal}
        onHide={onModalClose}
        centered
        backdrop="static"
      >
        {/* <Modal.Header
          className="modal-custom-header"
          style={{ justifyContent: "center" }}
          closeButton={
            subscription_data && !getSubscriptionStatus(subscription_data)
          }
        >
          <Modal.Title style={{ fontSize: "18px", fontWeight: "800" }}>
            <b className="text-uppercase">Mapozone Subscription</b>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <Spin tip="Mapozone Loading..." size="large"></Spin>
            </div>
          ) : (
            <>
              <div>
                <h4 className="text-center">Mapozone Subscription</h4>
                <p className="gray-text text-center">
                  Includes full access to application
                </p>
              </div>
              <section className="pricing">
                <div className="container">
                  <div className="row gy-3">
                    <div
                      className="col-lg-6"
                      role="button"
                      onClick={() => setSelectedCard(39)}
                    >
                      <div
                        className={`card ${
                          !subscription_data?.id && selectedCard === 39
                            ? "selected-card"
                            : ""
                        } ${
                          subscription_data?.plan?.amount === 3900
                            ? "selected-card"
                            : ""
                        }`}
                      >
                        <div className="card-body">
                          <h6 className="text-center">Monthly</h6>
                          <h6 className="card-price text-center mb-2">£39</h6>
                          <h6 className="gray-text text-center">Per Month</h6>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-6"
                      role="button"
                      onClick={() => setSelectedCard(350)}
                    >
                      <div
                        className={`card ${
                          !subscription_data?.id && selectedCard === 350
                            ? "selected-card"
                            : ""
                        } ${
                          subscription_data?.plan?.amount === 35000
                            ? "selected-card"
                            : ""
                        }`}
                      >
                        <div className="card-body">
                          <h6 className="text-center">Yearly</h6>
                          <h6 className="card-price text-center mb-2">£350</h6>
                          <h6 className="gray-text text-center">Per Year</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mt-3">
                    <button
                      className="login-btn"
                      onClick={() => handlePayment(selectedCard)}
                      disabled={
                        subscription_data &&
                        getSubscriptionStatus(subscription_data)
                      }
                    >
                      {subscription_data &&
                      getSubscriptionStatus(subscription_data)
                        ? "Subscribed"
                        : "Subscribe Now"}
                    </button>
                  </div>

                  <div className="w-100 mt-3">
                    <p className="gray-text text-center">
                      for multiple users{" "}
                      <a
                        href="https://www.mapozone.com/contact"
                        className=""
                        style={{
                          textDecoration: "underline",
                          color: "#71d622",
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact Us
                      </a>
                    </p>
                  </div>

                  <div className="text-center">
                    <Link
                      href="#"
                      onClick={() => logoutUser()}
                      className="m-0 mb-3 gray-text learn-more"
                      style={{ textDecoration: "none" }}
                    >
                      Log Out
                    </Link>
                  </div>
                </div>
              </section>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
