// LocationProject
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedProject: [],
  active_project: {},
  selected_locations: [],
  location_list: [],
  distance_location: [],
  poi_locations: [],
  reduxLoading: false,
};

export const LocationProjectSlice = createSlice({
  name: "LocationProject",
  initialState,
  reducers: {
    handleActiveProject: (state, action) => {
      state.active_project = action.payload; //[...action.payload,...state.active_project];
    },

    handleRemoveactiveProjects: (state, action) => {
      // state.active_project = state.active_project.filter(
      //   (project) => project?.id !== action.payload
      // );
      state.active_project = {};
    },
    handleAddSelectedLocations: (state, action) => {
      state.selected_locations = [
        ...state.selected_locations,
        ...action.payload,
      ];
    },
    handleRemoveSelectedLocations: (state, action) => {
      state.selected_locations = state.selected_locations.filter(
        (location) => location?.location_id !== action.payload
      );
    },
    addDistanceLocation: (state, action) => {
      state.distance_location = [...state.distance_location, ...action.payload];
    },
    removeDistanceLocation: (state, action) => {
      state.distance_location = state.distance_location.filter(
        (location) => location?.location_id !== action.payload
      );
    },
    setLocationList: (state, action) => {
      state.location_list = action.payload;
    },
    handleAddSelectedProjects: (state, action) => {
      state.selectedProject = [...state.selectedProject, ...action.payload];
    },
    handleRemoveSelectedProjects: (state, action) => {
      state.selectedProject = state.selectedProject.filter(
        (location) => location?.id !== action.payload
      );
    },
    updateArrSelectLocations: (state, action) => {
      state.selected_locations = action.payload;
    },
    updateArrDistenceLocations: (state, action) => {
      state.distance_location = action.payload;
    },

    handleRemoveLocationsByListId: (state, action) => {
      state.selected_locations = state.selected_locations.filter(
        (location) => location?.list_id !== action.payload
      );
      state.distance_location = state.distance_location.filter(
        (location) => location?.list_id !== action.payload
      );
    },

    handleRemoveLocationsByProjectId: (state, action) => {
      state.selected_locations = state.selected_locations.filter(
        (location) => location?.project_id !== action.payload
      );
      state.distance_location = state.distance_location.filter(
        (location) => location?.project_id !== action.payload
      );
    },

    handleRemoveAllLocations: (state) => {
      state.selected_locations = [];
      state.distance_location = [];
    },

    handleLoadingTrue: (state) => {
      state.reduxLoading = true;
    },

    handleLoadingFalse: (state) => {
      state.reduxLoading = false;
    },

    handleAddPoiLocations: (state, action) => {
      state.poi_locations = [...state.poi_locations, ...action.payload];
    },
    handleRemovePoiLocations: (state, action) => {
      state.poi_locations = state.poi_locations.filter(
        (location) => location?.osm_id !== action.payload
      );
    },
    handleRemoveAllPoiLocations: (state) => {
      state.poi_locations = [];
    },
    handleLocationsProjectName: (state, action) => {
      const updatedProjectName = action.payload.projectName;

      state.selected_locations = state.selected_locations.map((item) => {
        if (item.projectId === action.payload.projectId) {
          return { ...item, projectName: updatedProjectName };
        }
        return item;
      });

      state.distance_location = state.distance_location.map((item) => {
        if (item.projectId === action.payload.projectId) {
          return { ...item, projectName: updatedProjectName };
        }
        return item;
      });
    },
  },
});

export const {
  handleActiveProject,
  handleAddSelectedLocations,
  handleRemoveSelectedLocations,
  setLocationList,
  handleRemoveactiveProjects,
  removeDistanceLocation,
  addDistanceLocation,
  handleAddSelectedProjects,
  handleRemoveSelectedProjects,
  handleRemoveLocationsByListId,
  updateArrSelectLocations,
  updateArrDistenceLocations,
  handleRemoveLocationsByProjectId,
  handleRemoveAllLocations,
  handleLoadingTrue,
  handleLoadingFalse,
  handleAddPoiLocations,
  handleRemovePoiLocations,
  handleRemoveAllPoiLocations,
  handleLocationsProjectName,
} = LocationProjectSlice.actions;
export default LocationProjectSlice.reducer;
