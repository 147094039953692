// export const GoogleAPIKey = "AIzaSyA2oiKD-1rp4yMg06ryxlLi13le1kU0K8E";
export const GoogleAPIKey = "AIzaSyCEHN-ewWO14d3x8iXVwcawmfO2bhQMSJs";
// AIzaSyCEHN-ewWO14d3x8iXVwcawmfO2bhQMSJs

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 12.75l6 6 9-13.5"
    />
  </svg>
);

export const TokenKey = "mapozon_token";

export const global_user_id = "64e75f560b38b07996802fcc";
