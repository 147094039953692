import React, { useEffect, useState } from "react";
import {
  Circle,
  Marker,
  Pane,
  Polygon,
  Popup,
  Tooltip,
  useMap,
} from "react-leaflet";
import { useSelector } from "react-redux";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

const MyMarker = ({ data, index }) => {
  const map = useMap();

  const [markerSize, setMarkerSize] = useState(map.getZoom());

  const getPosition = (props) => {
    let lon = "";
    let lat = "";

    lon = props?.long;
    lat = props?.lat;
    return [lat, lon];
  };

  const updateMarkerSize = () => {
    setMarkerSize(map.getZoom());
  };

  const color = data?.color ? data?.color : data?.properties?.color || "#000";

  const myCustomColour = color;

  const markerHtmlStyles = `
  background-color: transparent;
  width: ${markerSize}px;
  height: ${markerSize}px;
  display: block;
 
  position: relative;
  border-radius: 100%;
  transform: rotate(45deg);
  border: 5px solid ${myCustomColour};
 `;

  //  left: -4px;
  //  top: -4px;
  //  width: 20px;
  //   height: 20px;

  const icon = new L.divIcon({
    className: "my-custom-pin",
    html: `<span style="${markerHtmlStyles}" />`,
  });

  useEffect(() => {
    // Update marker size when the zoom level changes
    map.on("zoomend", updateMarkerSize);

    // Cleanup the event listener when the component unmounts
    return () => {
      map.off("zoomend", updateMarkerSize);
    };
  }, [map]);

  if (data.catchment_area === null) {
    return (
      <Marker
        key={data?.location_id}
        zIndexOffset={4000000}
        position={getPosition(data)}
        icon={icon}
        // pane={`locationPane${index}`}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup(),
        }}
      >
        <Popup className="custom-popup" keepInView closeButton={false}>
          <div className="d-flex flex-column">
            <span className="d-flex align-items-center">
              Location Name&nbsp;:&nbsp;
              <b
                className="text-capitalize d-inline-block text-truncate"
                style={{ maxWidth: "200px" }}
              >
                {data?.location_name}
              </b>
            </span>
            <span className="d-flex align-items-center">
              Project Name&nbsp;:&nbsp;
              <b
                className="text-capitalize d-inline-block text-truncate"
                style={{ maxWidth: "200px" }}
              >
                {data?.projectName}
              </b>
            </span>
          </div>
        </Popup>
      </Marker>
    );
  } else if (data.catchment_area === "Distance") {
    return (
      <Pane
        name={`circlePane${data.location_id}`}
        // style={{ zIndex: parseInt(`40${i + 1}`) }}
        style={{ zIndex: 400 }}
        key={data?.location_id}
      >
        <Circle
          // key={index}
          center={getPosition(data)}
          pathOptions={{ color: data?.color ? data.color : "#ffffff" }}
          radius={data?.distance_radius * 1000}
          pane={`circlePane${data.location_id}`}
        >
          <Marker
            zIndexOffset={4000000}
            position={getPosition(data)}
            icon={icon}
            eventHandlers={{
              mouseover: (event) => event.target.openPopup(),
              mouseout: (event) => event.target.closePopup(),
            }}
          >
            <Popup className="custom-popup" keepInView closeButton={false}>
              <div className="d-flex flex-column">
                <span className="d-flex align-items-center">
                  Location Name&nbsp;:&nbsp;
                  <b
                    className="text-capitalize d-inline-block text-truncate"
                    style={{ maxWidth: "200px" }}
                  >
                    {data?.location_name}
                  </b>
                </span>
                <span className="d-flex align-items-center">
                  Project Name&nbsp;:&nbsp;
                  <b
                    className="text-capitalize d-inline-block text-truncate"
                    style={{ maxWidth: "200px" }}
                  >
                    {data?.projectName}
                  </b>
                </span>

                <span>
                  Catchment Type&nbsp;:&nbsp;
                  <b className="text-capitalize">Distance</b>
                </span>
                <span>
                  Distance Radius&nbsp;:&nbsp;
                  <b className="text-capitalize">
                    {data?.distance_radius}&nbsp;km
                  </b>
                </span>
              </div>
            </Popup>
          </Marker>
        </Circle>
      </Pane>
    );
  }

  let coorArray = [];
  let mark = [data?.lat, data?.long];
  data?.geometry?.coordinates[0].map((item) =>
    coorArray.push([item[1], item[0]])
  );
  return (
    <Pane
      name={`polygonPane${data?.location_id}`}
      // style={{ zIndex: parseInt(`40${i + 1}`) }}
      style={{ zIndex: 400 }}
      key={data?.location_id}
    >
      <Polygon
        // key={index}
        color={data?.properties?.color}
        fillColor={data?.properties?.color}
        fillOpacity={data?.properties?.fillOpacity}
        positions={coorArray}
        pane={`polygonPane${data?.location_id}`}
      >
        <Marker
          zIndexOffset={4000000}
          position={mark}
          icon={icon}
          eventHandlers={{
            mouseover: (event) => event.target.openPopup(),
            mouseout: (event) => event.target.closePopup(),
          }}
        >
          <Popup className="custom-popup" keepInView closeButton={false}>
            <div className="d-flex flex-column">
              <span className="d-flex align-items-center">
                Location Name&nbsp;:&nbsp;
                <b
                  className="text-capitalize d-inline-block text-truncate"
                  style={{ maxWidth: "200px" }}
                >
                  {data?.locationName}
                </b>
              </span>
              <span className="d-flex align-items-center">
                Project Name&nbsp;:&nbsp;
                <b
                  className="text-capitalize d-inline-block text-truncate"
                  style={{ maxWidth: "200px" }}
                >
                  {data?.projectName}
                </b>
              </span>
              <span>
                Catchment Type&nbsp;:&nbsp;
                <b className="text-capitalize">Travel Time</b>
              </span>
              <span>
                Travel Mode&nbsp;:&nbsp;
                <b className="text-capitalize">{data?.travelWay}</b>
              </span>
              <span>
                Travel Time&nbsp;:&nbsp;
                <b className="text-capitalize">
                  {data?.properties?.contour}&nbsp;mins
                </b>
              </span>
            </div>
          </Popup>
        </Marker>
      </Polygon>
    </Pane>
  );
};

const GroupedLocations = () => {
  const radiusLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );

  const polygonLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const combinedLocations = [...radiusLocation, ...polygonLocation];

  const propertiesToGroupBy = ["lat", "long"];

  const groupedData = combinedLocations.reduce((result, obj) => {
    const key = propertiesToGroupBy.map((prop) => obj[prop]).join("|");
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(obj);
    return result;
  }, {});

  const mappedData = Object.keys(groupedData).map((key) => {
    const group = groupedData[key];
    return {
      groupKey: key,
      group: group,
    };
  });

  const currentProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  const createClusterCustomIcon = function (cluster) {
    const markerHtmlStyles = `
  background-color: transparent;
  width: 30px;
  height: 30px;
  display: block;
  left: -4px;
  top: -4px;
  position: relative;
  border-radius: 100%;
  border: 5px solid #000000;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff2039;
 `;

    const textStyles = `
font-size: 30px
`;

    return L.divIcon({
      className: "my-custom-pin",
      html: `<span style="${markerHtmlStyles}">${
        Math.floor(cluster.getChildCount() / 2) + 1
      }</span>`,
    });

    // <span style="${textStyles}">${
    //     Math.floor(cluster.getChildCount() / 2) + 1
    //   }</span>

    // return L.divIcon({
    //   html: `<span>${cluster.getChildCount()}</span>`,
    //   className: 'custom-marker-cluster',
    //   iconSize: L.point(33, 33, true),
    // })
  };

  return (
    <>
      {/* <MarkerClusterGroup
        maxClusterRadius={1}
        spiderfyOnMaxZoom={true}
        showCoverageOnHover={true}
        chunkedLoading={true}
        // iconCreateFunction={createClusterCustomIcon}
      > */}
      {mappedData
        ?.filter((data) => data.group.length !== 1)
        .map((data, i) =>
          data.group
            .filter((item) =>
              currentProjects.some((obj) => obj.id === item.projectId)
            )
            .map((item, i) => {
              return <MyMarker data={item} index={i} />;
            })
        )}
      {/* </MarkerClusterGroup> */}
      {mappedData
        ?.filter((data) => data.group.length === 1)
        .map((data, i) =>
          data.group
            .filter((item) =>
              currentProjects.some((obj) => obj.id === item.projectId)
            )
            .map((item, i) => {
              // index={`groupedLoc${i + 1}`}
              return <MyMarker data={item} index={i} />;
            })
        )}
    </>
  );
};

export default GroupedLocations;
