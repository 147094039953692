import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-switch-basemap/src/L.switchBasemap";
import { useEffect } from "react";

export default function LayerSwitcher()
{
	const map = useMap();
	useEffect(() =>
	{
		new L.basemapsSwitcher(
			[
				{
					layer: L.tileLayer(
						"http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
						{
							attribution:
								'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
						}
					).addTo(map), //DEFAULT MAP
					icon: "maps/img1.png",
					name: "OSM",
				},
			],
			{ position: "bottomleft" }
		).addTo(map);
	}, [ map ]);
}
