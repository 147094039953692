import { DeleteColumnOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import PopupModal2 from "./PopupModal2";
import { Button, Modal, Form } from "react-bootstrap";
import { DeleteIcon, EditIcon } from "./Icons";
import "./style/custom.css";
import { Autocomplete } from "@react-google-maps/api";
import { PlusCircleOutlined } from "@ant-design/icons";
// import jwtDecode from 'jwt-decode';
import { BASE_API, CallPOSTAPI } from "./helpers/API";
import { TokenKey } from "./helpers/Constants";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import PopupModalEdit from "./PopupModalEdit";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import axios from "axios";

function PopupModal(props) {
  const {
    selectedDistanceLocation,
    setSelectedDistanceLocation,
    selectedLocation,
    setSelectedLocation,
    selected,
    setSelected,
    show,
    setSearched,
    setPolygon,
    setMapLocation,
    setShow,
    handleClose,
  } = props;
  console.log(props);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [userData, setUserData] = useState(null);
  const [openInfoModal2, setInfoModal2] = useState(false);
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const userId = user?.sub?.slice(6);
  const [locationresult, setLocationResult] = useState([]);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    handleClose();
    setShow2(true);
  };
  const navigate = useNavigate();
  const [editLocation, setEditLocation] = useState({});
  const handleClose3 = () => {
    navigate("/");
    setShow3(false);
  };
  const handleShow3 = (e) => {
    navigate(`?${e?.id}`);
    setEditLocation(e);
    console.log(e);
    handleClose();
    setShow3(true);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Add your login logic here
    console.log("Logging in...");
    handleClose();
  };
  const onload = async () => {
    const res = await CallPOSTAPI("fetchUserLocations");
    console.log({ res });
  };
  const [loading, setLoading] = useState(false);
  const fetchOnLoad = () => {
    const token = localStorage.getItem(TokenKey);
    fetch(BASE_API + `/fetchUserLocations/${user?.sub?.slice(6)}`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response?.json();
      })
      .then((data) => {
        const resultData = data?.userLocations || [];
        setLocationResult(resultData);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        // Handle the error as needed
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchOnLoad();
    setLoading(false);
  }, [show]); // Empty dependency array to run the effect only once

  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteClose = () => {
    setDeleteModal(false);
    setShow(true);
    fetchOnLoad();
  };

  const deleteFn = async (id) => {
    setDeleteId(id);
    handleClose();
    setDeleteModal(true);
  };
  // const [selectedLocations, setSelectedLocations] = useState([])
  const handleCheckboxChange = async (e, item) => {
    console.log("item checked", item);
    console.log("selected distance location", selectedDistanceLocation);
    if (item.catchment_area === "Distance") {
      if (selected.includes(item.id)) {
        setSelected([...selected].filter((data) => data !== item.id));
        setSelectedLocation(
          [...selectedLocation].filter((data) => data.id !== item.id)
        );
      } else {
        setSelected([...selected, item.id]);
        setSelectedLocation([...selectedLocation, item]);
      }
    } else {
      if (selectedDistanceLocation.find((o) => o.id === item.id)) {
        setSelectedDistanceLocation(
          [...selectedDistanceLocation].filter((data) => data.id !== item.id)
        );
      } else {
        setSelectedDistanceLocation([...selectedDistanceLocation, item]);
        const existdata = localStorage.getItem(item.id);

        if (!existdata) {
          try {
            const response = await axios.get(
              `https://api.mapbox.com/isochrone/v1/mapbox/${item?.travel_way}/${
                item.long
              },${item.lat}?contours_minutes=${
                item?.travel_time
              }&contours_colors=${
                item?.color?.slice(1) || ""
              }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
            );
            console.log(response.data.features[0]);
            let memoObject = {
              lat: item.lat,
              long: item.long,
              locationName: item.location_name,
              ...response.data.features[0],
            };
            localStorage.setItem(item.id, JSON.stringify(memoObject));
          } catch (error) {
            toast.error(error.msg);
          }
        }
      }
    }
  };
  console.log(props.selectedLocation);
  console.log(selected);

  return (
    <>
      <PopupModalEdit
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        selected={selected}
        setSelected={setSelected}
        setPolygon={setPolygon}
        thisLocations={locationresult}
        setMapLocation={setMapLocation}
        setSearched={setSearched}
        handleShow={handleShow3}
        show2={show3}
        setShow2={setShow3}
        handleClose2={handleClose3}
      />

      <DeleteModal
        setShow={setShow}
        fetchOnLoad={fetchOnLoad}
        id={deleteId}
        handleClose={deleteClose}
        show={deleteModal}
      />

      <PopupModal2
        setPolygon={setPolygon}
        setMapLocation={setMapLocation}
        setSearched={setSearched}
        handleShow={handleShow2}
        show2={show2}
        setShow2={setShow2}
        handleClose2={handleClose2}
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>My Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          {/* Content that may overflow */}
          <div style={{ display: "block" }}>
            {loading
              ? "Loading..."
              : locationresult.length === 0
              ? "No Data Found"
              : ""}
            {locationresult?.map((item) => (
              <>
                <ul style={{ listStyle: "none", paddingLeft: "0.5rem" }}>
                  <li className="d-flex justify-content-between align-center">
                    <span>
                      <input
                        checked={
                          selected.includes(item.id) ||
                          // selectedDistanceLocation.includes(item.id)
                          selectedDistanceLocation.find((o) => o.id === item.id)
                        }
                        name="checkbox"
                        value={item.id}
                        onChange={(e) => handleCheckboxChange(e, item)}
                        type="checkbox"
                      />{" "}
                      {item?.location_name}
                    </span>
                    <span style={{ justifyContent: "end", cursour: "pointer" }}>
                      <button
                        className="p-1 btn"
                        style={{ padding: "2px" }}
                        onClick={() => {
                          handleShow3(item);
                        }}
                      >
                        <EditIcon />
                      </button>
                      <button
                        className="p-1 btn"
                        style={{ padding: "2px", color: "red" }}
                        onClick={() => deleteFn(item?.id)}
                      >
                        <DeleteIcon />{" "}
                      </button>
                    </span>
                  </li>
                </ul>
              </>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="location-btn2" onClick={handleShow2}>
            <span className="icon">
              {" "}
              <PlusCircleOutlined />
            </span>{" "}
            ADD LOCATION{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopupModal;

{
  /*

{Array.from({ length: 2 }, (_, index) => (
  <li key={index} className="d-flex align-items-center w-full justify-content-between">
  <div className=''>
    <input type="checkbox"/> &nbsp;
    <span>Location Name   {index + 1} </span>      
  </div>

  <span className='d-flex justify-content-end text-align-end'> 
    <span className='p-1'><EditIcon/></span> 
    <span className='p-1'><DeleteIcon/>  </span> 
  </span> 
   </li>
))}


    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>

*/
}
