import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CallGETAPI, CallPOSTAPI } from "../../helpers/API";
import { useAuth0 } from "@auth0/auth0-react";
import ColorPalette from "../../ColorPallete";
import { useSelector } from "react-redux";

import { CheckOutlined } from "@ant-design/icons";
import {
  addDistanceLocation,
  handleAddSelectedLocations,
  handleRemoveAllLocations,
  handleRemoveLocationsByListId,
  handleRemoveSelectedLocations,
  removeDistanceLocation,
} from "../../redux/slices/LocationProjectSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
// const ColorArray = [
//   { bg: "#8c1a0f", is_selected: false },
//   { bg: "#eb3223", is_selected: false },
//   { bg: "#f19d39", is_selected: false },
//   { bg: "#fefd54", is_selected: false },
//   { bg: "#74f94d", is_selected: false },
//   { bg: "#5688e0", is_selected: false },
//   { bg: "#0d28f5", is_selected: false },
//   { bg: "#8b31f5", is_selected: false },
//   { bg: "#ea40f7", is_selected: false },
// ];

const ColorArray = [
  { bg: "#71d622", is_selected: false },
  { bg: "#22cbd6", is_selected: false },
  { bg: "#eda334", is_selected: false },
  { bg: "#ff6b62", is_selected: false },
  { bg: "#ff6fce", is_selected: false },
  { bg: "#b57bff", is_selected: false },
  { bg: "#ffdb5b", is_selected: false },
  { bg: "#6e7cf6", is_selected: false },
  { bg: "#afafaf", is_selected: false },
];

function UpdateListModal({
  isPopupOpen,
  handleCancel,
  fetchList,
  currentList,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState("");
  const [ColorData, setColorData] = useState("");
  const { user } = useAuth0();
  const userId = user?.sub?.slice(6);

  const SelectedProjects = useSelector(
    (state) => state?.LocationProject?.active_project
  );

  const addProject = async (e) => {
    e.preventDefault();

    if (!SelectedProjects?.id) {
      toast.error("Invalid Project ID");
      return "";
    }

    if (!project) {
      toast.error("Project name is required");
      return "";
    }

    if (!ColorData) {
      toast.error("Please Select Any Color");
      return "";
    }

    setLoading(true);
    const sendData = {
      id: currentList?.list_id,
      project_id: SelectedProjects?.id,
      list_name: project.trim(),
      color: ColorData,
    };
    const result = await CallPOSTAPI("updateList", sendData);
    if (result.data?.status) {
      toast.success(result?.data?.msg);
      setProject("");
      setColorData("");
      getLocations();
      // dispatch(handleRemoveLocationsByListId(currentList?.list_id));
      fetchList();
      handleCancel();
    } else {
      toast.error(result?.data?.msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    setProject(currentList?.title);
    setColorData(currentList?.color);
  }, [currentList]);

  const mapToken = localStorage.getItem("MapozonToken");

  const radiusLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );

  const travelLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const combinedLocations = [...radiusLocation, ...travelLocation];

  const getLocations = async () => {
    const res = await CallGETAPI(`getUserLocations/${mapToken?.split("|")[1]}`);

    if (res.data.status) {
      const { data } = res.data;

      for (let i = 0; i < data.length; i++) {
        const {
          color,
          list_id,
          list_locations,
          list_name,
          project_name,
          project_id,
        } = data[i];
        list_locations?.map(async (item, i) => {
          // if (!combinedLocations.find((obj) => obj.location_id === item.id)) {
          if (item.location_checked1) {
            if (
              item.catchment_area === "Distance" ||
              item.catchment_area === null
            ) {
              if (
                !combinedLocations.find((obj) => obj.location_id === item.id)
              ) {
                let newObj = { ...item };
                newObj.color = color;
                newObj.projectId = project_id;
                newObj.listName = list_name;
                newObj.projectName = project_name;
                newObj.location_id = item.id;
                dispatch(handleAddSelectedLocations([newObj]));
              } else {
                if (
                  combinedLocations.find(
                    (obj) => obj.location_id === item.id && obj.color !== color
                  )
                ) {
                  dispatch(handleRemoveSelectedLocations(item.id));
                  let newObj = { ...item };
                  newObj.color = color;
                  newObj.projectId = project_id;
                  newObj.listName = list_name;
                  newObj.projectName = project_name;
                  newObj.location_id = item.id;
                  dispatch(handleAddSelectedLocations([newObj]));
                }
              }
            } else {
              if (
                !combinedLocations.find((obj) => obj.location_id === item.id)
              ) {
                let localKey =
                  item.location_id.toString() +
                  item.lat.toString() +
                  item.long.toString() +
                  item.travel_way.toString() +
                  item.travel_time.toString() +
                  color.toString();
                const existdata = localStorage.getItem(localKey);
                if (!existdata) {
                  try {
                    const response = await axios.get(
                      `https://api.mapbox.com/isochrone/v1/mapbox/${
                        item?.travel_way
                      }/${item.long},${item.lat}?contours_minutes=${
                        item?.travel_time
                      }&contours_colors=${
                        color?.slice(1) || ""
                      }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
                    );

                    let memoObject = {
                      lat: item.lat,
                      long: item.long,
                      locationName: item.location_name,
                      location_id: item.id,
                      projectId: project_id,
                      list_id: list_id,
                      listName: list_name,
                      projectName: project_name,
                      travelWay: item.travel_way,
                      ...response.data.features[0],
                    };
                    localStorage.setItem(localKey, JSON.stringify(memoObject));
                    dispatch(addDistanceLocation([memoObject]));
                  } catch (error) {
                    toast.error(error.msg);
                  }
                } else {
                  dispatch(addDistanceLocation([JSON.parse(existdata)]));
                }
              } else {
                if (
                  combinedLocations.find(
                    (obj) => obj.location_id === item.id && obj.color !== color
                  )
                ) {
                  dispatch(removeDistanceLocation(item.id));
                  let localKey =
                    item.lat.toString() +
                    item.long.toString() +
                    color.toString();
                  try {
                    const response = await axios.get(
                      `https://api.mapbox.com/isochrone/v1/mapbox/${
                        item?.travel_way
                      }/${item.long},${item.lat}?contours_minutes=${
                        item?.travel_time
                      }&contours_colors=${
                        color?.slice(1) || ""
                      }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
                    );

                    let memoObject = {
                      lat: item.lat,
                      long: item.long,
                      locationName: item.location_name,
                      location_id: item.id,
                      projectId: project_id,
                      list_id: list_id,
                      listName: list_name,
                      projectName: project_name,
                      travelWay: item.travel_way,
                      ...response.data.features[0],
                    };
                    localStorage.setItem(localKey, JSON.stringify(memoObject));
                    dispatch(addDistanceLocation([memoObject]));
                  } catch (error) {
                    toast.error(error.msg);
                  }
                }
              }
            }
          }
        });
      }
    } else {
      dispatch(handleRemoveAllLocations());
    }
  };

  return (
    <>
      <Modal show={isPopupOpen} onHide={handleCancel} centered>
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b className="text-uppercase">Update List</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          {/* Content that may overflow */}
          <div style={{ display: "block" }}>
            <div className="">
              <form onSubmit={addProject} id="add_list_form">
                <div className="form-group d-grid gap-2">
                  <label htmlFor="location_name">
                    {" "}
                    <b class="d-flex mt-2">List Name</b>{" "}
                  </label>
                  <input
                    type="text"
                    name="ProjectName"
                    placeholder="Enter List Name..."
                    className="form-control modal-custom-input italic-input"
                    onChange={(e) => setProject(e.target.value.trimStart())}
                    value={project}
                  />
                </div>
                <div className="mt-4 px-1">
                  {/* <p className="m-0" style={{ fontWeight: "bold" }}>
                    List Color
                  </p> */}
                  <div style={{ marginLeft: "" }} className="mt-1">
                    {/* {" "}
                    <ColorPalette
                      setInput={setColorData}
                      value={ColorData}
                    />{" "} */}

                    <div className="color-palette">
                      {ColorArray.map((item, i) => (
                        <label
                          className="color"
                          style={{
                            backgroundColor: item.bg,
                            cursor: "pointer",
                          }}
                          key={i}
                          htmlFor={"clr_" + i}
                        >
                          <input
                            id={"clr_" + i}
                            type="radio"
                            name="color"
                            value={item.bg}
                            checked={ColorData === item.bg}
                            onChange={(e) => {
                              // setSelected(e.target.value);
                              // setInput(e.target.value);
                              setColorData(e.target.value);
                            }}
                            style={{ display: "none" }}
                          />
                          {ColorData === item.bg ? (
                            <span className="check-icon">
                              {" "}
                              <CheckOutlined />
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                      ))}

                      {/* Add more color divs as needed */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-custom-footer">
          <button
            className="search-btn"
            type="button"
            form="add_list_form"
            // id="add-project-btn"
            onClick={addProject}
          >
            {loading ? "Loading..." : "UPDATE LIST"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateListModal;
