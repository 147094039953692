import { useEffect, useState } from "react";
import { CircleMarker, Marker, Polygon, Tooltip, useMap } from "react-leaflet";
import icon from "./Marker";
// import { polygon } from "@turf/turf";
export default function SearchBarMarker(props) {
  const [position, setPosition] = useState(null);
  const map = useMap();
  const [address, setAddress] = useState("");

  console.log(props);
  useEffect(() => {
    let lon = "";
    let lat = "";
    if (props.position.geometry) {
      lon = props?.position?.geometry?.location?.lng();
      lat = props?.position?.geometry?.location?.lat();
      setPosition([lat, lon]);
      map.flyTo([lat, lon], 10);
    } else {
      lon = props?.position?.long;
      lat = props?.position?.lat;
      setPosition([lat, lon]);
      map.flyTo([lat, lon], 10);
    }
  }, [map, props.position]);

  const coor = [
    [51.515, -0.09],
    [51.52, -0.1],
    [51.52, -0.12],
  ];
  const fillBlueOptions = { fillColor: "blue" };

  return (
    <>
      {position === null ? null : (
        <>
          {props.color && props.radius ? (
            <CircleMarker
              center={position}
              pathOptions={{ color: props?.color }}
              radius={props?.radius * 10}
            >
              <Marker position={position} icon={icon}></Marker>
              <Tooltip>
                <span>{props?.address}</span>
              </Tooltip>
            </CircleMarker>
          ) : (
            <Marker position={position} icon={icon}>
              <Tooltip className="d-flex flex-column">
                <span>
                  Lat & Long&nbsp;:&nbsp;
                  <b className="text-capitalize">
                    {props?.position?.geometry?.location?.lat()},
                    {props?.position?.geometry?.location?.lng()}
                  </b>
                </span>
                <span>{props.position.formatted_address}</span>
              </Tooltip>
            </Marker>
          )}
        </>
      )}

      {/* <Polygon color="blue" positions={coor} /> */}

      {/* {
				props.coordinates ? (<>
					<Polygon pathOptions={props.properties} positions={props.coordinates} />
				</>) : null
			} */}
    </>
  );
}
