import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleActiveProject } from "../../redux/slices/LocationProjectSlice";
import { CallPOSTAPI } from "../../helpers/API";
import { toast } from "react-toastify";
import {
  handleAddSelectedProjects,
  handleRemoveSelectedProjects,
} from "../../redux/slices/LocationProjectSlice";
import { useSelector } from "react-redux";

function SharedProjectList({
  title,
  id,
  item,
  is_selected,
  refreashList,
  setActiveProject,
}) {
  const dispatch = useDispatch();
  const [updateLoading, setUpdateLoading] = useState(false);
  const currentProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  const handleChange = (e, item) => {
    if (e.target.checked) {
      dispatch(handleAddSelectedProjects([item]));
    } else {
      dispatch(handleRemoveSelectedProjects(item?.id));
    }
  };

  const handleLocationStatus = async (e, item) => {
    setUpdateLoading(true);
    let data = e.target.checked
      ? {
          id: [item.id],
          is_checked: 1,
        }
      : {
          id: [item.id],
          is_checked: 0,
        };

    const res = await CallPOSTAPI("projectChecked/", data);

    if (res?.data?.status) {
      setUpdateLoading(false);
    } else {
      toast.error(res?.data?.msg);
    }
  };

  return (
    <>
      <div
        className={`PersonalBoard1Rectangle mt-2  ${
          is_selected ? "active-project" : ""
        } `}
        key={id}
      >
        <div className="d-flex align-items-center justify-content-between cursor-pointer gap-1">
          <label htmlFor={"project_" + id}>
            <input
              className="LocationNameCheckBox"
              type="checkbox"
              id={"project_" + id}
              onChange={(e) => {
                handleChange(e, item);
                handleLocationStatus(e, item);
              }}
              checked={currentProjects.find((loc) => loc.id === item?.id)}
              disabled={updateLoading}
            />
          </label>
          <h2
            className="d-inline-block text-truncate m-0 text-capitalize"
            style={{ fontSize: "15px", maxWidth: "100px" }}
            onClick={() => dispatch(handleActiveProject(item))}
          >
            {title}
          </h2>
        </div>
      </div>
    </>
  );
}

export default SharedProjectList;
