// import React from 'react'

// function DataModal() {
//   return (
//     <div>DataModal</div>
//   )
// }

// export default DataModal

// import React from 'react'

// function DataModal() {
//   return (
//     <div>DataModal</div>
//   )
// }

// export default DataModal

import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Tabs } from "antd";

const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: "1",
    label: `Data`,
    children: (
      <>
        <h4 className="tb-title">Population</h4>
        <p className="tb-desc">
          This dataset provides Census 2021 estimates that classify usual
          residents in England and Wales. The estimates are as at Census Day, 21
          March 2021.
        </p>

        <h4 className="tb-title">Gender</h4>
        <p className="tb-desc">
          This dataset provides Census 2021 estimates that classify usual
          residents in England and Wales by sex. The estimates are as at Census
          Day, 21 March 2021.
        </p>

        <h4 className="tb-title">Age</h4>
        <p className="tb-desc">
          This dataset provides Census 2021 estimates that classify usual
          residents in England and Wales by five-year age bands. The estimates
          are as at Census Day, 21 March 2021.
        </p>

        <h4 className="tb-title">Income</h4>
        <p className="tb-desc">Financial year ending 2018</p>
        <ul>
          <li>
            <strong>Total annual household income:</strong> The sum of the gross
            income of every member of the household plus any income from
            benefits such as Working Families Tax Credit.
          </li>
          <li>
            <strong>Net annual household income:</strong> The sum of the net
            income of every member of the household. It is calculated using the
            same components as total income, but income is net of; income tax
            payments, national insurance contributions, domestic rates/council
            tax, contributions to occupational pension schemes, all maintenance
            and child support payments, which are deducted from the income of
            the person making the payments, and parental contribution to
            students living away from home.
          </li>
          <li>
            <strong>
              Net annual household income before housing costs (equivalised):
            </strong>{" "}
            Composed of the same elements as net household weekly income but is
            subject to the OECD’s equivalisation scale.
          </li>
          <li>
            <strong>
              Net annual household income after housing costs (equivalised):
            </strong>{" "}
            Composed of the same elements of net household weekly income but is
            subject to the following deductions prior to the OECD’s
            equivalisation scale being applied: rent (gross of housing benefit);
            water rates, community water charges and council water charges;
            mortgage interest payments (net of any tax relief); structural
            insurance premiums (for owner occupiers); and ground rent and
            service charges.
          </li>
        </ul>

        <h4 className="tb-title">Household and Size</h4>
        <p className="tb-desc">
          This dataset provides Census 2021 estimates that classify all
          households in England and Wales by household size. The estimates are
          as at Census Day, 21 March 2021.
        </p>

        <h4 className="tb-title">Religion</h4>
        <p className="tb-desc">
          This dataset provides Census 2021 estimates that classify usual
          residents in England and Wales by religion. The estimates are as at
          Census Day, 21 March 2021.
        </p>
      </>
    ),
  },
  {
    key: "2",
    label: `Data Sources`,
    children: (
      <>
        {" "}
        <h4 className="tb-title">Data Sources</h4>
        <table className="table">
          <tr
            style={{
              background: "#ccc",
              color: "#000",
              fontWeight: "bold",
              textAlign: "left",
              padding: "10px",
            }}
          >
            <th>Data</th>
            <th>Released</th>
            <th>Link</th>
          </tr>
          <tr>
            <td>2021 Census</td>
            <td>21st March 2021</td>
            <td>
              <a
                href="https://www.nomisweb.co.uk/sources/census_2021"
                target="_blank"
              >
                Link
              </a>
            </td>
          </tr>
          <tr>
            <td>Household income estimates</td>
            <td>05 March 2020</td>
            <td>
              <a
                href="https://www.ons.gov.uk/employmentandlabourmarket/peopleinwork/earningsandworkinghours/datasets/smallareaincomeestimatesformiddlelayersuperoutputareasenglandandwales"
                target="_blank"
              >
                Link
              </a>
            </td>
          </tr>
        </table>
        <p>
          Contains public sector information licensed under the Open
          <a
            href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
            target="_blank"
          >
            {" "}
            Government Licence v3.0
          </a>
          .
        </p>
      </>
    ),
  },
];

const DataModal = ({ isModalOpen, setIsModalOpen }) => {
  //   const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Data Information"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        centered
        bodyStyle={{ overflow: "auto", maxHeight: "80vh" }}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Modal>
    </>
  );
};

export default DataModal;
