import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";

import { columnsFromBackend } from "./KanbanData";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TaskCard from "./TaskCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEdit } from "@fortawesome/free-solid-svg-icons";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye, AiOutlinePlusCircle } from "react-icons/ai";
import AddLocationModal from "../modals/AddLocationModal";
import AddListModal from "../modals/AddListModal";
import { CallGETAPI, CallPOSTAPI } from "../../helpers/API";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  addDistanceLocation,
  handleAddSelectedLocations,
  handleLoadingFalse,
  handleLoadingTrue,
  handleRemoveLocationsByListId,
  handleRemoveSelectedLocations,
  removeDistanceLocation,
} from "../../redux/slices/LocationProjectSlice";
import { confirmAlert } from "react-confirm-alert";
import AddressSvg from "../../images/Address-bro2.svg";
import Logo from "../../images/logo.svg";
import UpdateListModal from "../modals/UpdateListModal";
import { FaRegEdit } from "react-icons/fa";
import { MdClose, MdIosShare } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RxEyeClosed } from "react-icons/rx";

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
  max-height: 100vh;
  overflow-y: auto;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Title = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
`;

// { setUpdateLoading, updateLoading }

const Test2DragDrop = ({
  setUpdateLoading,
  updateLoading,
  setProjectDetailDiv,
}) => {
  const [columns, setColumns] = useState(columnsFromBackend);
  const [AddLocationListId, setAddLocationListID] = useState("");
  const [ListLoading, setListLoading] = useState(false);
  const [updateListModal, setUpdateListModal] = useState(false);
  const [currentList, setCurrentList] = useState({});
  const selectedLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );
  const distanceLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const reduxLoading = useSelector(
    (state) => state?.LocationProject?.reduxLoading
  );

  const combinedLocations = [...selectedLocation, ...distanceLocation];

  const currentProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const userId = user?.sub?.slice(6);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    // draggableId
    const cardId = result?.draggableId;
    const disti_list_id = ListData?.[result?.destination?.droppableId]?.list_id;

    const locationCard = findItemById(cardId);
    // ...locationCard,
    const updateLocationData = {
      user_id: userId,
      address: locationCard?.address,
      catchment_area: locationCard?.catchment_area,
      color: locationCard?.color,
      distance_radius: locationCard?.distance_radius,
      lat: locationCard?.lat,
      long: locationCard?.long,
      location_name: locationCard?.location_name,
      status: locationCard?.status,
      id: locationCard?.location_id,
      list_id: disti_list_id,
      travel_way: locationCard?.travel_way,
      travel_time: locationCard?.travel_time,
    };

    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      const UpdatedLocationList = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      const LocationData = [];
      for (let di = 0; di < destItems.length; di++) {
        const ditem = destItems[di];
        const obj = {
          location_id: ditem.location_id,
          list_id: disti_list_id, //ditem.list_id,
          order_index: di + 1,
        };
        LocationData.push(obj);
      }

      setListData({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      UpdateLocation(LocationData);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
      setListData({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
      //   setListLoading
      const LocationData = [];
      for (let di = 0; di < copiedItems.length; di++) {
        const ditem = copiedItems[di];
        const obj = {
          location_id: ditem.location_id,
          list_id: disti_list_id, //ditem.list_id,
          order_index: di + 1,
        };
        LocationData.push(obj);
      }
      UpdateLocation(LocationData);
    }
  };

  const findItemById = (id) => {
    for (const key in ListData) {
      if (ListData.hasOwnProperty(key)) {
        const item = ListData[key];
        if (item.items) {
          const foundItem = item.items.find((item) => item.id === id);
          if (foundItem) {
            return foundItem;
          }
        }
      }
    }
    return null; // Return null if the ID is not found
  };

  const [openLocationModal, setOpenLocationModal] = useState(false);
  const LocationHandler = (list_id) => {
    setAddLocationListID(list_id);
    setOpenLocationModal(!openLocationModal);
  };
  const [openListModal, setOpenListModal] = useState(false);
  const SelectedProjects = useSelector(
    (state) => state?.LocationProject?.active_project
  );
  const [ListData, setListData] = useState({});

  const fetchList = async () => {
    // if (!SelectedProjects?.id) {
    //   toast.error("Please Any Project");
    //   return "";
    // }
    setListLoading(true);
    const result = await CallGETAPI("getLists/" + SelectedProjects?.id);
    if (result?.data?.status) {
      const resultData = await prepareData(result?.data?.data);
      setListData(resultData);
    } else {
      setListData({});
    }
    setListLoading(false);
  };

  const prepareData = async (data) => {
    let resultAr = {};

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const EleLocation = element?.list_locations;

      const obj = {
        list_id: element?.list_id,
        title: element?.list_name,
        color: element?.color,
        project_id: element?.project_id,
        list_name: element?.list_name,
        project_name: element?.project_name,
        items: [],
      };

      for (let i2 = 0; i2 < EleLocation.length; i2++) {
        const ele2 = EleLocation[i2];
        const obj2 = {
          ...ele2,
          id: uuidv4(),
          list_id: ele2?.list_id,
          location_id: ele2?.id,
          title: ele2?.location_name,
          Due_Date: "25-May-2020",
          Task: ele2?.location_name,
          project_id: element?.project_id,
        };
        obj.items.push(obj2);
      }
      resultAr = { ...resultAr, [uuidv4()]: obj };
    }
    return resultAr;
  };

  const UpdateLocation = async (locationData) => {
    // const locationId = locationData?.id;
    // if (!locationId) {
    //   toast.error("Invalid  Location...");
    //   return "";
    // }

    const res = await CallPOSTAPI("update-location-order/", locationData);
    if (res?.data?.status) {
      // toast.success(res?.data?.msg);
    } else {
      toast.msg(res?.data?.msg);
    }
  };

  // const UpdateLocation = async (locationData) => {
  //   const locationId = locationData?.id;
  //   if (!locationId) {
  //     toast.error("Invalid  Location...");
  //     return "";
  //   }

  //   const res = await CallPOSTAPI("updateLocation/" + locationId, locationData);
  //   if (res?.data?.status) {
  //     // toast.success(res?.data?.msg);
  //   } else {
  //     toast.msg(res?.data?.msg);
  //   }
  // };

  useEffect(() => {
    fetchList();
  }, [SelectedProjects?.id]);
  const dispatch = useDispatch();
  const handleLocationCheckBox = (locationId) => {
    const sendArr = [];
    // dispatch(handleAddSelectedLocations())
  };

  const [checkboxLoading, setCheckboxLoading] = useState(false);

  const handleCheckboxChange = async (
    e,
    item,
    color,
    projectId,
    listName,
    projectName,
    select_all = false
  ) => {
    const selectedDistanceLocation = [];
    const selected = [];

    // console.log("item in handle checkbox", item);
    // dispatch(handleLoadingTrue());

    // console.log("event", e.target.checked);

    // if (!combinedLocations.find((obj) => obj.id === item.id)) {
    if (item.catchment_area === "Distance" || item.catchment_area === null) {
      if (e) {
        if (
          !combinedLocations.find((obj) => obj.location_id === item.location_id)
        ) {
          let newObj = { ...item };
          newObj.color = color;
          newObj.projectId = projectId;
          newObj.listName = listName;
          newObj.projectName = projectName;
          newObj.is_project_selected =
            currentProjects.find((item) => item?.project_id) || false;

          dispatch(handleAddSelectedLocations([newObj]));
        }
      } else {
        dispatch(handleRemoveSelectedLocations(item?.location_id));
      }
    } else {
      if (e) {
        if (
          !combinedLocations.find((obj) => obj.location_id === item.location_id)
        ) {
          // let localKey = item.lat.toString() + item.long.toString();
          let localKey =
            item.location_id.toString() +
            item.lat.toString() +
            item.long.toString() +
            item.travel_way.toString() +
            item.travel_time.toString() +
            color.toString();
          const existdata = localStorage.getItem(localKey);
          if (!existdata) {
            try {
              const response = await axios.get(
                `https://api.mapbox.com/isochrone/v1/mapbox/${
                  item?.travel_way
                }/${item.long},${item.lat}?contours_minutes=${
                  item?.travel_time
                }&contours_colors=${
                  color?.slice(1) || ""
                }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
              );

              let memoObject = {
                lat: item.lat,
                long: item.long,
                locationName: item.location_name,
                location_id: item.location_id,
                projectId,
                list_id: item.list_id,
                listName: listName,
                projectName: projectName,
                travelWay: item.travel_way,
                is_project_selected:
                  currentProjects.find((item) => item?.project_id) || false,
                ...response.data.features[0],
              };
              localStorage.setItem(localKey, JSON.stringify(memoObject));
              dispatch(addDistanceLocation([memoObject]));
            } catch (error) {
              toast.error(error.msg);
            }
          } else {
            let currentLoc = JSON.parse(existdata);
            let locData = { ...currentLoc };
            locData.listName = listName;
            locData.projectName = projectName;
            dispatch(addDistanceLocation([locData]));
            // dispatch(addDistanceLocation([JSON.parse(existdata)]));
          }
        }
      } else {
        dispatch(removeDistanceLocation(item?.location_id));
      }
    }
    // dispatch(handleLoadingFalse());
    // }
  };

  const DeleteList = async (listId) => {
    const res = await CallGETAPI("deleteList/" + listId);
    if (res?.data?.status) {
      toast.success(res?.data?.msg);
      dispatch(handleRemoveLocationsByListId(listId));
      fetchList();
    } else {
      toast.error(res?.data?.msg);
    }
  };

  const handleSelectAll = (e, column) => {
    // setProfileLoading(true);
    if (e) {
      dispatch(handleLoadingTrue());
      column?.items.map((item) =>
        handleCheckboxChange(
          e,
          item,
          column.color,
          column.project_id,
          column.project_name,
          column.list_name
        )
      );
      dispatch(handleLoadingFalse());
    } else {
      dispatch(handleLoadingTrue());
      dispatch(handleRemoveLocationsByListId(column?.list_id));
      dispatch(handleLoadingFalse());
    }

    // setProfileLoading(false);
  };

  // const [updateLoading, setUpdateLoading] = useState(false);

  const handleAllLocationStatus = async (e, column) => {
    setUpdateLoading(true);

    let idArray = [];

    column?.items.map((item) => idArray.push(item.location_id));

    // let data = e.target.checked
    let data = e
      ? {
          id: idArray,
          is_checked: 1,
          user_id: user?.sub?.split("|")[1],
        }
      : {
          id: idArray,
          is_checked: 0,
          user_id: user?.sub?.split("|")[1],
        };

    const res = await CallPOSTAPI("locationChecked/", data);

    if (res?.data?.status) {
      setUpdateLoading(false);
    } else {
      toast.error(res?.data?.msg);
    }
  };

  return (
    <>
      {/* {Object.keys(ListData).length === 0 && !ListLoading && (
        <>
          <div
            className=""
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div className="loading-section align-items-center">
              <div className=""> */}
      {/* {SelectedProjects?.id ? (
              <div
                className="AddAnotherList"
                onClick={() => setOpenListModal(true)}
                style={{ width: "145px" }}
              >
                <AiOutlinePlusCircle
                  className="AddAnotherListaddIcon"
                  style={{ marginLeft: "35px" }}
                />
                <h2 className="AddAnotherListText" style={{ width: "auto" }}>
                  Add List
                </h2>
              </div>
            ) : (
              <div
               
                className="d-flex flex-column align-items-center"
              >
                <img src={AddressSvg} alt="address" style={{ width: "100%" }} />
                <img src={Logo} alt="logo" style={{ width: "100%" }} />
              </div>
            )}
            {/* </div>
            </div> */}
      {/* </div>
        </>
      // )} */}

      {ListLoading ? (
        <div className="loading-section">
          <div className="loader-11202" />
        </div>
      ) : (
        <>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <span
                  className="main-heading"
                  // onClick={() => setProjectDetailDiv(!projectDetailDiv)}
                >
                  {SelectedProjects?.project_name}
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <button
                  className="search-btn d-flex align-items-center justify-content-center gap-1 px-5"
                  type="button"
                  onClick={() => setOpenListModal(true)}
                >
                  <AiOutlinePlusCircle className="AddLocationaddIcon" />
                  <span>Add New List</span>
                </button>
                <MdClose
                  role="button"
                  size={35}
                  onClick={() => setProjectDetailDiv(false)}
                />
              </div>
            </div>
            <hr className="my-2" />
          </div>
          <DragDropContext
            onDragEnd={(result) => {
              onDragEnd(result, ListData, setColumns);
            }}
          >
            {/* <Draggable draggableId="dragId" index={1} id="dragId"> */}
            <Container>
              {/* <Droppable droppableId="dropId" id="dropId">
              <div style={{ width: "100%" }}> */}
              {Object.keys(ListData).length > 0 && (
                // <Draggable draggableId="dragId" index={1} id="dragId">
                <TaskColumnStyles>
                  {Object.entries(ListData).map(([columnId, column], index) => {
                    return (
                      <Droppable
                        key={columnId}
                        droppableId={columnId}
                        id={columnId}
                      >
                        {(provided, snapshot) => (
                          <TaskList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="p-0 overflow-hidden"
                            style={{
                              backgroundColor: "#f6f6f6",
                              maxHeight: "60vh",
                              minWidth: "250px",
                              marginRight: "10px",
                            }}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center px-3 py-2"
                              style={{
                                backgroundColor: column?.color || "#EAEAEA",
                              }}
                            >
                              <div className="d-flex justify-content-center align-items-center gap-1">
                                {column.items.length &&
                                column?.items.every((obj1) =>
                                  combinedLocations.some(
                                    (obj2) =>
                                      obj2["location_id"] ===
                                        obj1["location_id"] ||
                                      obj2["id"] === obj1["location_id"]
                                  )
                                ) ? (
                                  <AiOutlineEye
                                    onClick={() => {
                                      handleSelectAll(false, column);
                                      handleAllLocationStatus(false, column);
                                    }}
                                    color={"#ffffff"}
                                    style={{ cursor: "pointer" }}
                                    size={18}
                                  />
                                ) : (
                                  <RxEyeClosed
                                    onClick={() => {
                                      handleSelectAll(true, column);
                                      handleAllLocationStatus(true, column);
                                    }}
                                    color={"#ffffff"}
                                    style={{ cursor: "pointer" }}
                                    size={18}
                                  />
                                )}
                                <span
                                  className="main-heading text-white"
                                  style={{
                                    maxWidth: "150px",
                                  }}
                                >
                                  {column.title}
                                </span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between gap-1">
                                {/* <input
                                style={{
                                  accentColor: column?.color || "#EAEAEA",
                                }}
                                className="TargetlocationCheckBox"
                                type="checkbox"
                              /> */}

                                <FiEdit
                                  size={18}
                                  color={"#ffffff"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setCurrentList(column);
                                    setUpdateListModal(true);
                                  }}
                                />
                                {/* <FontAwesomeIcon
                                icon={faCaretDown}
                                className="TargetLocationDropdownIcon"
                                
                              /> */}

                                <RiDeleteBinLine
                                  size={18}
                                  color={"#ffffff"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    confirmAlert({
                                      customUI: ({ onClose }) => {
                                        return (
                                          <div className="custom-confirm-alert">
                                            <div
                                              style={{
                                                width: "60%",
                                                margin: "auto",
                                                textAlign: "center",
                                              }}
                                            >
                                              <h1
                                                className="main-heading text-uppercase"
                                                style={{ fontSize: "18px" }}
                                              >
                                                Are you sure you want to delete
                                                this item?
                                              </h1>
                                            </div>

                                            <div className="d-flex flex-column justify-content-between gap-4 mt-5">
                                              <button
                                                className="search-btn"
                                                style={{
                                                  backgroundColor: "#ff6b62",
                                                }}
                                                onClick={() => {
                                                  DeleteList(column?.list_id);
                                                  onClose();
                                                }}
                                              >
                                                Delete
                                              </button>
                                              <button
                                                className="search-btn"
                                                style={{
                                                  backgroundColor: "#fff",
                                                  border: "1px solid #d9d9d9",
                                                  color: "black",
                                                }}
                                                onClick={onClose}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      },
                                      // title: "Delete List?",
                                      // message: "Are you sure to do this.",
                                      // buttons: [
                                      //   {
                                      //     label: "Yes",
                                      //     onClick: () =>
                                      //       DeleteList(column?.list_id),
                                      //   },
                                      //   {
                                      //     label: "No",
                                      //     onClick: () => {},
                                      //   },
                                      // ],
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            {/* {column.items.length ? (
                            <>
                              <label
                                className="d-flex align-items-center gap-2 mx-auto px-3 py-2"
                                style={{ width: "95%" }}
                                htmlFor={columnId}
                              >
                                <input
                                  className=""
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleSelectAll(e, column);
                                    handleAllLocationStatus(e, column);
                                  }}
                                  checked={
                                    column?.items.every((obj1) =>
                                      combinedLocations.some(
                                        (obj2) =>
                                          obj2["location_id"] ===
                                            obj1["location_id"] ||
                                          obj2["id"] === obj1["location_id"]
                                      )
                                    ) && column.items.length
                                      ? true
                                      : false
                                  }
                                  // value={column?.list_id}
                                  id={columnId}
                                  disabled={updateLoading}
                                />
                                <p className="m-0" style={{ fontSize: "16px" }}>
                                  Select All
                                </p>
                              </label>

                              <hr
                                className="mx-auto"
                                style={{
                                  width: "95%",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )} */}
                            <div className="d-flex flex-column justify-content-between h-100">
                              <div
                                className="mt-2 overflow-auto"
                                style={{ height: "46vh" }}
                              >
                                {column?.items.map((item, index) => {
                                  let itemChecked = item.location_checked
                                    ? 1
                                    : 0;

                                  // handleReduxValues(item, column);
                                  return (
                                    <TaskCard
                                      // key={item}
                                      key={`location${index}`}
                                      item={item}
                                      index={index}
                                      handleCheckboxChange={
                                        handleCheckboxChange
                                      }
                                      color={column?.color || "#EAEAEA"}
                                      projectId={column?.project_id}
                                      fetchList={fetchList}
                                      // itemChecked={itemChecked}
                                      projectName={column?.project_name}
                                      listName={column?.list_name}
                                    />
                                  );
                                })}
                                {provided?.placeholder}
                              </div>
                              <div className="px-2 pb-2">
                                <button
                                  className="search-btn d-flex justify-content-center align-items-center gap-1"
                                  style={{ backgroundColor: column.color }}
                                  onClick={() =>
                                    LocationHandler(column?.list_id)
                                  }
                                >
                                  <AiOutlinePlusCircle className="AddLocationaddIcon" />
                                  <span className="py-1">Add New Location</span>
                                </button>
                              </div>
                            </div>
                          </TaskList>
                        )}
                      </Droppable>
                    );
                  })}

                  {/* <div
                  className="AddAnotherList"
                  onClick={() => setOpenListModal(true)}
                >
                  <AiOutlinePlusCircle className="AddAnotherListaddIcon" />
                  <h2 className="AddAnotherListText">Add Another List</h2>
                </div> */}
                </TaskColumnStyles>
                // </Draggable>
              )}
              {/* </div>
            </Droppable> */}
            </Container>
          </DragDropContext>
        </>
      )}

      <AddLocationModal
        show2={openLocationModal}
        handleClose2={() => setOpenLocationModal(false)}
        list_id={AddLocationListId}
        reloadList={fetchList}
      />
      <AddListModal
        isPopupOpen={openListModal}
        handleCancel={() => setOpenListModal(false)}
        fetchList={fetchList}
      />

      <UpdateListModal
        isPopupOpen={updateListModal}
        handleCancel={() => setUpdateListModal(false)}
        fetchList={fetchList}
        currentList={currentList}
      />
    </>
  );
};

export default Test2DragDrop;
