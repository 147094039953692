import { DeleteColumnOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Button, Modal, Form } from "react-bootstrap";
import {
  Bicycle,
  CarIcon,
  WalkingMan,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from "../../Icons";

import "../../style/custom.css";

import ColorPalette from "../../ColorPallete";
import AutoCompleteInput from "../../components/AutocompleteInput/AutoCompleteInput";
import { DeCryptToken } from "../../helpers/Common";
import { toast } from "react-toastify";
// import { CallPOSTAPI } from "./helpers/API";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { CallPOSTAPI } from "../../helpers/API";

function AddLocationModal({ show2, handleClose2, list_id, reloadList }) {
  const [openInfoModal2, setInfoModal2] = useState(false);
  const [volume, setVolume] = useState(5);
  const [selectedOption, setSelectedOption] = useState("none");
  const [showName, setShowName] = useState(false);
  // input fields
  const [ColorData, setColorData] = useState("");
  const [DistanceVal, setDistanceVal] = useState(5);
  const [DistanceTime, setDistanceTime] = useState(30);
  const [walingType, setwalingType] = useState("");
  const [LocationName, setLocationName] = useState("");
  const [mapData, setMapData] = useState({});
  const [place, setPlace] = useState({});

  const handleChange = (event) => {
    const value = event.target.value;
    console.log("value hai", typeof value);
    if (value >= 0 && value <= 10) {
      setVolume(event.target.value);
      setDistanceVal(event.target.value);
    }
    // else if (value === null) {
    //   setVolume(10);
    //   setDistanceVal(10);
    // }
    else {
      setVolume(5);
      setDistanceVal(5);
    }
  };

  const handleCheckboxChange = (event) => {
    setShowName(event.target.checked);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const userId = user?.sub?.slice(6);

  const handleLogin = (e) => {
    e.preventDefault();
    // Add your login logic here
    console.log("Logging in...");
    handleClose2();
  };
  const [locationInput, setLocationinput] = useState({
    lat: "",
    long: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const autoCompletInputHandle = (val) => {
    setLocationinput(val);
    console.log("value to be added", val);
  };
  //
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const userData = DeCryptToken();

    const sendObj = {
      user_id: userId,
      lat: locationInput?.lat || "",
      long: locationInput?.long || "",
      address: locationInput?.name || "",
      //   color: ColorData || "",
      catchment_area: selectedOption,
      distance_radius: DistanceVal,
      travel_way: walingType,
      travel_time: DistanceTime,
      location_name: LocationName.trim(),
      list_id: list_id,
    };

    if (!user) {
      toast.error("Invalid User Please Login");
      return "";
    }

    if (!list_id) {
      toast.error("Please Select Any List");
      return "";
    }

    if (!locationInput.name) {
      toast.error("Please select location.");
      return;
    }

    if (!LocationName) {
      toast.error("Location name is Required.");
      return "";
    }

    // if (!showName) {
    //   toast.error("Please Select Catchment Area");
    //   return "";
    // }

    if (showName && !selectedOption) {
      toast.error("Please Select Catchment Area Type");
      return "";
    }

    if (selectedOption === "Distance") {
      sendObj.travel_time = 0;
      sendObj.travel_way = null;
      if (
        !sendObj.distance_radius ||
        sendObj.distance_radius === 0 ||
        sendObj.distance_radius === "0" ||
        DistanceVal === 0 ||
        DistanceVal === "0"
      ) {
        toast.error("Please Set Distance Renge");
        return "";
      }
    }

    if (selectedOption === "TravelTime") {
      sendObj.distance_radius = 0;
      if (!sendObj.travel_way) {
        toast.error("Please Select travel Way");
        return "";
      }
      if (
        !sendObj.travel_time ||
        sendObj.travel_time === 0 ||
        sendObj.travel_time === "0" ||
        DistanceTime === 0 ||
        DistanceTime === "0"
      ) {
        toast.error("Please Set Time Renge");
        return "";
      }
    }

    if (selectedOption === "none") {
      sendObj.distance_radius = 0;
      sendObj.travel_time = 0;
      sendObj.travel_way = null;
    }

    setLoading(true);
    const res = await CallPOSTAPI("add-location", sendObj);
    if (selectedOption === "Distance") {
      // setSearched(place)
      // setMapLocation({
      //   color: ColorData,
      //   radius: DistanceVal,
      //   address: locationInput?.name
      // })
      setColorData("");
      setVolume(5);
      setDistanceVal(5);
      setDistanceTime(30);
      setwalingType("");
      setLocationName("");
      setShowName(false);
      setSelectedOption(null);
      handleClose2();
      reloadList();
    } else {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/isochrone/v1/mapbox/${walingType}/${
            locationInput?.long
          },${
            locationInput?.lat
          }?contours_minutes=${DistanceTime}&contours_colors=${
            ColorData.slice(1) || ""
          }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
        );
        console.log(response.data);
      } catch (error) {
        toast.error(error.msg);
      }
    }
    if (res.data.status) {
      toast.success(res.data.msg);
      setColorData("");
      setVolume(5);
      setDistanceVal(5);
      setDistanceTime(30);
      setwalingType("");
      setLocationName("");
      setShowName(false);
      setSelectedOption(null);
      handleClose2();
      reloadList();
    } else {
      toast.error(res.data.msg);
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setColorData("");
    setVolume(5);
    setDistanceVal(5);
    setDistanceTime(30);
    setwalingType("");
    setLocationName("");
    setShowName(false);
    setSelectedOption(null);
    handleClose2();
  };

  return (
    <>
      <Modal show={show2} onHide={handleCloseModal} centered>
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b className="text-uppercase">Add New Location</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <div class="container"></div>

          <form className="" onSubmit={handleSubmit} id="add-location-form">
            {/* add-location */}

            {/* <div class="container"> */}
            <div class="mt-2">
              <label htmlFor="location_name" className="mb-2">
                <b>Search For Location</b>
              </label>
              <AutoCompleteInput
                setPlace={setPlace}
                handleValue={autoCompletInputHandle}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <label htmlFor="location_name">
                {" "}
                <b class="d-flex mt-2">Location Name</b>{" "}
              </label>
              <input
                type="text"
                name=""
                id="location_name"
                placeholder="Enter Location Name..."
                onChange={(e) => setLocationName(e.target.value.trimStart())}
                value={LocationName}
                className="form-control modal-custom-input italic-input"
              />
            </div>
            {/* </div> */}

            {/* <b style={{ marginLeft: "1rem" }} className="d-flex mt-2">
              Colour
            </b>
            <div style={{ marginLeft: "1rem" }} className="mt-2">
              {" "}
              <ColorPalette setInput={setColorData} />{" "}
            </div> */}

            <div className="mt-4">
              {/* <label className="checkbox1">
                <input
                  type="checkbox"
                  defaultValue={false}
                  checked={showName}
                  onChange={handleCheckboxChange}
                />
                &nbsp;
                <b>Add Catchment Area</b>
              </label>
              <br /> */}
              {/* {showName && ( */}
              {/* <p> */}
              <label htmlFor="location_name" className="">
                <b>Catchment Area</b>
              </label>
              <div
                style={{ margin: "14px", gap: "2rem" }}
                className="d-flex justify-content-start"
              >
                <div className="flex flex-col">
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "2rem",
                    }}
                  >
                    <label className="label">
                      <input
                        type="radio"
                        value="Distance"
                        checked={selectedOption === "Distance"}
                        onChange={handleRadioChange}
                        className="modal-custom-radio"
                      />
                      Distance
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        value="TravelTime"
                        checked={selectedOption === "TravelTime"}
                        onChange={handleRadioChange}
                        className="modal-custom-radio"
                      />
                      Travel Time
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        value="none"
                        checked={selectedOption === "none"}
                        onChange={handleRadioChange}
                        className="modal-custom-radio"
                      />
                      None
                    </label>
                  </span>

                  <div className="d-flex flex-column">
                    {selectedOption === "TravelTime" && (
                      <>
                        <div className="icon-flex">
                          <label
                            htmlFor={"id_driving"}
                            className={`icons ${
                              walingType === "driving" ? "checkedbox" : ""
                            } `}
                          >
                            <input
                              style={{ display: "none" }}
                              id={"id_driving"}
                              type="radio"
                              value="driving"
                              onClick={(e) => setwalingType(e.target.value)}
                            />
                            <CarIcon />
                          </label>
                          <label
                            htmlFor={"id_walking"}
                            className={`icons ${
                              walingType === "walking" ? "checkedbox" : ""
                            } `}
                          >
                            <input
                              style={{ display: "none" }}
                              id={"id_walking"}
                              type="radio"
                              value="walking"
                              onClick={(e) => setwalingType(e.target.value)}
                            />
                            <WalkingMan
                              color={walingType === "walking" ? "#fff" : "#000"}
                            />
                          </label>
                          <label
                            htmlFor={"id_cycling"}
                            className={`icons ${
                              walingType === "cycling" ? "checkedbox" : ""
                            } `}
                          >
                            <input
                              style={{ display: "none" }}
                              id={"id_cycling"}
                              type="radio"
                              value="cycling"
                              onClick={(e) => setwalingType(e.target.value)}
                            />
                            <Bicycle />
                          </label>
                        </div>

                        <div className="volume-container ">
                          <div className="range-container">
                            <input
                              type="range"
                              min={0}
                              max={60}
                              // step={0.1}
                              value={DistanceTime}
                              onChange={(e) => setDistanceTime(e.target.value)}
                              className="volume-slider"
                              style={{ height: "0.3rem" }}
                              step={1}
                            />
                          </div>
                          <span className="d-flex align-items-center position-relative">
                            <input
                              style={{ width: "80px" }}
                              type="number"
                              value={DistanceTime}
                              min={0}
                              max={60}
                              step={1}
                              onChange={(e) => setDistanceTime(e.target.value)}
                              className="modal-custom-input form-control"
                            />
                            <b
                              className="position-absolute"
                              style={{ right: "12px" }}
                            >
                              Min
                            </b>
                            {/* <b>{DistanceTime}Min</b> */}
                          </span>
                        </div>
                      </>
                    )}
                    {selectedOption === "Distance" && (
                      <div className="volume-container ">
                        <div className="range-container">
                          <input
                            type="range"
                            min={0}
                            max={10}
                            step={0.1}
                            defaultValue={5}
                            value={volume}
                            onChange={handleChange}
                            className="volume-slider"
                            style={{ height: "0.3rem" }}
                          />
                        </div>
                        <span className="d-flex align-items-center position-relative">
                          <input
                            style={{ width: "80px" }}
                            type="number"
                            value={volume}
                            min={0}
                            max={10}
                            step={0.1}
                            onChange={handleChange}
                            className="modal-custom-input form-control"
                          />
                          <b
                            className="position-absolute"
                            style={{ right: "12px" }}
                          >
                            Km
                          </b>
                        </span>
                        {/* <span>
                              {" "}
                              <b>{volume}km</b>{" "}
                            </span> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* </p> */}
              {/* // )} */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="modal-custom-footer">
          <button
            className="search-btn py-2"
            form="add-location-form"
            disabled={loading}
          >
            {loading ? "Loading..." : "ADD NEW LOCATION"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddLocationModal;
