// import React from 'react'

// function InfoModal() {
//   return (
//     <div>InfoModal</div>
//   )
// }

// export default InfoModal

import React, { useState } from "react";
import { Button, Modal } from "antd";

const InfoModal = ({ isModalOpen, setIsModalOpen }) => {
  //   const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Percentage Score"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p>
          The percentage score indicates the position of a particular value in a
          selected dataset and area, revealing how much it deviates from the
          smallest value in the dataset across the entire range. This score is
          essential in understanding the significance or magnitude of that value
          compared to others in the dataset.
        </p>
        <p>
          To illustrate, a score of 0% implies that the individual value is
          identical to the smallest value in the dataset. Conversely, a score of
          100% means the individual value matches the largest value in the
          dataset. Any score between 0% and 100% indicates where the individual
          value falls within that range, allowing us to gauge its importance
          within the dataset and area being studied.
        </p>
        <p>
          To visually represent the percentage score, a colour code is assigned
          to the relevant area. This colour code serves to emphasise the
          relative position of the specific value within the dataset and area,
          enabling easy interpretation of its significance with just a glance.
          You can find the legend for the colour code at the bottom right corner
          of the application.
        </p>
      </Modal>
    </>
  );
};

export default InfoModal;
