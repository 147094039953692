import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CallGETAPI, CallPOSTAPI } from "../../helpers/API";
import { useAuth0 } from "@auth0/auth0-react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert";

function ShareProjectModal({
  isPopupOpen,
  handleCancel,
  fetchProjects,
  currentProject,
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [usersList, setUsersList] = useState([]);

  const { user } = useAuth0();
  const ownerEmail = user?.email;

  const assignProject = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please Enter Email");
      return "";
    }

    if (email === ownerEmail) {
      toast.error("Please Enter Any Other Email");
      return "";
    }

    setLoading(true);
    const sendData = {
      email: email,
      shared_project: currentProject.id,
    };
    const result = await CallPOSTAPI("assign-project", sendData);
    if (result.data?.status) {
      toast.success(result?.data?.msg);
      setEmail("");
      handleCancel();
      getUsersList(currentProject.id);
      // fetchProjects();
    } else {
      toast.error(result?.data?.msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentProject.id) {
      getUsersList(currentProject.id);
    }
  }, [currentProject]);

  const getUsersList = async (id) => {
    const res = await CallGETAPI(`get-users-by-project/${id}`);
    setUsersList(res.data.data);
    // console.log("res is", res);
  };

  const onModalClose = () => {
    handleCancel();
    setEmail("");
  };

  const removeUser = async (email) => {
    setLoading(true);
    const sendData = {
      email: email,
      shared_project: currentProject.id,
    };
    const result = await CallPOSTAPI("remove-user-from-project", sendData);
    if (result.data?.status) {
      toast.success(result?.data?.msg);
      handleCancel();
      getUsersList(currentProject.id);
    } else {
      toast.error(result?.data?.msg);
    }
    setLoading(false);
  };

  // console.log("users list", usersList);

  return (
    <>
      <Modal show={isPopupOpen} onHide={onModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b className="text-capitalize">
              Share&nbsp;{currentProject.project_name}
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          {/* Content that may overflow */}
          <div style={{ display: "block" }}>
            <div className="">
              <form
                onSubmit={assignProject}
                id="add_project_form"
                className="d-flex align-items-center gap-2"
              >
                <div className="form-group d-grid gap-2 w-100">
                  <input
                    type="email"
                    name="user-email"
                    placeholder="Enter Email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value.trim())}
                    value={email}
                  />
                </div>
                <button
                  className="add-project-btn"
                  type="button"
                  form="add_project_form"
                  id="add-project-btn"
                  onClick={assignProject}
                  disabled={
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
                      ? false
                      : true
                  }
                >
                  {loading ? "Loading..." : "Share"}
                </button>
              </form>
            </div>
            <div>
              <div
                className="d-flex justify-content-between align-items-center mt-3"
                style={{ width: "95%", margin: "auto" }}
              >
                <p className="m-0 p-0">{ownerEmail}</p>
                <span
                  className="px-2"
                  style={{
                    border: "2px solid black",
                    borderRadius: "5px",
                    backgroundColor: "lightgray",
                    color: "black",
                  }}
                >
                  owner
                </span>
              </div>
              <div className="mt-2">
                {usersList?.map((item, i) => (
                  <div
                    key={i}
                    className="d-flex justify-content-between align-items-center mt-1"
                    style={{ width: "95%", margin: "auto" }}
                  >
                    <p className="m-0 p-0">{item.email}</p>
                    <RiDeleteBin6Line
                      // className="DeleteIcon"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCancel();
                        confirmAlert({
                          title: "Delete Project?",
                          message: "Are you sure to do this.",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => {
                                removeUser(item.email);
                              },
                            },
                            {
                              label: "No",
                              onClick: () => {},
                            },
                          ],
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShareProjectModal;
