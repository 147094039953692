import { toast } from "react-toastify";
import { TokenKey } from "./Constants";

// export const BASE_API = 'https://call-flow-tc.onrender.com/';
// export const BASE_API = 'http://16.16.23.96/webapp/'; //
export const BASE_API = "https://api.mapozone.com/webapp/";
// export const BASE_API = "http://localhost:3001/";
// export const BASE_API = "http://192.168.1.51:3001/"; //
// https://callflow.techcarrel.in/
//export const BASE_API = 'https://bidmate-tc-2.onrender.com/'; // http://localhost:3005

export async function CallGETAPI(url, params = "", headers = {}) {
  try {
    let token = localStorage.getItem(TokenKey);
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: token };
    }
    let res = await fetch(BASE_API + url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();

    if (resultData.message === "Invalid token.Unauthorized access.") {
      localStorage.removeItem(TokenKey);
      toast.error("Session Out Please Login");
    }

    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallPOSTAPI(url, params = [], headers = {}) {
  try {
    let token = localStorage.getItem(TokenKey);

    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: token, ...headers };
    }

    let res = await fetch(BASE_API + url, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(params),
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallPOSTAPIFles(url, jsonData, files = [], headers = {}) {
  try {
    let token = localStorage.getItem(TokenKey);
    let AllHeaders = { ...headers };

    if (token) {
      AllHeaders = { Authorization: token, ...headers };
    }

    let formData = new FormData();

    // Append JSON data to the form data
    formData.append("jsonData", JSON.stringify(jsonData));

    // Append files to the form data
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    let res = await fetch(BASE_API + url, {
      method: "POST",
      headers: { ...AllHeaders },
      body: formData,
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }

    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}
