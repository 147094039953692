import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription_data: {},
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    handleSubscription: (state, action) => {
      state.subscription_data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
