import { useCallback, useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Pane,
  Polygon,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IoMdArrowDropdown } from "react-icons/io";
import { LuCalendarSearch } from "react-icons/lu";
import { HiOutlineLocationMarker } from "react-icons/hi";
import "leaflet/dist/leaflet.css";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./images/logo.svg";
import heatmapIcon from "./images/heatmap.svg";
import {
  Button,
  Checkbox,
  Divider,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
} from "antd";
import LayerSwitcher from "./LayerSwitcher";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import Legend from "./Legend";
import SearchBarMarker from "./SearchBarMarker";
import VectorData from "./VectorData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style/custom.css";
import { area_dropdownd } from "./map_pages/Constants";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartColumn,
  faLocation,
  faSignIn,
  faSignOut,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import DataModal from "./components/DataModal";
import PopupModal from "./PopupModal";
import { GoogleAPIKey, TokenKey } from "./helpers/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoginNew from "./LoginNew";
import SelectedLocations from "./SelectedLocations";
import SelectedLocationDistance from "./SelectedLocationDistance";
import MobileViewAlert from "./MobileViewAlert";
import Header from "./components/Header";
import { BASE_API, CallGETAPI, CallPOSTAPI } from "./helpers/API";
import { useDispatch } from "react-redux";
import {
  addDistanceLocation,
  handleAddPoiLocations,
  handleAddSelectedLocations,
  handleAddSelectedProjects,
  handleRemoveAllLocations,
  handleRemoveAllPoiLocations,
  handleRemovePoiLocations,
} from "./redux/slices/LocationProjectSlice";
import axios from "axios";
import AllLocations from "./AllLocations";
// import Spiderfy from "./Spiderfy";
// import MarkerClusterGroup from "react-leaflet-markercluster";
// import Marker from "./Marker";
import MarkerClusterGroup from "react-leaflet-cluster";
import GroupedLocations from "./GroupedLocations";
import UngroupedLocations from "./UngroupedLocations";
import { MdIosShare, MdOutlineMyLocation } from "react-icons/md";
import PoiMarker from "./PoiMarker";
import { RiDeleteBinLine, RiSearchLine } from "react-icons/ri";
import { FiChevronDown, FiChevronLeft, FiEdit } from "react-icons/fi";

import { Slider, Box, styled } from "@material-ui/core";
import L from "leaflet";
import Test2DragDrop from "./components/dragDropComp/Test2DragDrop";
import ProjectList from "./components/list/ProjectList";
import AddProjectModal from "./components/modals/AddProjectModal";
import { AiOutlinePlusCircle } from "react-icons/ai";
import SubscriptionModal from "./components/modals/SubscriptionModal";
import { handleSubscription } from "./redux/slices/subscriptionSlice";
import PaymentModal from "./components/modals/PaymentModal";
import ProfileModal from "./components/modals/ProfileModal";
import AutoCompleteInput from "./components/AutocompleteInput/AutoCompleteInput";

// import Box from "@mui/material/Box";
// import Slider from "@mui/material/Slider";

const placesLibrary = ["places"];
const numberFormatter = Intl.NumberFormat("en-US");

const ICon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
    />
  </svg>
);

const CustomToast = ({ text }) => (
  <div>
    <p className="text">{text}</p>
    <button className="button1" onClick={() => toast.dismiss()}>
      Dismiss
    </button>
  </div>
);

// let optionsAdmin = ;
const st = {
  display: "flex",
  justifyContent: "center",
};

const optionsPopu = [
  {
    value: "population",
    label: "All",
  },
];

const optionsGender = [
  {
    value: "genderm",
    label: "Male",
  },
  {
    value: "genderf",
    label: "Female",
  },
];

const optionsAge = [
  {
    value: "Age0to4",
    label: "Age 0 - 4 years",
  },
  {
    value: "Age5to9",
    label: "Age 5 - 9 years",
  },
  {
    value: "Age10to14",
    label: "Age 10 - 14 years",
  },
  {
    value: "Age15to19",
    label: "Age 15 - 19 years",
  },
  {
    value: "Age20to24",
    label: "Age 20 - 24 years",
  },
  {
    value: "Age25to29",
    label: "Age 25 - 29 years",
  },
  {
    value: "Age30to34",
    label: "Age 30 - 34 years",
  },
  {
    value: "Age35to39",
    label: "Age 35 - 39 years",
  },
  {
    value: "Age40to44",
    label: "Age 40 - 44 years",
  },
  {
    value: "Age45to49",
    label: "Age 45 - 49 years",
  },
  {
    value: "Age50to54",
    label: "Age 50 - 54 years",
  },
  {
    value: "Age55to59",
    label: "Age 55 - 59 years",
  },
  {
    value: "Age60to64",
    label: "Age 60 - 64 years",
  },
  {
    value: "Age65to69",
    label: "Age 65 - 69 years",
  },
  {
    value: "Age70to74",
    label: "Age 70 - 74 years",
  },
  {
    value: "Age75to79",
    label: "Age 75 - 79 years",
  },
  {
    value: "Age80to84",
    label: "Age 80 - 84 years",
  },
  {
    value: "Age85Plus",
    label: "Age 85 years Plus",
  },
];

const dataSetList = [
  {
    value: "population",
    label: "Population",
  },
  {
    value: "gender",
    label: "Gender",
  },
  {
    value: "age",
    label: "Age",
  },
  {
    value: "income",
    label: "Income",
  },
  {
    value: "people",
    label: "Households and Size",
  },
  {
    value: "religion",
    label: "Religion",
  },
];

const optionsIncome = [
  {
    value: "total_annual_income",
    label: "Total Annual Income",
  },
  {
    value: "net_annual_income_before_housing_costs",
    label: "Net Annual Income Before Housing Costs",
  },
  {
    value: "net_annual_income_after_housing_costs",
    label: "Net Annual Income After Housing Costs",
  },
  {
    value: "net_annual_income",
    label: "Net Annual Income",
  },
];

const optionsPeople = [
  {
    value: "total_households",
    label: "Total Households",
  },
  {
    value: "people0",
    label: "0 Person Households",
  },
  {
    value: "person1",
    label: "1 Person Households",
  },
  {
    value: "person2",
    label: "2 Person Households",
  },
  {
    value: "person3",
    label: "3 Person Households",
  },
  {
    value: "person4",
    label: "4 Person Households",
  },
  {
    value: "person5",
    label: "5 Person Households",
  },
  {
    value: "person6",
    label: "6 Person Households",
  },
  {
    value: "person7",
    label: "7 Person Households",
  },
  {
    value: "person8ormore",
    label: "8+ Person Households",
  },
];

const optionsReligion = [
  {
    value: "christian",
    label: "Christian",
  },
  {
    value: "hindu",
    label: "Hindu",
  },
  {
    value: "jewish",
    label: "Jewish",
  },
  {
    value: "muslim",
    label: "Muslim",
  },
  {
    value: "sikh",
    label: "Sikh",
  },
  {
    value: "buddhist",
    label: "Buddhist",
  },
  {
    value: "no_religion",
    label: "No Religion",
  },
  {
    value: "other_religion",
    label: "Other Religion",
  },
];

function MapWrapper() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("MapozonToken");
  const { user, loginWithRedirect, isAuthenticated, isLoading, logout } =
    useAuth0();
  const userId = user?.sub?.split("|")[1];

  const reduxLoading = useSelector(
    (state) => state?.LocationProject?.reduxLoading
  );

  const [showPOI, setShowPOI] = useState(false);
  const [poiLoading, setPoiLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setLoginModal(true);
    } else {
      setLoginModal(false);
    }
  }, [isLoading]);
  // useEffect(() => {
  // 	if (token || isAuthenticated) {
  // 		localStorage.setItem("MapozonToken", user?.sub)
  // 	}
  // 	else {
  // 		localStorage.removeItem("MapozonToken")
  // 	}
  // }, [isAuthenticated])

  const logoutUser = () => {
    localStorage.removeItem("MapozonToken");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  const mapRef = useRef();
  const OldMapSlice = useSelector((state) => state.OldMapSlice);
  const [optionsAdmin, setoptionsAdmin] = useState(area_dropdownd);
  const [value, setValue] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState([53.0781, -1.036]);
  const [searched, setSearched] = useState("");
  const [selectedCat, setselectedCat] = useState(null);
  const [subCatOpts, setsubCatOpts] = useState(null);
  const [reset, setreset] = useState(false);
  const [changed, setChanged] = useState(null);
  const [searchResult, setSearchResult] = useState("Result: none");
  const [hoverOne, sethoverOne] = useState(null);
  const [hoverTwo, sethoverTwo] = useState(null);
  const [hoverPopu, sethoverPopu] = useState(null);
  const [changeStyle, setchangeStyle] = useState(null);
  const [defaultCatVal, setdefaultCatVal] = useState(null);
  const [defaultSubCatVal, setdefaultSubCatVal] = useState("actual");
  const [msoaselected, setmsoaselected] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [showHeatmap, setshowHeatmap] = useState(true);
  const [mainSelect, setmainSelect] = useState(null);
  const [visible, setVisible] = useState(true);
  const [activeBtn, setactiveBtn] = useState(1);
  const [loading, setLoading] = useState(false);
  const [msoaEstab, setmsoaEstab] = useState(null);
  const [show, setShow] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [mapLoaction, setMapLocation] = useState({
    color: "",
    radius: 0,
    address: "",
  });
  const [polygon, setPolygon] = useState({
    properties: {},
    coordinates: [],
  });

  const [categoryFilter, setCategoryFilter] = useState([]);

  const headerStyle = {
    // color: "#fff",
    height: 64,
    paddingInline: 0,
    lineHeight: "64px",
    // textAlign: "right",
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GoogleAPIKey,
    libraries: placesLibrary,
  });

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged(e) {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      if (!place.geometry || !place.geometry.location) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      // setMapLocation({
      // 	color: "",
      // 	radius: 0
      // })

      setLocationinput({
        lat: place?.geometry?.location?.lat(),
        long: place?.geometry?.location?.lng(),
        name: place?.name,
      });

      setSearched(place);
    } else {
      alert("Please enter text");
    }
  }

  function inputChanged(e) {
    setSearched(null);
    if (e.target.value) setsearchValue(e.target.value);
  }

  const selectedArea = useCallback((e) => {
    if (optionsAdmin.length > 2) return;
    var option;
    setLoading(true);
    e.properties.cat === "regions"
      ? (option = e.properties.rgn21nm)
      : (option = e.properties.ctyua21nm);
    setValue(option);
    setoptionsAdmin([
      {
        value: option,
        name: option,
        disabled: true,
      },
      ...optionsAdmin,
    ]);
    setLoading(false);
  });

  const featureHighlighted = (e) => {
    setreset(false);
    let prop = e.properties;

    // console.log({ prop });

    let hoverOneVal;
    if (defaultSubCatVal === "actual") {
      if (defaultCatVal === "population") hoverOneVal = prop.population;
      if (defaultCatVal === "genderm") hoverOneVal = prop.genderm;
      if (defaultCatVal === "genderf") hoverOneVal = prop.genderf;
      if (defaultCatVal === "Age0to4") hoverOneVal = prop.age0to4;
      if (defaultCatVal === "Age5to9") hoverOneVal = prop.age5to9;
      if (defaultCatVal === "Age10to14") hoverOneVal = prop.age10to14;
      if (defaultCatVal === "Age15to19") hoverOneVal = prop.age15to19;
      if (defaultCatVal === "Age20to24") hoverOneVal = prop.age20to24;
      if (defaultCatVal === "Age25to29") hoverOneVal = prop.age25to29;
      if (defaultCatVal === "Age30to34") hoverOneVal = prop.age30to34;
      if (defaultCatVal === "Age35to39") hoverOneVal = prop.age35to39;
      if (defaultCatVal === "Age40to44") hoverOneVal = prop.age40to44;
      if (defaultCatVal === "Age45to49") hoverOneVal = prop.age45to49;
      if (defaultCatVal === "Age50to54") hoverOneVal = prop.age50to54;
      if (defaultCatVal === "Age55to59") hoverOneVal = prop.age55to59;
      if (defaultCatVal === "Age60to64") hoverOneVal = prop.age60to64;
      if (defaultCatVal === "Age65to69") hoverOneVal = prop.age65to69;
      if (defaultCatVal === "Age70to74") hoverOneVal = prop.age70to74;
      if (defaultCatVal === "Age75to79") hoverOneVal = prop.age75to79;
      if (defaultCatVal === "Age80to84") hoverOneVal = prop.age80to84;
      if (defaultCatVal === "Age85Plus") hoverOneVal = prop.age85plus;

      if (defaultCatVal === "hindu") hoverOneVal = prop.hindu;
      if (defaultCatVal === "jewish") hoverOneVal = prop.jewish;
      if (defaultCatVal === "muslim") hoverOneVal = prop.muslim;
      if (defaultCatVal === "sikh") hoverOneVal = prop.sikh;
      if (defaultCatVal === "christian") hoverOneVal = prop.christian;
      if (defaultCatVal === "buddhist") hoverOneVal = prop.buddhist;
      if (defaultCatVal === "no_religion") hoverOneVal = prop.no_religion;
      if (defaultCatVal === "other_religion") hoverOneVal = prop.other_religion;
      if (defaultCatVal === "people0") hoverOneVal = prop.people0;
      if (defaultCatVal === "person1") hoverOneVal = prop.person1;
      if (defaultCatVal === "person2") hoverOneVal = prop.person2;
      if (defaultCatVal === "person3") hoverOneVal = prop.person3;
      if (defaultCatVal === "person4") hoverOneVal = prop.person4;
      if (defaultCatVal === "person5") hoverOneVal = prop.person5;
      if (defaultCatVal === "person6") hoverOneVal = prop.person6;
      if (defaultCatVal === "person7") hoverOneVal = prop.person7;
      if (defaultCatVal === "person8") hoverOneVal = prop.person8;
      if (defaultCatVal === "person8ormore") hoverOneVal = prop.person8ormore;
      if (defaultCatVal === "total_households")
        hoverOneVal = prop.total_households;
      if (defaultCatVal === "net_annual_income")
        hoverOneVal = prop.net_annual_income;
      if (defaultCatVal === "net_annual_income_after_housing_costs")
        hoverOneVal = prop.net_annual_income_after_housing_costs;
      if (defaultCatVal === "net_annual_income_before_housing_costs")
        hoverOneVal = prop.net_annual_income_before_housing_costs;
      if (defaultCatVal === "total_annual_income")
        hoverOneVal = prop.total_annual_income;
    }
    if (defaultSubCatVal === "density") {
      if (defaultCatVal === "population") hoverOneVal = prop.dpopu;
      if (defaultCatVal === "genderm") hoverOneVal = prop.dgm;
      if (defaultCatVal === "genderf") hoverOneVal = prop.dgf;
      if (defaultCatVal === "Age0to4") hoverOneVal = prop.da0to4;
      if (defaultCatVal === "Age5to9") hoverOneVal = prop.da5to9;
      if (defaultCatVal === "Age10to14") hoverOneVal = prop.da10to14;
      if (defaultCatVal === "Age15to19") hoverOneVal = prop.da15to19;
      if (defaultCatVal === "Age20to24") hoverOneVal = prop.da20to24;
      if (defaultCatVal === "Age25to29") hoverOneVal = prop.da25to29;
      if (defaultCatVal === "Age30to34") hoverOneVal = prop.da30to34;
      if (defaultCatVal === "Age35to39") hoverOneVal = prop.da35to39;
      if (defaultCatVal === "Age40to44") hoverOneVal = prop.da40to44;
      if (defaultCatVal === "Age45to49") hoverOneVal = prop.da45to49;
      if (defaultCatVal === "Age50to54") hoverOneVal = prop.da50to54;
      if (defaultCatVal === "Age55to59") hoverOneVal = prop.da55to59;
      if (defaultCatVal === "Age60to64") hoverOneVal = prop.da60to64;
      if (defaultCatVal === "Age65to69") hoverOneVal = prop.da65to69;
      if (defaultCatVal === "Age70to74") hoverOneVal = prop.da70to74;
      if (defaultCatVal === "Age75to79") hoverOneVal = prop.da75to79;
      if (defaultCatVal === "Age80to84") hoverOneVal = prop.da80to84;
      if (defaultCatVal === "Age85Plus") hoverOneVal = prop.da85plus;

      if (defaultCatVal === "hindu") hoverOneVal = prop.dhindu;
      if (defaultCatVal === "jewish") hoverOneVal = prop.djewish;
      if (defaultCatVal === "muslim") hoverOneVal = prop.dmuslim;
      if (defaultCatVal === "sikh") hoverOneVal = prop.dsikh;
      if (defaultCatVal === "christian") hoverOneVal = prop.dchristian;
      if (defaultCatVal === "buddhist") hoverOneVal = prop.dbuddhist;
      if (defaultCatVal === "no_religion") hoverOneVal = prop.dno_religion;
      if (defaultCatVal === "other_religion")
        hoverOneVal = prop.dother_religion;
      if (defaultCatVal === "people0") hoverOneVal = prop.dpeople0;
      if (defaultCatVal === "person1") hoverOneVal = prop.dperson1;
      if (defaultCatVal === "person2") hoverOneVal = prop.dperson2;
      if (defaultCatVal === "person3") hoverOneVal = prop.dperson3;
      if (defaultCatVal === "person4") hoverOneVal = prop.person4;
      if (defaultCatVal === "person5") hoverOneVal = prop.dperson5;
      if (defaultCatVal === "person6") hoverOneVal = prop.dperson6;
      if (defaultCatVal === "person7") hoverOneVal = prop.dperson7;
      if (defaultCatVal === "person8") hoverOneVal = prop.dperson8;
      if (defaultCatVal === "person8ormore") hoverOneVal = prop.dperson8ormore;
      if (defaultCatVal === "total_households")
        hoverOneVal = prop.dtotal_households;
      if (defaultCatVal === "net_annual_income")
        hoverOneVal = prop.dnet_annual_income;
      if (defaultCatVal === "net_annual_income_after_housing_costs")
        hoverOneVal = prop.dnet_annual_income_after_housing_costs;
      if (defaultCatVal === "net_annual_income_before_housing_costs")
        hoverOneVal = prop.dnet_annual_income_before_housing_costs;
      if (defaultCatVal === "total_annual_income")
        hoverOneVal = prop.dtotal_annual_income;
    }
    let hoverOneFormatValue = numberFormatter.format(hoverOneVal);

    if (defaultSubCatVal === "density")
      hoverOneFormatValue = hoverOneVal.toFixed(2);

    switch (changed) {
      case "regions":
        sethoverOne(prop.rgn21nm);
        sethoverTwo(prop.rgn21cd);
        sethoverPopu(hoverOneFormatValue);
        break;
      case "counties":
        sethoverOne(prop.ctyua21nm);
        sethoverTwo(prop.ctyua21cd);
        sethoverPopu(hoverOneFormatValue);
        break;
      case "counties_msoa":
        sethoverOne(prop.ctyua21nm || prop.msoa21nm);
        sethoverTwo(prop.ctyua21cd || prop.msoa21cd);
        sethoverPopu(hoverOneFormatValue);
        break;
      case "msoa":
        if (prop?.msoa21nm) {
          sethoverOne(prop.msoa21nm);
          sethoverTwo(prop.msoa21cd);
          sethoverPopu(hoverOneFormatValue);
        } else {
          sethoverOne(prop.rgn21nm);
          sethoverTwo(prop.rgn21cd);
          sethoverPopu(hoverOneFormatValue);
        }
        break;
      default:
        break;
    }
  };

  const featureUnHighlighted = useCallback((e) => {
    sethoverOne(null);
    sethoverTwo(null);
    sethoverPopu(null);
    setreset(false);
  });

  function handleUnitChange(e) {
    // setLoading(true);
    toast.dismiss();

    console.log("e value", e);

    setreset(true);
    if (e === "msoa" || e === "counties_msoa") {
      toast.info("Access deeper insights by clicking on an area of interest", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        closeButton: false,
        hideProgressBar: false,
        closeOnClick: false,
        // borderRadius: "12px",
        theme: "success",
        className: "my-background",
        // bodyClassName: "grow-font-size",
        // progressClassName: "fancy-progress-bar",
        //   icon: false,
        icon: ICon,
      });
      // toast(<CustomToast  text="This is a custom toast" />);
      setValue(e);
      // setchangeStyle(null);
      // setmainSelect(null);
      // setselectedCat(null);
      setLoading(true);
      // sethoverOne(null);
      setChanged(e);
    } else if (e === "regions" || e === "counties") {
      setreset(true);
      setValue(e);
      toast.info("Select an area to see further detail", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
      });
      setLoading(true);
      sethoverOne(null);
      setChanged(e);
      setmsoaEstab(null);
    }
    setLoading(false);
  }

  function onChange(e) {
    // toast.dismiss();
    setmainSelect(e);
    if (!changed) {
      toast.warn("Please select an Area", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
      });
      setmainSelect(null);
      return;
    }
    setselectedCat(e.charAt(0).toUpperCase() + e.slice(1));
    setchangeStyle(e);
    setVisible(false);
    switch (e) {
      case "population":
        setsubCatOpts(optionsPopu);
        setdefaultCatVal(optionsPopu[0].value);
        break;
      case "gender":
        setsubCatOpts(optionsGender);
        setdefaultCatVal(optionsGender[0].value);
        break;
      case "age":
        setsubCatOpts(optionsAge);
        setdefaultCatVal(optionsAge[0].value);
        break;
      case "income":
        setsubCatOpts(optionsIncome);
        setdefaultCatVal(optionsIncome[0].value);
        break;
      case "people":
        setsubCatOpts(optionsPeople);
        setdefaultCatVal(optionsPeople[0].value);
        break;
      case "religion":
        setsubCatOpts(optionsReligion);
        setdefaultCatVal(optionsReligion[0].value);
        break;

      default:
        break;
    }
    setLoading(false);
  }

  function handleActualDensityChange(value) {
    if (!changed) {
      toast.warn("Please select an Area", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
      });
      setLoading(false);
      setmainSelect(null);
      return;
    }
    setdefaultSubCatVal(value);
    setLoading(false);
  }

  useEffect(() => {}, [OldMapSlice?.area_dropdownd]);

  const [openInfoModal, setInfoModal] = useState(false);
  const [showAdminDiv, setShowAdminDiv] = useState(false);

  const handleShowData = () => {
    setShowAdminDiv(!showAdminDiv);
    setShowPOI(false);
    setCategoryFilter([]);
    dispatch(handleRemoveAllPoiLocations());
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    toast.dismiss();
    dispatch(handleRemoveAllPoiLocations());
    setCategoryFilter([]);
    navigate("/location-page");
    // setShow(true);
  };

  const [multiPolygon, setMultiPolygon] = useState([]);
  const [selectedDistanceLocation, setSelectedDistanceLocation] = useState([]);
  // location checkbox selected ;
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);

  const [locationInput, setLocationinput] = useState({
    lat: "",
    long: "",
    name: "",
  });

  const [place, setPlace] = useState(null);

  const handleCategory = (e) => {
    const { value } = e.target;
    if (categoryFilter.includes(value)) {
      const newArray = categoryFilter.filter((item) => item !== value);
      setCategoryFilter(newArray);
    } else {
      setCategoryFilter([...categoryFilter, value]);
    }
  };

  const {
    selected_locations: radiusLocation,
    distance_location: distanceLocation,
    poi_locations: poiLocations,
    selectedProject,
  } = useSelector((state) => state?.LocationProject);
  /**.map(
        ({ geometry, properties, ...keepAttrs }) => keepAttrs
      ), */
  const handleSearch = async () => {
    setPoiLoading(true);

    let searchObject = {
      lat: place.geometry.location.lat(),
      long: place.geometry.location.lng(),
      name: place.formatted_address,
    };
    // const circleLocations = radiusLocation.filter((item) =>
    //   selectedProject.some((proj) => proj.id === item.projectId)
    // );
    // const polygonLocations = distanceLocation.filter((item) =>
    //   selectedProject.some((proj) => proj.id === item.projectId)
    // );

    // const allLocations = [...circleLocations, ...polygonLocations];

    const res = await CallPOSTAPI("get-point-of-interests", {
      category_filter: categoryFilter,
      // all_locations: allLocations,
      search_query: searchObject,
      distanceRadius: distanceVal,
    });

    if (res.data.status) {
      if (res.data.result.length === 0) {
        toast.error("No Locations Found.");
      }
      dispatch(handleRemoveAllPoiLocations());
      res.data.result.map((item, i) => {
        // if (!poiLocations.find((obj) => obj.osm_id === item.osm_id)) {
        dispatch(handleAddPoiLocations([item]));
        // }
      });
      setPoiLoading(false);
    } else {
      dispatch(handleRemoveAllPoiLocations());
      setPoiLoading(false);
    }
  };

  const autoCompletInputHandle = (val) => {
    setLocationinput(val);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const [filterValue, setFilterValue] = useState([0, 100]);
  const [filterMinValue, setFilterMinValue] = useState(0);
  const [filterMaxValue, setFilterMaxValue] = useState(100);

  const [minVal, setminVal] = useState(0);
  const [maxVal, setmaxVal] = useState(100);

  const handleFilterChange = (event, newValue) => {
    // console.log({ newValue });
    setminVal(newValue[0]);
    setmaxVal(newValue[1]);
    setFilterValue(newValue);
  };

  useEffect(() => {
    setFilterValue([
      filterMinValue ? parseInt(filterMinValue) : 0,
      filterMaxValue ? parseInt(filterMaxValue) : 100,
    ]);
  }, [filterMinValue, filterMaxValue]);

  const findArea = () => {
    setValue("msoa");
    setChanged("msoa");
  };

  const handleIndex = (index) => {
    setActiveIndex(index);
    if (index === 0) {
      setreset(false);
      setValue(null);
      setChanged(null);
      setmainSelect(null);
      setselectedCat(null);
      setchangeStyle(null);
      setVisible(true);
      setdefaultCatVal(null);
      setdefaultSubCatVal("actual");
    } else {
      // setActiveIndex(index);
      setreset(true);
      setValue("msoa");
      setChanged("msoa");
      setmainSelect(null);
      setselectedCat(null);
      setchangeStyle(null);
      setVisible(true);
      setdefaultCatVal(null);
      setdefaultSubCatVal("actual");
    }
  };

  const formatNumber = (number) => {
    if (number < 1000) {
      return number.toFixed(1);
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else {
      return (number / 1000000000).toFixed(1) + "B";
    }
  };

  const [searchParams] = useSearchParams();
  const { subscription_data } = useSelector((state) => state?.subscription);

  const [btnIndex, setBtnIndex] = useState(null);
  const [projectDiv, setProjectDiv] = useState(false);
  const [projectDetailDiv, setProjectDetailDiv] = useState(false);

  const [ProjectListData, setProjectListData] = useState([]);
  const [sharedProjectListData, setSharedProjectListData] = useState([]);
  const [activePrject, setActiveProject] = useState("");
  const [projectType, setProjectType] = useState("my_projects");

  const [showProjectModal, setShowProjectModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [subscriptionApi, setSubscriptionApi] = useState(false);
  const [profileModal, setProfileModal] = useState(false);

  const [distanceVal, setDistanceVal] = useState(5);

  useEffect(() => {
    if (searchParams) {
      setSessionId(searchParams.get("session_id"));
    }
  }, [searchParams]);

  const allProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  const SelectedProjects = useSelector(
    (state) => state?.LocationProject?.active_project
  );

  const onProjectLoad = async () => {
    setProjectListData([]);
    setSharedProjectListData([]);
    setActiveProject("");
    if (!userId) {
      return "";
    }
    const resilt = await CallGETAPI("getProjects/" + userId);

    if (resilt?.data?.status) {
      setProjectListData(resilt?.data?.projects || []);
      setSharedProjectListData(resilt?.data?.shared_projects || []);
      resilt?.data?.projects.map((item, i) => {
        if (item.project_checked1) {
          if (!allProjects.find((obj) => obj.id === item.id)) {
            dispatch(handleAddSelectedProjects([item]));
          }
        }
      });
      resilt?.data?.shared_projects.map((item, i) => {
        if (item.project_checked1) {
          if (!allProjects.find((obj) => obj.id === item.id)) {
            dispatch(handleAddSelectedProjects([item]));
          }
        }
      });
    }
  };
  useEffect(() => {
    if (userId) {
      onProjectLoad();
    }
  }, [userId]);

  const getUserData = async () => {
    try {
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      // console.log("user id", userId);
      const response = await axios.post(
        BASE_API + "get-user-by-userId",
        {
          user_id: userId,
        },
        { headers: headers }
      );

      if (response.data.status && response.data.user[0].session) {
        let user = response.data?.user[0];
        setSessionId(user.session);
      } else {
        setSubscriptionApi(true);
        console.log("error", response.data.error);
      }
    } catch (error) {
      setSubscriptionApi(true);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (user) {
      getUserData();
    }
  }, [user]);

  const handlePaymentSuccess = async () => {
    setLoading(true);
    try {
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      const response = await axios.post(
        BASE_API + "payment-successful",
        {
          session: sessionId,
          user_id: userId,
        },
        { headers: headers }
      );

      if (response.data.status) {
        setPaymentDetails(response.data.paymentDetails);
        setPaymentModal(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setSubscriptionModal(true);
      toast.error(error.response.data.error);
    }
  };

  useEffect(() => {
    if (user && sessionId) {
      handlePaymentSuccess();
    }
  }, [user, sessionId]);

  const getUserSubscription = async () => {
    setLoading(true);
    try {
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      const response = await axios.post(
        BASE_API + "get-user-subscription-status",
        {
          user_id: userId,
        },
        { headers: headers }
      );

      dispatch(handleSubscription(response.data.subscription));
      setLoading(false);
    } catch (error) {
      setSubscriptionModal(true);
      setLoading(false);
      // dispatch(handleSubscription({ planId: "sasdsasfadfdafsadafssdafasdf" }));
      // toast.error(error.response.data.error);
    }
  };

  useEffect(() => {
    if (user && !searchParams.get("session_id") && subscriptionApi) {
      getUserSubscription();
    }
  }, [user, searchParams, subscriptionApi]);

  const handleFormat = (value) => {
    console.log({ value });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value >= 0 && value <= 10) {
      setDistanceVal(event.target.value);
    } else {
      setDistanceVal(5);
    }
  };

  console.log({ place });

  const getSubscriptionStatus = (data) => {
    const currentDate = new Date();
    const subscriptionEndDate = new Date(data?.current_period_end * 1000);

    const comparison = subscriptionEndDate.getTime() - currentDate.getTime();

    if (
      comparison > 0 &&
      (data?.status === "active" || data?.status === "canceled")
    ) {
      return true;
    } else {
      return false;
    }
  };

  console.log({ loginModal });

  return (
    <>
      <LoginNew show={loginModal} setShow={setLoginModal} />

      <SubscriptionModal
        showModal={subscriptionModal}
        setShowModal={setSubscriptionModal}
      />

      <ProfileModal showModal={profileModal} setShowModal={setProfileModal} />

      <PaymentModal
        showModal={paymentModal}
        setShowModal={setPaymentModal}
        paymentDetails={paymentDetails}
        onClose={() => getUserSubscription()}
      />

      {isAuthenticated && getSubscriptionStatus() && <MobileViewAlert />}

      {/* <MobileViewAlert /> */}

      <Spin
        tip="Mapozone Loading..."
        spinning={loading || isLoading || reduxLoading || poiLoading}
        size="large"
      >
        <Container fluid>
          <Header
            setSearched={setSearched}
            openInfoModal={openInfoModal}
            setInfoModal={setInfoModal}
            setSubscriptionModal={setSubscriptionModal}
            setProfileModal={setProfileModal}
          />
          <Row>
            <MapContainer
              zoomControl={false}
              ref={mapRef}
              center={center}
              zoom={zoom}
              bounds={polygon.properties}
              className="map-container"
              maxZoom={18}
              // attributionControl={false}
            >
              {!visible && activeIndex === 0 && (
                <Legend activeIndex={activeIndex} />
              )}
              {changeStyle ? (
                <div className="heatMapSwitcher">
                  {/* <Space> */}
                  {/* <img
                      alt="heatmap logo"
                      src={"heatmap.svg"}
                      height={20}
                      width={20}
                    /> */}
                  <b>Heatmap</b>
                  <Switch
                    defaultChecked
                    onChange={() => {
                      setshowHeatmap(!showHeatmap);
                    }}
                    size="small"
                  />
                  {/* </Space> */}
                </div>
              ) : null}
              <LayerSwitcher />
              <ZoomControl position="bottomleft" />
              {/* { showHeatmap ? (
								<> */}
              <VectorData
                msoaEstablished={(e) => {
                  if (e) {
                    setmsoaEstab(e);
                  }
                }}
                selectedMsoa={msoaEstab}
                reset={reset}
                change={changed}
                featureHighlighted={featureHighlighted}
                featureUnHighlighted={featureUnHighlighted}
                updateStyle={changeStyle}
                updateCatVal={defaultCatVal}
                updateSubCatVal={defaultSubCatVal}
                selectedArea={selectedArea}
                loading={(val) => {
                  setLoading(!val);
                }}
                setLoading={setLoading}
                showHeatmap={showHeatmap}
                setoptionsAdmin={setoptionsAdmin}
                optionsAdmin={optionsAdmin}
                setValue={setValue}
                setreset={setreset}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                setFilterMinValue={setFilterMinValue}
                setFilterMaxValue={setFilterMaxValue}
                minVal={minVal}
                setminVal={setminVal}
                maxVal={maxVal}
                setmaxVal={setmaxVal}
                activeIndex={activeIndex}
              />

              <TileLayer
                // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {searched && (
                <SearchBarMarker
                  coordinates={polygon.coordinates}
                  properties={polygon.properties}
                  color={mapLoaction.color}
                  radius={mapLoaction.radius}
                  position={searched}
                  address={mapLoaction.address}
                />
              )}

              {/* <MarkerClusterGroup
                maxClusterRadius={1}
                // spiderfyOnMaxZoom={true}
                showCoverageOnHover={true}
                // spiderfyOnEveryZoom={true}
                polygonOptions={{
                  fillColor: "#ffffff",
                  color: "#f00800",
                  weight: 5,
                  opacity: 1,
                  fillOpacity: 0.8,
                }}
              > */}
              {/* <AllLocations /> */}
              {/* </MarkerClusterGroup> */}
              {/* <UngroupedLocations /> */}
              <PoiMarker />
              <GroupedLocations />
            </MapContainer>
            {hoverOne ? (
              <div className="hoverInfo">
                <Space direction="horizontal" size="small">
                  <span className="hover-heading-green">{hoverOne}</span>
                  <span className="hoverHeading">{hoverTwo}</span>
                  {defaultCatVal ? (
                    <>
                      <span className="hover-heading-green" style={st}>
                        {selectedCat === "Income" ? <>&#163;</> : "People"}
                        {defaultSubCatVal === "density" ? (
                          <>
                            / Km <sub>2</sub>
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                      <span className="hoverHeading">{hoverPopu}</span>
                    </>
                  ) : null}
                </Space>
              </div>
            ) : (
              ""
            )}
            {subscription_data && getSubscriptionStatus(subscription_data) && (
              <div className="left-side-div">
                <button
                  className={`${
                    btnIndex === 0 ? "location-btn1-active" : "location-btn1"
                  }`}
                  onClick={() => {
                    // handleShow();
                    setProjectDiv(!projectDiv);
                    setBtnIndex(0);
                    setShowPOI(false);
                    setShowAdminDiv(false);
                    setProjectDetailDiv(false);
                  }}
                >
                  <HiOutlineLocationMarker size={15} />
                  <span>MY LOCATIONS</span>
                </button>

                <button
                  className={`${
                    btnIndex === 1 ? "location-btn1-active" : "location-btn1"
                  }`}
                  onClick={() => {
                    setShowPOI(!showPOI);
                    setShowAdminDiv(false);
                    setProjectDiv(false);
                    setProjectDetailDiv(false);
                    setBtnIndex(1);
                  }}
                >
                  <MdOutlineMyLocation size={15} />
                  <span>POINTS OF INTEREST</span>
                </button>

                <button
                  className={`${
                    btnIndex === 2 ? "location-btn1-active" : "location-btn1"
                  }`}
                  onClick={() => {
                    handleShowData();
                    setProjectDiv(false);
                    setProjectDetailDiv(false);
                    setBtnIndex(2);
                  }}
                >
                  <AiOutlinePlusCircle size={15} />
                  <span>ADD DATA TO MAP</span>
                </button>
              </div>
            )}

            {showPOI && (
              <div className="add-data-map">
                <h1 className="main-heading">Point of Interest</h1>

                <div className="my-3">
                  <AutoCompleteInput
                    setPlace={setPlace}
                    handleValue={autoCompletInputHandle}
                  />
                </div>

                <div className="volume-container">
                  <div className="" style={{ flexGrow: 1 }}>
                    <input
                      type="range"
                      min={0}
                      max={10}
                      step={0.1}
                      defaultValue={5}
                      value={distanceVal}
                      onChange={handleChange}
                      // className="volume-slider"
                      style={{
                        height: "0.3rem",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <span
                    className="d-flex align-items-center position-relative"
                    style={{ fontSize: "10px" }}
                  >
                    <input
                      style={{ height: "auto", fontSize: "10px" }}
                      type="number"
                      value={distanceVal}
                      min={0}
                      max={10}
                      step={0.1}
                      onChange={handleChange}
                      className="modal-custom-input form-control "
                    />
                    <b className="position-absolute" style={{ right: "12px" }}>
                      Km
                    </b>
                  </span>
                </div>

                {/* <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                  <div className="my-3 position-relative">
                    <RiSearchLine
                      size={25}
                      color="#71d622"
                      className="position-absolute"
                      style={{ top: "10px", right: "15px" }}
                    />
                    <input
                      id="searchTxt"
                      value={searchValue}
                      onChange={inputChanged}
                      type="search"
                      className="modal-custom-input w-100"
                      placeholder="Type to search"
                      style={{
                        border: "1px solid #e7e7e7",
                        height: "45px",
                        alignItems: "center",
                        paddingRight: "50px",
                        paddingLeft: "10px",
                      }}
                      onInput={() => setsearchValue(null)}
                    />
                  </div>
                </Autocomplete> */}
                <p className="m-0 hoverHeading mt-2">Filter by Category</p>
                <div className="mt-2 d-flex flex-column gap-1 poi-location-text">
                  <Checkbox
                    value={"restaurant"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("restaurant")}
                    className="poi-location-text"
                  >
                    Restaurant
                  </Checkbox>
                  <Checkbox
                    value={"fast_food"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("fast_food")}
                    className="poi-location-text"
                  >
                    Fast Food
                  </Checkbox>
                  <Checkbox
                    value={"pub"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("pub")}
                    className="poi-location-text"
                  >
                    Pub
                  </Checkbox>
                  <Checkbox
                    value={"cafe"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("cafe")}
                    className="poi-location-text"
                  >
                    Cafe
                  </Checkbox>
                  <Checkbox
                    value={"parking"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("parking")}
                    className="poi-location-text"
                  >
                    Parking
                  </Checkbox>
                  <Checkbox
                    value={"school"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("school")}
                    className="poi-location-text"
                  >
                    School
                  </Checkbox>
                  <Checkbox
                    value={"hospital"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("hospital")}
                    className="poi-location-text"
                  >
                    Hospital
                  </Checkbox>
                  <Checkbox
                    value={"college"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("college")}
                    className="poi-location-text"
                  >
                    College
                  </Checkbox>
                  <Checkbox
                    value={"university"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("university")}
                    className="poi-location-text"
                  >
                    University
                  </Checkbox>
                  <Checkbox
                    value={"shops"}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    checked={categoryFilter.includes("shops")}
                    className="poi-location-text"
                  >
                    Shops
                  </Checkbox>
                </div>
                <div className="mt-3">
                  <button
                    className="search-btn"
                    type="button"
                    onClick={handleSearch}
                    disabled={poiLoading || (!place && !categoryFilter.length)}
                  >
                    {poiLoading ? "Loading..." : "Search"}
                  </button>
                </div>
              </div>
            )}
            {showAdminDiv && (
              <div className="add-data-map">
                <h1 className="main-heading">Add data to map</h1>
                <div
                  className="d-flex justify-content-center align-items-center mt-3"
                  role="button"
                >
                  <div
                    className={`tab-left ${
                      activeIndex === 0 ? "green-bg" : ""
                    }`}
                    onClick={() => {
                      handleIndex(0);
                    }}
                  >
                    <span
                      style={{
                        fontSize: "10px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      Compare Areas
                    </span>
                  </div>
                  <div
                    className={`tab-right ${
                      activeIndex === 1 ? "green-bg" : ""
                    }`}
                    onClick={() => {
                      handleIndex(1);
                    }}
                  >
                    <span
                      style={{
                        fontSize: "10px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      Find Areas
                    </span>
                  </div>
                </div>
                {activeIndex === 0 && (
                  <Space direction="vertical" className="w-100 gap-3 mt-4">
                    <div className="">
                      <span className="hoverHeading">AREA</span>
                      <Select
                        value={value}
                        options={optionsAdmin}
                        className="select-default mt-2"
                        onChange={handleUnitChange}
                        placeholder="Select an Area"
                        style={{ minWidth: "100%" }}
                      />
                    </div>

                    <div>
                      <span className="hoverHeading">DATASET</span>
                      <Select
                        placeholder="Demographics"
                        className="select-default mt-2"
                        onChange={onChange}
                        value={mainSelect}
                        options={dataSetList}
                        style={{ minWidth: "100%" }}
                      />
                    </div>

                    <div>
                      <span className="hoverHeading">CATEGORY</span>

                      <Select
                        key={defaultSubCatVal}
                        value={defaultCatVal}
                        options={subCatOpts}
                        className="select-default mt-2"
                        style={{ minWidth: "100%" }}
                        onChange={setdefaultCatVal}
                      />
                    </div>

                    <div>
                      <div className="hoverHeading">SHOW</div>
                      <Radio.Group
                        onChange={(e) => {
                          handleActualDensityChange(e.target.value);
                        }}
                        defaultValue={defaultSubCatVal}
                        className="d-flex align-items-center mt-2"
                        size="large"
                      >
                        <Radio
                          value="actual"
                          checked
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          Actual
                        </Radio>
                        {mainSelect !== "income" && (
                          <Radio
                            value="density"
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Density
                          </Radio>
                        )}
                      </Radio.Group>
                    </div>
                  </Space>
                )}

                {activeIndex === 1 && (
                  <Space direction="vertical" className="w-100 gap-3 mt-4">
                    <div>
                      <span className="hoverHeading text-uppercase">
                        Target Audience
                      </span>
                      <Select
                        placeholder="Demographics"
                        onChange={onChange}
                        value={mainSelect}
                        options={dataSetList}
                        className="select-default mt-2 w-100"
                      />
                    </div>

                    <div>
                      <span className="hoverHeading">CATEGORY</span>
                      <Select
                        key={defaultSubCatVal}
                        value={defaultCatVal}
                        options={subCatOpts}
                        className="select-default mt-2 w-100"
                        onChange={setdefaultCatVal}
                      />
                    </div>
                    <div>
                      <span className="hoverHeading">SHOW</span>
                      <Radio.Group
                        onChange={(e) => {
                          handleActualDensityChange(e.target.value);
                        }}
                        className="d-flex align-items-center mt-2"
                        defaultValue={defaultSubCatVal}
                      >
                        <Radio
                          value="actual"
                          checked
                          style={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          Actual
                        </Radio>
                        {mainSelect !== "income" && (
                          <Radio
                            value="density"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Density
                          </Radio>
                        )}
                      </Radio.Group>
                    </div>

                    <div>
                      <span className="hoverHeading text-uppercase">
                        Filter
                      </span>
                      <Box
                        className=""
                        style={{
                          width: "95%",
                          margin: "auto",
                          marginTop: "10px",
                        }}
                      >
                        <Slider
                          // getAriaLabel={() => 'Temperature range'}
                          value={filterValue}
                          onChange={handleFilterChange}
                          valueLabelDisplay="auto"
                          // valueLabelFormat={handleFormat}
                          // getAriaValueText={"filterMinValue"}
                          valueLabelFormat={formatNumber}
                          // aria-label="Default"
                          step={10}
                          // min={minVal}
                          min={filterMinValue}
                          // max={maxVal}
                          max={filterMaxValue}
                          // marks={[
                          //   {
                          //     value: filterMinValue,
                          //     label: formatNumber(filterMinValue),
                          //     // label: `${filterMinValue} Min`,
                          //   },
                          //   {
                          //     value: filterMaxValue,
                          //     label: formatNumber(filterMaxValue),
                          //     // label: `${filterMaxValue} Max`,
                          //   },
                          // ]}
                        />
                      </Box>
                    </div>
                  </Space>
                )}
              </div>
            )}
            {projectDiv && (
              <div className="add-data-map">
                {/* <div className="d-flex align-items-center gap-2">
                  <div className="flex-grow-1">
                    <button className="search-btn">My Projects</button>
                    <FiChevronDown className="chevron-down" />
                  </div>
                  <div className="flex-grow-1">
                    <button className="hide-btn">Hide</button>
                    <FiChevronLeft className="chevron-left" />
                  </div>
                </div> */}
                <div
                  className="d-flex justify-content-center align-items-center mt-1"
                  role="button"
                >
                  <div
                    className={`tab-left ${
                      projectType === "my_projects" ? "green-bg" : ""
                    }`}
                    onClick={() => {
                      setProjectType("my_projects");
                    }}
                  >
                    <span
                      style={{
                        fontSize: "10px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      My Projects
                    </span>
                  </div>
                  <div
                    className={`tab-right ${
                      projectType === "shared_projects" ? "green-bg" : ""
                    }`}
                    onClick={() => {
                      setProjectType("shared_projects");
                    }}
                  >
                    <span
                      style={{
                        fontSize: "10px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                      // className="main-heading"
                    >
                      Shared Projects
                    </span>
                  </div>
                </div>
                {projectType === "my_projects" && (
                  <div className="" style={{ width: "95%", margin: "auto" }}>
                    {ProjectListData &&
                      ProjectListData?.map((item, i) => {
                        return (
                          <ProjectList
                            key={`proj${i}`}
                            title={item?.project_name}
                            id={item?.id}
                            user_id={item?.user_id}
                            setActiveProject={setActiveProject}
                            activePrject={activePrject}
                            item={item}
                            is_selected={SelectedProjects?.id === item?.id}
                            refreashList={onProjectLoad}
                            projectDetailDiv={projectDetailDiv}
                            setProjectDetailDiv={setProjectDetailDiv}
                            projectType=""
                          />
                        );
                      })}
                  </div>
                )}

                {projectType === "shared_projects" && (
                  <div
                    className="mt-4"
                    style={{ width: "95%", margin: "auto" }}
                  >
                    {sharedProjectListData &&
                      sharedProjectListData?.map((item, i) => {
                        return (
                          <ProjectList
                            key={`proj${i}`}
                            title={item?.project_name}
                            id={item?.id}
                            user_id={item?.user_id}
                            setActiveProject={setActiveProject}
                            activePrject={activePrject}
                            item={item}
                            is_selected={SelectedProjects?.id === item?.id}
                            refreashList={onProjectLoad}
                            projectDetailDiv={projectDetailDiv}
                            setProjectDetailDiv={setProjectDetailDiv}
                            projectType="shared"
                          />
                        );
                      })}
                  </div>
                )}

                {/* <div className="mt-3 d-flex flex-column">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <Checkbox
                          // value={"restaurant"}
                          // onChange={(e) => {
                          //   handleCategory(e);
                          // }}
                          // checked={categoryFilter.includes("restaurant")}
                          className="main-heading"
                        />
                        <span
                          className="main-heading"
                          onClick={() => setProjectDetailDiv(!projectDetailDiv)}
                        >
                          Project 1
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-center gap-1 primary-icon">
                        <RiDeleteBinLine className="" />
                        <MdIosShare />
                        <FiEdit />
                      </div>
                    </div>
                    <hr />
                  </div>
                </div> */}

                <div className="mt-3">
                  <button
                    className="search-btn"
                    type="button"
                    onClick={() => setShowProjectModal(true)}
                    // onClick={handleSearch}
                  >
                    Add New Project
                  </button>
                </div>
              </div>
            )}

            {projectDetailDiv && (
              <div className="project-detail-div">
                <div className="mt-1 d-flex flex-column">
                  <Test2DragDrop
                    setUpdateLoading={setLoading}
                    updateLoading={loading}
                    setProjectDetailDiv={setProjectDetailDiv}
                  />
                </div>
              </div>

              // <div className="project-detail-div">
              //   <div className="mt-1 d-flex flex-column">
              //     <div>
              //       <div className="d-flex justify-content-between align-items-center">
              //         <div className="">
              //           <span
              //             className="main-heading"
              //             onClick={() => setProjectDetailDiv(!projectDetailDiv)}
              //           >
              //             Project 1
              //           </span>
              //         </div>
              //         <div className="d-flex align-items-center justify-content-center gap-3">
              //           <div className="d-flex align-items-center justify-content-center gap-1">
              //             <RiDeleteBinLine />
              //             <MdIosShare />
              //             <FiEdit />
              //           </div>
              //           <div>
              //             <button className="search-btn px-2" type="button">
              //               Add New List
              //             </button>
              //           </div>
              //         </div>
              //       </div>
              //       <hr />
              //     </div>
              //   </div>
              //   <div className="mt-1">
              //     <div className="project-list-div d-flex flex-column justify-content-between">
              //       <div>
              //         <div className="d-flex justify-content-between align-items-center py-2 px-2 bg-primary">
              //           <div className="">
              //             <span className="main-heading">List 1</span>
              //           </div>

              //           <div className="d-flex align-items-center justify-content-center gap-1">
              //             <RiDeleteBinLine size={20} />
              //             <MdIosShare size={20} />
              //             <FiEdit size={20} />
              //           </div>
              //         </div>
              //         <div className="mt-3 px-2">
              //           <div>
              //             <div className="d-flex justify-content-between align-items-center">
              //               <div className="">
              //                 <span className="main-heading">Location 1</span>
              //               </div>

              //               <div className="d-flex align-items-center justify-content-center gap-1">
              //                 <RiDeleteBinLine size={20} />
              //                 <MdIosShare size={20} />
              //                 <FiEdit size={20} />
              //               </div>
              //             </div>
              //             <hr />
              //           </div>
              //           <div>
              //             <div className="d-flex justify-content-between align-items-center">
              //               <div className="">
              //                 <span className="main-heading">Location 2</span>
              //               </div>

              //               <div className="d-flex align-items-center justify-content-center gap-1">
              //                 <RiDeleteBinLine size={20} />
              //                 <MdIosShare size={20} />
              //                 <FiEdit size={20} />
              //               </div>
              //             </div>
              //             <hr />
              //           </div>
              //         </div>
              //       </div>
              //       <div className="px-2 pb-2">
              //         <button className="search-btn">Add New Location</button>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            )}
          </Row>
        </Container>
      </Spin>
      <div className="modal-content">
        {" "}
        <DataModal isModalOpen={openInfoModal} setIsModalOpen={setInfoModal} />
        <AddProjectModal
          // addProject={addProject}
          isPopupOpen={showProjectModal}
          handleCancel={setShowProjectModal}
          fetchProjects={onProjectLoad}
        />
      </div>
    </>
  );
}

export default MapWrapper;
