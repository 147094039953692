import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { TokenKey } from "./helpers/Constants";
import axios from "axios";
import { BASE_API } from "./helpers/API";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PaymentSuccess = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserData = async () => {
    try {
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      console.log("user id", userId);
      const response = await axios.post(
        BASE_API + "get-user-by-userId",
        {
          user_id: userId,
        },
        { headers: headers }
      );

      if (response.data.status) {
        // console.log("user data", response.data.user);
        let user = response.data?.user[0];
        setSessionId(user.session);
        // setLoading(false);
      } else {
        // setLoading(false);
        console.log("error", response.data.error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (user) {
      getUserData();
    }
  }, [user]);

  const handlePaymentSuccess = async () => {
    try {
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      const response = await axios.post(
        BASE_API + "payment-successful",
        {
          session: sessionId,
          user_id: userId,
        },
        { headers: headers }
      );

      if (response.data.status) {
        // window.location = response.data.session.url;
        setLoading(false);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (sessionId) {
      handlePaymentSuccess();
    }
  }, [sessionId]);
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Spin tip="Mapozone Loading..." spinning={loading} size="large">
        <button className="search-btn px-3" onClick={() => navigate("/")}>
          Proceed To Home
        </button>
      </Spin>
    </div>
  );
};

export default PaymentSuccess;
