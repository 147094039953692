import { toast } from "react-toastify";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CallPOSTAPI } from "../../helpers/API";
import { useAuth0 } from "@auth0/auth0-react";

function AddProjectModal({ isPopupOpen, handleCancel, fetchProjects }) {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState("");

  const { user } = useAuth0();
  const userId = user?.sub?.slice(6);

  const addProject = async (e) => {
    e.preventDefault();

    if (!userId) {
      toast.error("Invalid User Please Login Again");
      return "";
    }

    if (!project) {
      toast.error("Project name is required");
      return "";
    }
    setLoading(true);
    const sendData = {
      user_id: userId,
      project_name: project.trim(),
    };
    const result = await CallPOSTAPI("addProject", sendData);
    if (result.data?.status) {
      toast.success(result?.data?.msg);
      setProject("");
      fetchProjects();
      handleCancel();
    } else {
      toast.error(result?.data?.msg);
    }
    setLoading(false);
  };

  const onModalClose = () => {
    handleCancel(false);
    setProject("");
  };

  return (
    <>
      <Modal show={isPopupOpen} onHide={onModalClose} centered>
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b className="text-uppercase">Add New Project</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          {/* Content that may overflow */}
          <div style={{ display: "block" }}>
            <div className="">
              <form onSubmit={addProject} id="add_project_form">
                <div className="form-group d-grid gap-2">
                  <label htmlFor="location_name">
                    {" "}
                    <b class="d-flex">Project Title</b>{" "}
                  </label>
                  <input
                    type="text"
                    name="ProjectName"
                    placeholder="Enter Project Name..."
                    className="form-control modal-custom-input italic-input"
                    onChange={(e) => setProject(e.target.value.trimStart())}
                    value={project}
                    style={{ borderRadius: "20px" }}
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-custom-footer">
          <button
            className="search-btn"
            type="button"
            form="add_project_form"
            // id="add-project-btn"
            onClick={addProject}
          >
            {loading ? "Loading..." : "ADD NEW PROJECT"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddProjectModal;
