import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GoogleAPIKey } from "../helpers/Constants";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartColumn,
  faLocation,
  faSignIn,
  faSignOut,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { RiSearchLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const headerStyle = {
  // color: "#fff",
  height: 64,
  paddingInline: 0,
  lineHeight: "64px",
  width: "100%",
  // textAlign: "right",
};

const placesLibrary = ["places"];

const Header = ({
  setSearched,
  openInfoModal,
  setInfoModal,
  setSubscriptionModal,
  setProfileModal,
}) => {
  //   const [searched, setSearched] = useState(null);
  const [searchResult, setSearchResult] = useState("Result: none");
  const [searchValue, setsearchValue] = useState("");
  //   const [openInfoModal, setInfoModal] = useState(false);
  const [activeBtn, setactiveBtn] = useState(1);

  const token = localStorage.getItem("MapozonToken");
  const { user, loginWithRedirect, isAuthenticated, isLoading, logout } =
    useAuth0();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GoogleAPIKey,
    libraries: placesLibrary,
  });

  const { subscription_data } = useSelector((state) => state?.subscription);

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged(e) {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      if (!place.geometry || !place.geometry.location) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      console.log(place);
      setSearched(place);
    } else {
      alert("Please enter text");
    }
  }

  function inputChanged(e) {
    setSearched(null);
    if (e.target.value) setsearchValue(e.target.value);
  }

  const logoutUser = () => {
    localStorage.removeItem("MapozonToken");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Row style={headerStyle}>
      <Col sm={12} style={{ textAlign: "right" }}>
        <div className="flex-box justify-content-between">
          <div
            style={{
              width: "250px",
              textAlign: "left",
              paddingLeft: "14px",
            }}
          >
            <a href="/">
              <img width={160} src={"logo.svg"} alt="mapozone logo" />
            </a>
          </div>
          <div className="w-50">
            {isLoaded ? (
              <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                <div className="position-relative">
                  <RiSearchLine
                    size={25}
                    color="#71d622"
                    className="position-absolute"
                    style={{ top: "18px", right: "15px" }}
                  />
                  <input
                    id="searchTxt"
                    value={searchValue}
                    onChange={inputChanged}
                    type="search"
                    className="header-custom-search w-100"
                    placeholder="Type to search"
                    style={{
                      borderBottom: "2px solid #e7e7e7",
                      height: "45px",
                      alignItems: "center",
                      paddingRight: "50px",
                      paddingLeft: "10px",
                    }}
                    onInput={() => setsearchValue(null)}
                  />
                </div>
                {/* <> */}
                {/* <input
                  id="searchTxt"
                  onChange={inputChanged}
                  type="search"
                  className="nosubmit"
                  placeholder="Search Location"
                  style={{
                    color: "#000",
                    boxSizing: `border-box`,
                    border: `1px solid #E7E7E7`,
                    width: `280px`,
                    height: `40px`,
                    borderRadius: `5px`,
                    // boxShadow: `0 2px 4px rgba(0, 0, 0, 0.2)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    padding: "9px 4px 9px 40px",
                  }}
                /> */}
              </Autocomplete>
            ) : (
              ""
            )}
          </div>
          <div className="flex-box">
            <div className="">
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faChartColumn} />}
                onClick={() => setInfoModal(true)}
                style={{
                  color: activeBtn === 3 ? "black" : "rgba(0,0,0,0.5)",
                }}
              >
                Data Information
              </Button>
            </div>

            <div className="">
              {token || isAuthenticated ? (
                <>
                  <div className="d-inline-block profileItem">
                    <div className="user-img-cover">
                      <img
                        className="user-img profile-img-user"
                        src={user?.picture}
                        alt="user"
                      />
                    </div>
                    <div className="profileList">
                      <ul>
                        <li className="profile">
                          <Button
                            type="text"
                            icon={<FontAwesomeIcon icon={faUserCircle} />}
                            onClick={() => setProfileModal(true)}
                            style={{
                              color:
                                activeBtn === 3 ? "black" : "rgba(0,0,0,0.5)",
                            }}
                          >
                            Profile
                          </Button>
                        </li>
                        {/* {subscription_data?.status === "active" && (
                          <li className="profile">
                            <Button
                              type="text"
                              // icon={<FontAwesomeIcon icon={faUserCircle} />}
                              onClick={() => setSubscriptionModal(true)}
                              style={{
                                color:
                                  activeBtn === 3 ? "black" : "rgba(0,0,0,0.5)",
                              }}
                            >
                              Subscription
                            </Button>
                          </li>
                        )} */}
                        <li className="logout">
                          <Button
                            type="text"
                            icon={<FontAwesomeIcon icon={faSignOut} />}
                            onClick={logoutUser}
                            style={{
                              color:
                                activeBtn === 3 ? "black" : "rgba(0,0,0,0.5)",
                            }}
                          >
                            Logout
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    type="text"
                    icon={<FontAwesomeIcon icon={faSignIn} />}
                    onClick={() => loginWithRedirect()}
                    style={{
                      color: activeBtn === 3 ? "black" : "rgba(0,0,0,0.5)",
                    }}
                  >
                    Login
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
