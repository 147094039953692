import React from "react";
import { Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import L from "leaflet";

const MyMarker = ({ data, index }) => {
  const getPosition = (props) => {
    let lon = "";
    let lat = "";

    lon = props?.longitude1;
    lat = props?.latitude1;
    // lon = props?.latitude1;
    // lat = props?.longitude1;
    return [lat, lon];
  };

  const color = "#000";

  const myCustomColour = color;

  const markerHtmlStyles = `
  background-color: transparent;
  width: 20px;
  height: 20px;
  display: block;
  left: -4px;
  top: -4px;
  position: relative;
  border-radius: 100%;
  transform: rotate(45deg);
  border: 5px solid ${myCustomColour};
 `;

  const icon = new L.divIcon({
    className: "my-custom-pin",
    html: `<span style="${markerHtmlStyles}" />`,
  });

  return (
    <Marker
      position={[2916.718630407372, 115334.19369989881]}
      icon={icon}
    ></Marker>
    // <Marker
    //   key={index}
    //   //   zIndexOffset={4000000}
    //   position={getPosition(data)}
    //   icon={icon}
    //   eventHandlers={{
    //     mouseover: (event) => event.target.openPopup(),
    //     mouseout: (event) => event.target.closePopup(),
    //   }}
    // >
    //   <Popup className="custom-popup" keepInView closeButton={false}>
    //     <div className="d-flex flex-column">
    //       <span className="d-flex align-items-center">
    //         Location Name&nbsp;:&nbsp;
    //         <b
    //           className="text-capitalize d-inline-block text-truncate"
    //           style={{ maxWidth: "200px" }}
    //         >
    //           {data?.name}
    //         </b>
    //       </span>
    //     </div>
    //   </Popup>
    // </Marker>
  );
};

const PoiMarker = () => {
  const markerHtmlStyles = `
  background-color: transparent;
  width: 0.5px;
  height: 0.5px;
  display: block;
  position: relative;
  border-radius: 100%;
  transform: rotate(45deg);
  border: 4px solid #013220;
 `;

  //  left: -4px;
  //  top: -4px;

  const icon = new L.divIcon({
    className: "my-custom-pin",
    html: `<span style="${markerHtmlStyles}" />`,
  });

  const poiLocations = useSelector(
    (state) => state?.LocationProject?.poi_locations
  );

  const getPosition = (props) => {
    let lon = "";
    let lat = "";

    lon = props?.longitude;
    lat = props?.latitude;
    // lon = props?.latitude1;
    // lat = props?.longitude1;
    return [lat, lon];
  };

  function renderInfoSpan(label, value) {
    return (
      <span className="d-flex align-items-center">
        {label}&nbsp;:&nbsp;
        <b
          className="text-capitalize d-inline-block text-truncate"
          style={{ maxWidth: "200px" }}
        >
          {value || "NA"}
        </b>
      </span>
    );
  }

  function renderPopupContent(item) {
    return (
      <div className="d-flex flex-column">
        {item?.name && renderInfoSpan("Name", item?.name)}
        {item?.brand && renderInfoSpan("Brand", item?.brand)}
        {item?.amenity && renderInfoSpan("Amenity", item?.amenity)}
        {item?.shop && renderInfoSpan("Shop", item?.shop)}
        {item?.cuisine && renderInfoSpan("Cuisine", item?.cuisine)}
        {item?.phone && renderInfoSpan("Phone", item?.phone)}
        {item?.email && renderInfoSpan("Email", item?.email)}
        {item?.website && renderInfoSpan("Website", item?.website)}
        {item?.addr_housename &&
          renderInfoSpan("House Name", item?.addr_housename)}
        {item?.addr_housenumber &&
          renderInfoSpan("House Number", item?.addr_housenumber)}
        {item?.addr_street && renderInfoSpan("Street", item?.addr_street)}
        {item?.addr_postcode &&
          renderInfoSpan("Post Code", item?.addr_postcode)}
        {item?.addr_city && renderInfoSpan("City", item?.addr_city)}
      </div>
    );
  }

  return (
    <>
      {poiLocations.map((item, i) => (
        <Marker key={i} position={getPosition(item)} icon={icon}>
          <Popup className="custom-popup" keepInView closeButton={false}>
            {renderPopupContent(item)}
          </Popup>
        </Marker>
      ))}
    </>
  );
};

export default PoiMarker;
