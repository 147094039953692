import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { TokenKey } from "../../helpers/Constants";
import axios from "axios";
import { BASE_API } from "../../helpers/API";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleSubscription } from "../../redux/slices/subscriptionSlice";
import { AiOutlineClose } from "react-icons/ai";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";

const ProfileModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { subscription_data } = useSelector((state) => state?.subscription);
  const onModalClose = () => {
    setShowModal(false);
  };

  const [loading, setLoading] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const response = await axios.post(
        BASE_API + "cancel-subscription",
        {
          subscription_id: subscription_data?.id,
          user_id: userId,
        },
        { headers: headers }
      );

      if (response.data.status) {
        dispatch(handleSubscription(response.data.subscription));
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const getSubscriptionStatus = (data) => {
    // const currentDate = new Date();
    // const subscriptionEndDate = new Date(data?.current_period_end * 1000);

    // const comparison = subscriptionEndDate.getTime() - currentDate.getTime();

    if (data?.status === "canceled") {
      return true;
    } else {
      return false;
    }
  };

  const handleResumeSubscription = async () => {
    try {
      setLoading(true);
      const userId = user?.sub?.split("|")[1];
      let token = localStorage.getItem(TokenKey);
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const response = await axios.post(
        BASE_API + "resume-subscription",
        {
          subscription_id: subscription_data?.id,
          user_id: userId,
        },
        { headers: headers }
      );

      if (response.data.status) {
        dispatch(handleSubscription(response.data.subscription));
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleButtonClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-confirm-alert"
            style={{ width: "60%", margin: "auto" }}
          >
            <div
              style={{
                // width: "60%",
                // margin: "auto",
                textAlign: "center",
              }}
            >
              <h1
                className="main-heading text-uppercase"
                style={{ fontSize: "18px" }}
              >
                {subscription_data && getSubscriptionStatus(subscription_data)
                  ? "Are you sure you want to resume your subscription?"
                  : `Based on your current plan, your subscription will automatically canceled by ${moment(
                      subscription_data.current_period_end * 1000
                    ).format("DD-MM-YYYY HH:mm:ss A")}`}
              </h1>
            </div>

            <div className="d-flex flex-column justify-content-between gap-4 mt-5">
              <button
                className="search-btn"
                style={{
                  backgroundColor: "#ff6b62",
                }}
                onClick={() => {
                  if (
                    subscription_data &&
                    getSubscriptionStatus(subscription_data)
                  ) {
                    handleResumeSubscription();
                  } else {
                    handleCancelSubscription();
                  }
                  onClose();
                }}
              >
                Okay
              </button>
              <button
                className="search-btn"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #d9d9d9",
                  color: "black",
                }}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Modal
        size="lg"
        className="subscription-modal"
        show={showModal}
        onHide={onModalClose}
        centered
        backdrop="static"
      >
        <Modal.Header
          className="modal-custom-header"
          // style={{ justifyContent: "center" }}
          // closeButton
        >
          <Modal.Title className="flex-grow-1 text-center">
            <h4 className="m-0">Profile</h4>
          </Modal.Title>

          <AiOutlineClose
            // size={20}
            onClick={onModalClose}
            className="text-muted"
            style={{
              border: "1px solid gray",
              borderRadius: "50%",
              padding: "8px",
              height: "35px",
              width: "35px",
              cursor: "pointer",
            }}
          />
        </Modal.Header>
        <Modal.Body
          className="d-flex flex-column align-items-start gap-3"
          style={{ padding: "10px 20px" }}
        >
          <div className="">
            <h4 className="">Email</h4>
            <p className="text-muted">{user?.email}</p>
          </div>
          <div className="" style={{ width: "100%", padding: "0px 5px" }}>
            {subscription_data && subscription_data?.plan?.amount === 0 && (
              <>
                <h4>Active Subscription</h4>
                <div className="pricing">
                  {/* <div className="container">
                  <div className="row"> */}
                  <div className={``} role="button">
                    {/* <div className="card">
                      <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">
                          Free
                        </h5> */}
                    <h6 className="card-price">
                      £0/
                      <span className="text-muted" style={{ fontSize: "16px" }}>
                        free
                      </span>
                    </h6>
                    {/* </div>
                    </div> */}
                  </div>
                  {/* </div>
                </div> */}
                </div>
              </>
            )}
            {subscription_data && subscription_data?.plan?.amount !== 0 && (
              <>
                <h4 className="">Active Subscription</h4>
                <div className="pricing">
                  {/* <div className="container">
                  <div className="row"> */}
                  <div className={``} role="button">
                    {/* <div className="card">
                      <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">
                          {subscription_data?.plan?.amount === 39900
                            ? "Yearly"
                            : "Monthly"}
                        </h5> */}
                    <h6 className="card-price">
                      {subscription_data?.plan?.amount === 35000
                        ? "£350/"
                        : "£39/"}
                      <span className="text-muted" style={{ fontSize: "16px" }}>
                        {subscription_data?.plan?.amount === 35000
                          ? "year"
                          : "month"}
                      </span>
                    </h6>
                    {/* </div>
                    </div> */}
                  </div>
                  {/* </div>
                </div> */}
                </div>
                <div className="w-100 mt-3 mb-2">
                  <button
                    className="cancel-btn"
                    type="button"
                    onClick={() => {
                      handleButtonClick();
                      // handleCancelSubscription();
                    }}
                    // disabled={
                    //   loading ||
                    //   (subscription_data &&
                    //     getSubscriptionStatus(subscription_data))
                    // }
                    disabled={loading}
                  >
                    {subscription_data &&
                    getSubscriptionStatus(subscription_data)
                      ? "Resume Subscription"
                      : "Cancel Subscription"}
                  </button>
                </div>
                {/* <button
                  className="search-btn mt-3"
                  style={{ backgroundColor: "#ff6b62" }}
                  onClick={() => {
                    handleCancelSubscription();
                  }}
                  disabled={
                    loading ||
                    (subscription_data &&
                      getSubscriptionStatus(subscription_data))
                  }
                >
                  {loading ? "Loading..." : "Cancel"}
                </button> */}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileModal;
