// import React from 'react'
// import { useAuth0 } from '@auth0/auth0-react'
// import { useLocation, useNavigate } from 'react-router-dom'
// import { useEffect } from 'react'
// const LoginNew = () => {
//    const location = useLocation()

//    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

//    const navigate = useNavigate()
//    useEffect(() => {
//       if (isAuthenticated) {
//          navigate('/home')
//       }
//    }, [isAuthenticated])
//    return (
//       <div>
//          <button className='btn1 register-submit' onClick={() => loginWithRedirect()}>Login</button>
//       </div>
//    )
// }

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./style/custom.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { CallGETAPI } from "./helpers/API";
import { useDispatch } from "react-redux";
import {
  addDistanceLocation,
  handleAddSelectedLocations,
  handleAddSelectedProjects,
  handleLoadingFalse,
  handleLoadingTrue,
  handleRemoveAllLocations,
  handleRemoveSelectedLocations,
  removeDistanceLocation,
} from "./redux/slices/LocationProjectSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { IoLogInOutline } from "react-icons/io5";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Link } from "react-router-dom";


function LoginNew({ show, setShow }) {

  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  const dispatch = useDispatch();

  const [localToken, setLocalToken] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem("MapozonToken", user?.sub);
      setLocalToken(user?.sub);
      setShow(false);
      // getLocations(user?.sub);
      // if (localToken) {
      // getLocations(localToken);
      // }
    } else {
      localStorage.removeItem("MapozonToken");
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isAuthenticated]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  }, [window.innerWidth]);

  // useEffect(() => {
  //   if (windowSize.width <= 768) {
  //     setShow(false);
  //   }
  // }, [window.innerWidth]);

  // const mapToken = localStorage.getItem("MapozonToken");

  const allProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  const radiusLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );
  const travelLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const combinedLocations = [...radiusLocation, ...travelLocation];

  const getLocations = async (myToken) => {
    dispatch(handleLoadingTrue());
    if (!myToken) return "";
    const res = await CallGETAPI(`getUserLocations/${myToken}`);
    // dispatch(handleRemoveAllLocations());
    if (res.data.status) {
      const { data, projectData, shared_projects } = res.data;

      if (projectData.data.length) {
        projectData.data.map((item, i) => {
          if (item.project_checked1) {
            if (!allProjects.find((obj) => obj.id === item.id)) {
              dispatch(handleAddSelectedProjects([item]));
            }
          }
        });
      }

      // if (shared_projects?.data?.length) {
      if (shared_projects?.length) {
        // shared_projects.data.map((item, i) => {
        shared_projects.map((item, i) => {
          if (item.project_checked1) {
            if (!allProjects.find((obj) => obj.id === item.id)) {
              dispatch(handleAddSelectedProjects([item]));
            }
          }
        });
      }

      for (let i = 0; i < data.length; i++) {
        const {
          color,
          list_id,
          list_locations,
          list_name,
          project_name,
          project_id,
        } = data[i];

        list_locations?.map(async (item, i) => {
          if (item.location_checked1) {
            // if (!combinedLocations.find((obj) => obj.location_id === item.id)) {
            if (
              item.catchment_area === "Distance" ||
              item.catchment_area === null
            ) {
              if (
                !combinedLocations.find((obj) => obj.location_id === item.id)
              ) {
                // dispatch(handleRemoveSelectedLocations(item.id));
                let newObj = { ...item };
                newObj.color = color;
                newObj.projectId = project_id;
                newObj.listName = list_name;
                newObj.projectName = project_name;
                newObj.location_id = item.id;
                dispatch(handleAddSelectedLocations([newObj]));
              }
            } else {
              if (
                !combinedLocations.find((obj) => obj.location_id === item.id)
              ) {
                // dispatch(removeDistanceLocation(item.id));
                let localKey =
                  item.id.toString() +
                  item.lat.toString() +
                  item.long.toString() +
                  color.toString();
                const existdata = localStorage.getItem(localKey);
                if (!existdata) {
                  try {
                    const response = await axios.get(
                      `https://api.mapbox.com/isochrone/v1/mapbox/${
                        item?.travel_way
                      }/${item.long},${item.lat}?contours_minutes=${
                        item?.travel_time
                      }&contours_colors=${
                        color?.slice(1) || ""
                      }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
                    );

                    let memoObject = {
                      lat: item.lat,
                      long: item.long,
                      locationName: item.location_name,
                      location_id: item.id,
                      projectId: project_id,
                      list_id: list_id,
                      listName: list_name,
                      projectName: project_name,
                      travelWay: item.travel_way,
                      ...response.data.features[0],
                    };
                    localStorage.setItem(item.id, JSON.stringify(memoObject));
                    dispatch(addDistanceLocation([memoObject]));
                  } catch (error) {
                    toast.error(error.msg);
                  }
                } else {
                  let currentLoc = JSON.parse(existdata);
                  // console.log("Loc Data", currentLoc);
                  let locData = { ...currentLoc };
                  locData.listName = list_name;
                  locData.projectName = project_name;
                  dispatch(addDistanceLocation([locData]));
                  // dispatch(addDistanceLocation([JSON.parse(existdata)]));
                }
              }
            }
            // }
          }
        });
      }
      dispatch(handleLoadingFalse());
    } else {
      dispatch(handleRemoveAllLocations());
      dispatch(handleLoadingFalse());
    }
  };

  useEffect(() => {
    if (localToken) {
      const lt = localToken?.split("|")[1];
      // getLocations(lt);
    }
  }, [localToken]);

 
 
  


  return (
    <>
      <Modal
        className="subscription-modal"
        // className="loginModal"
        // dialogClassName="loginModo"
        show={show}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <p className="m-0 mb-3 gray-text">Welcome to</p>
            <div className="mb-3">
              <img
                width={200}
                // height={80}
                src={"logo.svg"}
                alt="mapozone logo"
              />
            </div>
            <p className="m-0 mb-3 gray-text">
              Log In or Sign Up to using Mapozone
            </p>

            <div className="mb-5">
              <img
                width={250}
                // height={80}
                src={"LoginModal.svg"}
                alt="mapozone logo"
              />
            </div>
            <div className="w-100 d-flex align-items-center gap-3">
              <button
                className="login-btn flex-grow-1"
                onClick={() => loginWithRedirect()}
              >
                <IoLogInOutline className="me-1" size={24} />
                <span>Log In</span>
              </button>
              <button
                className="login-btn flex-grow-1"
                onClick={() => loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  }
                })}
              >
                <AiOutlineUserAdd className="me-1" size={22} />
                <span>Sign Up</span>
              </button>
            </div>
            <div className="mt-3">
              <a
                href="https://www.mapozone.com"
                className="m-0 mb-3 gray-text learn-more"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </div>
            {/* <button
              style={{
                width: "150px",
                background: "#adf802",
                border: "none",
                borderRadius: "5px",
              }}
              className="btn1 register-submit mx-auto"
              onClick={() => loginWithRedirect()}
            >
              {" "}
              Login / Signup
            </button> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginNew;
