import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counterSlice";
import mapReducer from "./slices/MapSlice";
import OldMapReducer from "./slices/OldMapSlice";
import LocationProjectReducer from "./slices/LocationProjectSlice";
import subscriptionReducer from "./slices/subscriptionSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    mapSlice: mapReducer,
    OldMapSlice: OldMapReducer,
    LocationProject: LocationProjectReducer,
    subscription: subscriptionReducer,
  },
});
