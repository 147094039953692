// import MapWrapper from "./Map";
// import { Routes,Route } from "react-router-dom";
// import MapPage from "./map_pages/MapPage";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "react-toastify/dist/ReactToastify.css";
// import "./style/custom.css";
// import "leaflet/dist/leaflet.css";

// import { ToastContainer } from "react-toastify";

// function App() {
//   return (<>
//     <Routes>
//         <Route path="/" element={<MapWrapper />} />
//         <Route path="/new" element={<MapPage />} />
//     </Routes>
//     <ToastContainer/>
//   </>)
// }

// export default App;

import LoginPage from "./Login";
import MapWrapper from "./Map";
import React, { useState, useEffect } from "react";
import RegisterPage from "./RegisterPage";
import ForgotPassword from "./Forgotpassword";
import { ToastContainer, toast } from "react-toastify";
import UpdatePassword from "./UpdatePassword";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { TokenKey } from "./helpers/Constants";
import LoginNew from "./LoginNew";
import "react-confirm-alert/src/react-confirm-alert.css";
import Profile from "./pages/ProfilePages/Profile";
import { useSelector } from "react-redux";
import { CallGETAPI, CallPOSTAPI } from "./helpers/API";
import {
  addDistanceLocation,
  handleAddSelectedLocations,
  handleAddSelectedProjects,
  handleLoadingFalse,
  handleLoadingTrue,
  handleRemoveAllLocations,
  handleRemoveSelectedLocations,
  removeDistanceLocation,
} from "./redux/slices/LocationProjectSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import PaymentSuccess from "./PaymentSuccess";
import PaymentCancelled from "./PaymentCancelled";

function extractPathnameAndToken(url) {
  // Split the URL into parts using '/' as the delimiter

  const parts = url.split("/");

  // Extract the pathname from the parts
  const pathname = parts[1]; // Index 0 is an empty string due to the leading slash

  // Use URLSearchParams to extract the token from the query string
  const queryString = parts[2]; // Index 2 contains the query string
  const searchParams = new URLSearchParams(queryString);
  const token = searchParams.get("token");

  const searchParams2 = new URLSearchParams(window.location.search);
  const token2 = searchParams2.get("token");

  return { pathname, Urltoken: token2 };
}

// import { BrowserRouter,Router,Route } from "react-router-dom";
function App() {
  const dispatch = useDispatch();
  const [is_login, set_is_login] = useState(false);
  const currentPathName =
    window.location.pathname === "/" ? "login" : window.location.pathname;

  const { pathname, Urltoken } = extractPathnameAndToken(currentPathName);

  const [crrPath, setCrrPath] = useState(pathname ? pathname : currentPathName);
  const [isComponentVisible, setComponentVisible] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (user) {
      handleRegister(user);
      getLocations(user);
    }
  }, [user]);

  useEffect(() => {
    const token = localStorage.getItem(TokenKey);
    if (token) {
      // handleRegister();
      set_is_login(true);
    } else {
      set_is_login(false);
    }
  }, [crrPath]);

  const handleRegister = async (user) => {
    const res = await CallPOSTAPI("register-user", { ...user });
    if (res.data.status) {
      // toast.success(res.data.msg);
    } else {
      // toast.error(res.data.msg);
    }
  };

  const mapToken = localStorage.getItem("MapozonToken");

  const allProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  const radiusLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );
  const travelLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const combinedLocations = [...radiusLocation, ...travelLocation];

  const getLocations = async (getUserToken) => {
    const lt = getUserToken.sub?.split("|")[1]; //mapToken?.split("|")[1];

    if (!lt) return "";
    dispatch(handleLoadingTrue());
    const res = await CallGETAPI(`getUserLocations/` + lt);

    if (res.data.status) {
      const { data, projectData, shared_projects } = res.data;

      if (projectData.data.length) {
        projectData.data.map((item, i) => {
          if (item.project_checked1) {
            if (!allProjects.find((obj) => obj.id === item.id)) {
              dispatch(handleAddSelectedProjects([item]));
            }
          }
        });
      }
      // if (shared_projects?.data?.length) {
      if (shared_projects?.length) {
        // shared_projects.data.map((item, i) => {
        shared_projects.map((item, i) => {
          if (item.project_checked1) {
            if (!allProjects.find((obj) => obj.id === item.id)) {
              dispatch(handleAddSelectedProjects([item]));
            }
          }
        });
      }

      for (let i = 0; i < data.length; i++) {
        const {
          color,
          list_id,
          list_locations,
          list_name,
          project_name,
          project_id,
        } = data[i];
        list_locations?.map(async (item, i) => {
          if (item.location_checked1) {
            // if (!combinedLocations.find((obj) => obj.location_id === item.id)) {
            if (
              item.catchment_area === "Distance" ||
              item.catchment_area === null
            ) {
              if (
                !combinedLocations.find((obj) => obj.location_id === item.id)
              ) {
                // dispatch(handleRemoveSelectedLocations(item.id));
                let newObj = { ...item };
                newObj.color = color;
                newObj.projectId = project_id;
                newObj.listName = list_name;
                newObj.projectName = project_name;
                newObj.location_id = item.id;
                dispatch(handleAddSelectedLocations([newObj]));
              }
            } else {
              if (
                !combinedLocations.find((obj) => obj.location_id === item.id)
              ) {
                // dispatch(removeDistanceLocation(item.id));
                let localKey =
                  item.id.toString() +
                  item.lat.toString() +
                  item.long.toString() +
                  item.travel_way.toString() +
                  item.travel_time.toString() +
                  color.toString();
                const existdata = localStorage.getItem(localKey);
                if (!existdata) {
                  try {
                    const response = await axios.get(
                      `https://api.mapbox.com/isochrone/v1/mapbox/${
                        item?.travel_way
                      }/${item.long},${item.lat}?contours_minutes=${
                        item?.travel_time
                      }&contours_colors=${
                        color?.slice(1) || ""
                      }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
                    );

                    let memoObject = {
                      lat: item.lat,
                      long: item.long,
                      locationName: item.location_name,
                      location_id: item.id,
                      projectId: project_id,
                      list_id: list_id,
                      listName: list_name,
                      projectName: project_name,
                      travelWay: item.travel_way,
                      ...response.data.features[0],
                    };
                    localStorage.setItem(localKey, JSON.stringify(memoObject));
                    dispatch(addDistanceLocation([memoObject]));
                  } catch (error) {
                    toast.error(error.msg);
                  }
                } else {
                  let currentLoc = JSON.parse(existdata);
                  let locData = { ...currentLoc };
                  locData.listName = list_name;
                  locData.projectName = project_name;
                  dispatch(addDistanceLocation([locData]));
                }
              }
            }
            // }
          }
        });
      }

      dispatch(handleLoadingFalse());
    } else {
      dispatch(handleRemoveAllLocations());
      dispatch(handleLoadingFalse());
    }
  };

  // useEffect(() => {
  //   getLocations();
  // }, []);

  const setUserToken = async () => {
    try {
      const newAccessToken = await getAccessTokenSilently();
      localStorage.setItem(TokenKey, newAccessToken);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem(TokenKey)) {
      setUserToken();
    }
  }, []);

  if (crrPath === "forgot-password-from") {
    return <ForgotPassword />;
  }

  if (crrPath === "forgot-password") {
    return <UpdatePassword token={Urltoken} />;
  }

  // if(crrPath==='update-password'){
  //   return (
  //     <UpdatePassword/>
  //   );
  // }

  return (
    <>
      <Routes>
        <Route exact path="/" element={<MapWrapper />} />
        <Route exact path="/location-page" element={<Profile />} />
        <Route exact path="/payment-success" element={<PaymentSuccess />} />
        <Route exact path="/payment-cancelled" element={<PaymentCancelled />} />
      </Routes>
      <ToastContainer />

      {/* {is_login ?  <div><MapWrapper /></div> :  <> 
        {crrPath === 'login' ? <LoginPage setCrrPath={setCrrPath} /> : <RegisterPage setCrrPath={setCrrPath}/>} 
        
        </>}
         */}
    </>
  );
}

export default App;
