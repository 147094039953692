import { createSlice } from '@reduxjs/toolkit'
import { area_dropdownd } from '../../map_pages/Constants';
const initialState = {
    loading: 0,
    value: null,
    searched: null,
    zoom: 6,
    reset: false,
    optionsAdmin: area_dropdownd,
    adminOptionVal: "", 
    defaultSubCatVal : 'actual',
    active_btn: '',
    geoJsonData : [],
    msoaEstab : null,
    dataset_val: "",
    category_val:"",
    show_type: "actual",
  }


export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
    handle_zoom: (state,action) => {
        state.zoom = action.payload
    },
    handle_val: (state,action) => {
        state.value = action.payload
    },
    handle_search : (state,action) => {
        state.searched = action.payload
    },
    handle_reset : (state,action) => {
        state.reset = action.payload
    },

    handle_loading : (state,action) => {
        state.loading = action.payload
    },

    handle_admin_option: (state,action) =>{
        state.adminOption = [
			...state.adminOption,
			{
                ...action.payload
			},
		]
    },
    handle_area_select: (state,action) => {
        state.defaultSubCatVal = action.payload
    },

    handle_admin_option_val: (state,action)=>{
        state.adminOptionVal = action.payload
    },
    handle_activate_btn :(state,action) =>{
        state.active_btn = action.payload
    },
    handle_data: (state,action) =>{
        state.geoJsonData = action.payload
    },
    set_msoaEstab: (state,action) => {
        state.msoaEstab = action.payload
    },

    set_dataset_val: (state,action) => {
        state.dataset_val = action.payload
    },
    set_category: (state,action) =>{
        state.category_val = action.payload
    },

    set_show_type: (state,action) => {
        state.show_type = action.payload
    },

    }
});

export const { handle_zoom, handle_val, handle_search,handle_reset,handle_loading,handle_admin_option,handle_area_select,handle_admin_option_val,handle_activate_btn,handle_data,set_msoaEstab,set_dataset_val,set_category,set_show_type } = mapSlice.actions
export default mapSlice.reducer;
