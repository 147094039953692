import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import {
  addDistanceLocation,
  handleActiveProject,
  handleAddSelectedLocations,
  handleRemoveAllLocations,
  handleRemoveLocationsByProjectId,
  handleRemoveSelectedLocations,
  handleRemoveactiveProjects,
  removeDistanceLocation,
  updateArrDistenceLocations,
  updateArrSelectLocations,
} from "../../redux/slices/LocationProjectSlice";
import { CallGETAPI, CallPOSTAPI } from "../../helpers/API";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { FaRegEdit } from "react-icons/fa";
import UpdateProjectModal from "../modals/UpdateProjectModal";
import {
  handleAddSelectedProjects,
  handleRemoveSelectedProjects,
} from "../../redux/slices/LocationProjectSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import { AiOutlineEye, AiOutlineShareAlt } from "react-icons/ai";
import ShareProjectModal from "../modals/ShareProjectModal";
import { useAuth0 } from "@auth0/auth0-react";
import { MdIosShare } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RxEyeClosed } from "react-icons/rx";

function ProjectList({
  title,
  id,
  item,
  is_selected,
  refreashList,
  setActiveProject,
  projectDetailDiv,
  setProjectDetailDiv,
  projectType,
}) {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const [updateLoading, setUpdateLoading] = useState(false);
  const currentProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );
  const [currentProject, setCurrentProject] = useState({});
  const [updateProjectModal, setUpdateProjectModal] = useState(false);
  const [shareProjectModal, setShareProjectModal] = useState(false);

  const DeleteProject = async () => {
    const res = await CallGETAPI("deleteProject/" + id);
    if (res?.data?.status) {
      toast.success(res?.data?.msg);
      setActiveProject("");
      dispatch(handleRemoveSelectedProjects(id));
      dispatch(handleRemoveLocationsByProjectId(id));
      dispatch(handleRemoveactiveProjects(is_selected));
      refreashList();
    } else {
      toast.error(res?.data?.msg);
    }
  };

  const selectedLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );
  const travelLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const handleChange = (e, item) => {
    if (e) {
      dispatch(handleAddSelectedProjects([item]));
      // handleAddSelectedLocations
      // addDistanceLocation
      // const arr = [...selectedLocation,];
      // const arr1 = selectedLocation.map((it) => ({
      //   ...it,
      //   is_project_selected:
      //     parseInt(it.project_id) === parseInt(item.project_id),
      // }));
      // const arr2 = travelLocation.map((it) => ({
      //   ...it,
      //   is_project_selected:
      //     parseInt(it.project_id) === parseInt(item.project_id),
      // }));
      // console.log({ arr1, arr2 });
      // dispatch(updateArrSelectLocations(arr1));
      // dispatch(updateArrDistenceLocations(arr2));
    } else {
      dispatch(handleRemoveSelectedProjects(item?.id));
    }
  };

  const handleLocationStatus = async (e, item) => {
    setUpdateLoading(true);
    let data = e
      ? {
          id: [item.id],
          is_checked: 1,
          user_id: user?.sub?.split("|")[1],
        }
      : {
          id: [item.id],
          is_checked: 0,
          user_id: user?.sub?.split("|")[1],
        };

    const res = await CallPOSTAPI("projectChecked/", data);

    if (res?.data?.status) {
      // getLocations();
      setUpdateLoading(false);
    } else {
      toast.error(res?.data?.msg);
    }
  };

  // const handleReduxValues = (item) => {
  //   if (item.project_checked) {
  //     dispatch(handleAddSelectedProjects([item]));
  //   }
  // };

  // useEffect(() => {
  //   handleReduxValues(item);
  // }, [item]);

  const mapToken = localStorage.getItem("MapozonToken");

  const radiusLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );
  // const travelLocation = useSelector(
  //   (state) => state?.LocationProject?.distance_location
  // );

  const combinedLocations = [...radiusLocation, ...travelLocation];

  const getLocations = async () => {
    const res = await CallGETAPI(`getUserLocations/${mapToken?.split("|")[1]}`);

    if (res.data.status) {
      const { data } = res.data;

      for (let i = 0; i < data.length; i++) {
        const {
          color,
          list_id,
          list_locations,
          list_name,
          project_name,
          project_id,
        } = data[i];
        list_locations?.map(async (item, i) => {
          // console.log("item in loop", item);

          // if (!combinedLocations.find((obj) => obj.location_id === item.id)) {
          if (item.catchment_area === "Distance") {
            if (!combinedLocations.find((obj) => obj.location_id === item.id)) {
              let newObj = { ...item };
              newObj.color = color;
              newObj.projectId = project_id;
              newObj.listName = list_name;
              newObj.projectName = project_name;
              newObj.location_id = item.id;
              dispatch(handleAddSelectedLocations([newObj]));
            } else {
              if (
                combinedLocations.find(
                  (obj) => obj.location_id === item.id && obj.color !== color
                )
              ) {
                dispatch(handleRemoveSelectedLocations(item.id));
                let newObj = { ...item };
                newObj.color = color;
                newObj.projectId = project_id;
                newObj.listName = list_name;
                newObj.projectName = project_name;
                newObj.location_id = item.id;
                dispatch(handleAddSelectedLocations([newObj]));
              }
            }
          } else {
            if (!combinedLocations.find((obj) => obj.location_id === item.id)) {
              let localKey =
                item.lat.toString() + item.long.toString() + color.toString();
              const existdata = localStorage.getItem(localKey);
              if (!existdata) {
                try {
                  const response = await axios.get(
                    `https://api.mapbox.com/isochrone/v1/mapbox/${
                      item?.travel_way
                    }/${item.long},${item.lat}?contours_minutes=${
                      item?.travel_time
                    }&contours_colors=${
                      color?.slice(1) || ""
                    }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
                  );

                  let memoObject = {
                    lat: item.lat,
                    long: item.long,
                    locationName: item.location_name,
                    location_id: item.id,
                    projectId: project_id,
                    list_id: list_id,
                    listName: list_name,
                    projectName: project_name,
                    ...response.data.features[0],
                  };
                  localStorage.setItem(localKey, JSON.stringify(memoObject));
                  dispatch(addDistanceLocation([memoObject]));
                } catch (error) {
                  toast.error(error.msg);
                }
              } else {
                dispatch(addDistanceLocation([JSON.parse(existdata)]));
              }
            } else {
              if (
                combinedLocations.find(
                  (obj) => obj.location_id === item.id && obj.color !== color
                )
              ) {
                dispatch(removeDistanceLocation(item.id));
                let localKey =
                  item.lat.toString() + item.long.toString() + color.toString();
                try {
                  const response = await axios.get(
                    `https://api.mapbox.com/isochrone/v1/mapbox/${
                      item?.travel_way
                    }/${item.long},${item.lat}?contours_minutes=${
                      item?.travel_time
                    }&contours_colors=${
                      color?.slice(1) || ""
                    }&polygons=true&access_token=pk.eyJ1IjoibWFwb3pvbmUiLCJhIjoiY2xsYmlhd3ZmMDhpYTNqbGhrcGdxNG80NCJ9.Qc-iZKg3sjooK3sbjkV7zQ`
                  );

                  let memoObject = {
                    lat: item.lat,
                    long: item.long,
                    locationName: item.location_name,
                    location_id: item.id,
                    projectId: project_id,
                    list_id: list_id,
                    listName: list_name,
                    projectName: project_name,
                    ...response.data.features[0],
                  };
                  localStorage.setItem(localKey, JSON.stringify(memoObject));
                  dispatch(addDistanceLocation([memoObject]));
                } catch (error) {
                  toast.error(error.msg);
                }
              }
            }
          }
        });
      }
    } else {
      dispatch(handleRemoveAllLocations());
    }
  };

  return (
    <>
      <UpdateProjectModal
        currentProject={currentProject}
        fetchProjects={refreashList}
        isPopupOpen={updateProjectModal}
        handleCancel={() => setUpdateProjectModal(false)}
      />
      <ShareProjectModal
        currentProject={currentProject}
        fetchProjects={refreashList}
        isPopupOpen={shareProjectModal}
        handleCancel={() => setShareProjectModal(false)}
      />
      <div
        className={`d-flex justify-content-between align-items-center mt-3 p-1 ${
          is_selected ? "active-proj" : ""
        } `}
        // style={{ }}
        key={id}
      >
        <div className="d-flex align-items-center justify-content-between cursor-pointer gap-1">
          {/* <label htmlFor={"project_" + id}>
            <input
              className="LocationNameCheckBox"
              type="checkbox"
              id={"project_" + id}
              // value={"project_" + id}
              onChange={(e) => {
                handleChange(e, item);
                handleLocationStatus(e, item);
              }}
              checked={currentProjects.find((loc) => loc.id === item?.id)}
              disabled={updateLoading}
            />
          </label> */}

          {/* {currentProjects.some((loc) => loc.id === item?.id) === true ? (
            <AiOutlineEye
              className="primary-icon"
              onClick={() => {
                handleChange(false, item);
                handleLocationStatus(false, item);
              }}
              style={{ cursor: "pointer" }}
              size={15}
            />
          ) : (
            <RxEyeClosed
              className="primary-icon"
              onClick={() => {
                handleChange(true, item);
                handleLocationStatus(true, item);
              }}
              style={{ cursor: "pointer" }}
              size={15}
            />
          )} */}
          <span
            className="main-heading"
            onClick={() => {
              dispatch(handleActiveProject(item));
              setProjectDetailDiv(true);
            }}
            role="button"
            // onClick={() => setProjectDetailDiv(!projectDetailDiv)}
          >
            {title}
          </span>
        </div>
        {projectType !== "shared" && (
          <div className="d-flex justify-content-between align-items-center gap-1 primary-icon">
            <RiDeleteBinLine
              size={15}
              style={{ cursor: "pointer" }}
              onClick={() => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-confirm-alert">
                        <div
                          style={{
                            width: "60%",
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          <h1
                            className="main-heading text-uppercase"
                            style={{ fontSize: "18px" }}
                          >
                            Are you sure you want to delete this item?
                          </h1>
                        </div>

                        <div className="d-flex flex-column justify-content-between gap-4 mt-5">
                          <button
                            className="search-btn"
                            style={{ backgroundColor: "#ff6b62" }}
                            onClick={() => {
                              DeleteProject();
                              onClose();
                            }}
                          >
                            Delete
                          </button>
                          <button
                            className="search-btn"
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid #d9d9d9",
                              color: "black",
                            }}
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    );
                  },
                  // title: "Delete Project?",
                  // message: "Are you sure to do this.",
                  // buttons: [
                  //   {
                  //     label: "Yes",
                  //     onClick: () => DeleteProject("Click Yes"),
                  //   },
                  //   {
                  //     label: "No",
                  //     onClick: () => {},
                  //   },
                  // ],
                });
              }}
            />
            <MdIosShare
              size={15}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrentProject(item);
                setShareProjectModal(true);
              }}
            />
            <FiEdit
              size={15}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCurrentProject(item);
                setUpdateProjectModal(true);
              }}
            />
          </div>
        )}
      </div>
      <hr className="my-1" />
    </>
  );
}

export default ProjectList;
