import React, { useState } from "react";
import { CheckIcon } from "./helpers/Constants";
import { CheckOutlined } from "@ant-design/icons";
// const ColorData = [
//   { bg: "#8c1a0f", is_selected: false },
//   { bg: "#eb3223", is_selected: false },
//   { bg: "#f19d39", is_selected: false },
//   { bg: "#fefd54", is_selected: false },
//   { bg: "#74f94d", is_selected: false },
//   { bg: "#5688e0", is_selected: false },
//   { bg: "#0d28f5", is_selected: false },
//   { bg: "#8b31f5", is_selected: false },
//   { bg: "#ea40f7", is_selected: false },
// ];

const ColorData = [
  { bg: "#71d622", is_selected: false },
  { bg: "#22cbd6", is_selected: false },
  { bg: "#eda334", is_selected: false },
  { bg: "#ff6b62", is_selected: false },
  { bg: "#ff6fce", is_selected: false },
  { bg: "#b57bff", is_selected: false },
  { bg: "#ffdb5b", is_selected: false },
  { bg: "#6e7cf6", is_selected: false },
  { bg: "#afafaf", is_selected: false },
];

const ColorPalette = ({ setInput, color = "" }) => {
  const [selected, setSelected] = useState("");
  return (
    <div className="color-palette">
      {ColorData.map((item, i) => (
        <label
          className="color"
          style={{ backgroundColor: item.bg, cursor: "pointer" }}
          key={i}
          htmlFor={"clr_" + i}
        >
          <input
            id={"clr_" + i}
            type="radio"
            name="color"
            value={item.bg}
            checked={color === item.bg || selected === item.bg}
            onChange={(e) => {
              setSelected(e.target.value);
              setInput(e.target.value);
            }}
            style={{ display: "none" }}
          />
          {color === item.bg || selected === item.bg ? (
            <span className="check-icon">
              <CheckOutlined />
            </span>
          ) : (
            ""
          )}
        </label>
      ))}

      {/* Add more color divs as needed */}
    </div>
  );
};

export default ColorPalette;
