import React from "react";
import {
  Circle,
  Marker,
  Pane,
  Polygon,
  Popup,
  Tooltip,
  useMap,
} from "react-leaflet";
import { useSelector } from "react-redux";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

const MyMarker = ({ data, index }) => {
  const getPosition = (props) => {
    let lon = "";
    let lat = "";

    lon = props?.long;
    lat = props?.lat;
    return [lat, lon];
  };

  const color = data?.color ? data?.color : data?.properties?.color || "#000";

  const myCustomColour = color;

  const markerHtmlStyles = `
  background-color: transparent;
  width: 20px;
  height: 20px;
  display: block;
  left: -4px;
  top: -4px;
  position: relative;
  border-radius: 100%;
  transform: rotate(45deg);
  border: 5px solid ${myCustomColour};
 `;

  const icon = new L.divIcon({
    className: "my-custom-pin",
    html: `<span style="${markerHtmlStyles}" />`,
  });

  if (data.catchment_area === null) {
    return (
      <Marker
        zIndexOffset={4000000}
        position={getPosition(data)}
        icon={icon}
        // pane={`locationPane${index}`}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup(),
        }}
      >
        <Popup className="custom-popup" keepInView closeButton={false}>
          <div className="d-flex flex-column">
            <span>
              Location Name&nbsp;:&nbsp;
              <b className="text-capitalize">{data?.location_name}</b>
            </span>
            <span>
              Project Name&nbsp;:&nbsp;
              <b className="text-capitalize">{data?.projectName}</b>
            </span>
          </div>
        </Popup>
      </Marker>
    );
  } else if (data.catchment_area === "Distance") {
    return (
      <Circle
        center={getPosition(data)}
        pathOptions={{ color: data?.color ? data.color : "#ffffff" }}
        radius={data?.distance_radius * 1000}
        // pane={`locationPane${index}`}
      >
        <Marker
          zIndexOffset={4000000}
          position={getPosition(data)}
          icon={icon}
          eventHandlers={{
            mouseover: (event) => event.target.openPopup(),
            mouseout: (event) => event.target.closePopup(),
          }}
        >
          <Popup className="custom-popup" keepInView closeButton={false}>
            <div className="d-flex flex-column">
              {/* <span>
                Lat & Long&nbsp;:&nbsp;
                <b className="text-capitalize">
                  {data?.lat},{data?.long}
                </b>
              </span> */}
              <span>
                Location Name&nbsp;:&nbsp;
                <b className="text-capitalize">{data?.location_name}</b>
              </span>
              <span>
                Project Name&nbsp;:&nbsp;
                <b className="text-capitalize">{data?.projectName}</b>
              </span>

              <span>
                Catchment Type&nbsp;:&nbsp;
                <b className="text-capitalize">Distance</b>
              </span>
              <span>
                Distance Radius&nbsp;:&nbsp;
                <b className="text-capitalize">
                  {data?.distance_radius}&nbsp;km
                </b>
              </span>
            </div>
          </Popup>
        </Marker>
      </Circle>
    );
  }

  let coorArray = [];
  let mark = [data?.lat, data?.long];
  data?.geometry?.coordinates[0].map((item) =>
    coorArray.push([item[1], item[0]])
  );
  return (
    <Polygon
      key={index}
      color={data?.properties?.color}
      fillColor={data?.properties?.color}
      fillOpacity={data?.properties?.fillOpacity}
      positions={coorArray}
      // pane={`locationPane${index}`}
    >
      <Marker
        zIndexOffset={4000000}
        position={mark}
        icon={icon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup(),
        }}
      >
        <Popup className="custom-popup" keepInView closeButton={false}>
          <div className="d-flex flex-column">
            <span>
              Location Name&nbsp;:&nbsp;
              <b className="text-capitalize">{data?.locationName}</b>
            </span>
            <span>
              Project Name&nbsp;:&nbsp;
              <b className="text-capitalize">{data?.projectName}</b>
            </span>
            <span>
              Catchment Type&nbsp;:&nbsp;
              <b className="text-capitalize">Travel Time</b>
            </span>
            <span>
              Travel Mode&nbsp;:&nbsp;
              <b className="text-capitalize">{data?.travelWay}</b>
            </span>
            <span>
              Travel Time&nbsp;:&nbsp;
              <b className="text-capitalize">
                {data?.properties?.contour}&nbsp;mins
              </b>
            </span>
          </div>
        </Popup>
      </Marker>
    </Polygon>
  );
};

const AllLocations = () => {
  const radiusLocation = useSelector(
    (state) => state?.LocationProject?.selected_locations
  );

  const polygonLocation = useSelector(
    (state) => state?.LocationProject?.distance_location
  );

  const combinedLocations = [...radiusLocation, ...polygonLocation];

  const currentProjects = useSelector(
    (state) => state?.LocationProject?.selectedProject
  );

  return (
    <>
      {/* <MarkerClusterGroup
        maxClusterRadius={1}
        spiderfyOnMaxZoom={true}
        showCoverageOnHover={true}
        // spiderfyOnEveryZoom={true}
        polygonOptions={{
          fillColor: "#ffffff",
          color: "#f00800",
          weight: 5,
          opacity: 1,
          fillOpacity: 0.8,
        }}
      > */}
      {combinedLocations
        ?.filter((item) =>
          currentProjects.some((obj) => obj.id === item.projectId)
        )
        .map((data, i) => {
          return (
            // <Pane
            //   name={`locationPane${i}`}
            //   // style={{ zIndex: parseInt(`40${i + 1}`) }}
            //   style={{ zIndex: 400 }}
            //   key={`locationPane${i}`}
            // >

            <MyMarker data={data} index={i} />
            // </Pane>
          );
        })}
      {/* </MarkerClusterGroup> */}
    </>
  );
};

export default AllLocations;
