import React, { useEffect, useRef, useState } from "react";
import {
  StandaloneSearchBox,
  LoadScript,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import { GoogleAPIKey } from "../../helpers/Constants";
import { placesLibrary } from "../../map_pages/Constants";

const AutoCompleteInput = ({ handleValue, setPlace, address = "" }) => {
  const inputRef = useRef();
  const [searchResult, setSearchResult] = useState("Result: none");
  const [searched, setSearched] = useState(null);
  const [newaddress, setNewAddress] = useState("");
  useEffect(() => {
    setNewAddress(address);
  }, [address]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GoogleAPIKey,
    libraries: placesLibrary,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };
  const inputChanged = () => {};

  function onPlaceChanged(e) {
    console.log("On Placed Changed Function Working");
    if (searchResult != null) {
      console.log(searchResult);
      const place = searchResult.getPlace();
      setPlace(place);
      if (!place.geometry || !place.geometry.location) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      // console.log({place});
      console.log(place);
      handleValue({
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),
        name: place.formatted_address,
      });
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
      // console.log(place.place_id);
      // console.log(place.types);
      // console.log(place.url);
      // console.log(place.utc_offset);
      // console.log(place.vicinity);
      // console.log(place.website);
      // console.log(place.photos);
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
      // console.log(place.place_id);
      // console.log(place.types);
      // console.log(place.url);
      // console.log(place.utc_offset);
      // console.log(place.vicinity);
      // console.log(place.website);
      setSearched(place);
    } else {
      alert("Please enter text");
    }
  }

  function onLoad(autocomplete) {
    console.log("on load function called");
    setSearchResult(autocomplete);
  }
  return (
    <>
      <div className="search-input-box">
        {isLoaded && (
          <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
            <input
              id="searchTxt"
              onChange={inputChanged}
              type="search"
              defaultValue={newaddress}
              className="nosubmit"
              placeholder="Search Location"
              style={{
                color: "#000",
                boxSizing: `border-box`,
                border: `1px solid #E7E7E7`,
                width: `230px`,
                // height: `32px`,
                borderRadius: `20px`,
                // boxShadow: `0 2px 4px rgba(0, 0, 0, 0.2)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                padding: "9px 4px 9px 40px",
                backgroundColor: "#f6f6f6",
              }}
            />
          </Autocomplete>
        )}
      </div>
    </>
  );
};

export default AutoCompleteInput;
